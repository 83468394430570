import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { 
  ITask, 
  ITaskList ,  
  IGroup,
  TaskProfile,
  IAccount,
  IGroupAccount,
  Status,
  Priority,} from "./types";
import { getStorageData } from "../../../framework/src/Utilities";
export interface LegalServiceType {
  id: number,
  name: string
}

export interface AssociatedLawyerDetails {
  data: LawyerDetailData;
}

export interface AssignedLawyers {
  data: LawyerData[];
}

export interface LawyerData {
  id: string;
  type: string;
  attributes: LawyerAttributes;
}

export interface LawyerAttributes {
  associated_lawyer_details: AssociatedLawyerDetails;
  legal_service_count: number;
}

export interface LawyerDetailData {
  id: string;
  type: string;
  attributes: LawyerDetailAttributes;
}

export interface LawyerDetailAttributes {
  first_name: string;
  last_name: string;
  website: string;
  firm: string;
  jurisdiction: string;
  full_phone_number: string;
  country_code: number;
  email: string;
  user_role: string;
  accomplishments: string;
  lawyer_status: string;
  legel_service_type: LegalServiceType[];
  years_of_experience: number;
  profile_image?: {
    url: string;
  };
  attachment: Attachment;
}

export interface LegalServiceType {
  id: number;
  legal_service_name: string;
  legel_service_description: string | null;
  created_at: string;
  updated_at: string;
}

export interface ProfileImage {
  url: string;
}

export interface Attachment {
  data: any[];
}
export interface TodoListResponse{
  id:string,
          attributes: {
              id: string,
              to_do_name: string,
              to_do_description: string,
              priority: string,
              status: string,
              deadline: string,
              account_id: 1,
              assign_lawyer: "",
              project_template_id: 1,
              created_at: "",
              updated_at: "",
              assigned_lawyers:{
                data:LawyerData[];
              }
          }
}
export interface LegalServiceType {
  id: number,
  name: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleClose:boolean
  handleRejectClose:boolean
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  title: string;
  description: string;
  priority: string;
  status: string;
  assign_to: string;
  assign_to_type: string;
  taskList: ITask[];
  isVisibleAssignModal: boolean;
  dropdownGroup: boolean;
  dropdownStatus: boolean;
  dropdownAccount: boolean;
  dropdownPriority: boolean;
  groupList: IGroup[];
  statusList: Status[];
  priorityList: Priority[];
  selectedAccounts: IGroupAccount[];
  accountsData: IAccount[];
  name: string;
  editMode: boolean;
  token: string;
  taskLists: ITaskList[];
  taskProfile:TaskProfile[],
  filterTask:TaskProfile[],
  filterStateOption:{
    name:string,
    bgColor:string,
    textColor:string
  }[],
  filterStatusOption:string[],
  filterServiceOption:string[],
  selectedTaskStatus:string,
  searchMessage:boolean,
  filterState:boolean,
  openFilter:boolean,
  selectedTaskState:string,
  selectedTaskService:string,
  taskView:{
    id: string;
    attributes: {
      task_name: string;
      legel_service_type: LegalServiceType;
      trademark_name: string;
      trademark_application_number: string;
      state: string;
      status: string;
      jurisdiction:string,
      deadline:string,
      description:string,
      created_at:string,
      request: { data: {
          id: string,
          type: string,
          attributes: {
            price: string,
          }
        }
      }
    };
  },
  tasksData: ITask[];
  selectedTasks: ITask[];
  isVisibleModal: boolean;
  dropdownTasks: boolean;
  mobileOpen: boolean;
  open: boolean;
  openDialog: boolean
  drawerOpen: boolean,
  taskViewId:string,
  isExpanded: boolean,  
  shouldShowSeeMore: boolean, 
  isContentLong: boolean,
  acceptTask:boolean,
  rejectTask:boolean,
  accepted:boolean,
  isTaskPosted:boolean,
  loading:boolean,
  todoList:TodoListResponse[],
  openFinishTaskPopup:boolean,
  taskName:string,
  responseShow:{
    success:string,
    errors:string},
  isLibraryHover: boolean,
  openTaskForm:boolean,
  isSaveDraft:boolean,
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTasksApiCallId = "";
  postTaskApiCallId = "";
  putTaskApiCallId = "";
  deleteTaskApiCallId = "";
  getGroupsApiCallId = "";
  assignGroupApiCallId = "";
  getAccountsApiCallId = "";
  getTaskListsApiCallId = "";
  postTaskListApiCallId = "";
  putTaskListApiCallId = "";
  deleteTaskListApiCallId = "";
  getTasksApiCallIdd="";
  finishTaskId="";
  getTodoListId="";
  getServiceApiCallId = "";
  apiCreateProjectCallId: string = "";

  areAllToDosCompleted: boolean = false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationTaskAllocatorMessage),
      getName(MessageEnum.NavigationTaskAllocatorId),
      getName(MessageEnum.NavigationProjectTrackingMessage),
      getName(MessageEnum.NavigationProjectTrackingId),
      getName(MessageEnum.ProjectTrackingMessage),
      getName(MessageEnum.NavigationChatMessage),
      getName(MessageEnum.NavigationChatMessageId),
      
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "",
      title: "",
      description: "",
      priority: "",
      status: "",
      assign_to: "",
      assign_to_type: "",
      editMode: false,
      token: "",
      taskList: [],
      isVisibleModal: false,
      isVisibleAssignModal: false,
      dropdownGroup: false,
      dropdownStatus: false,
      dropdownAccount: false,
      dropdownPriority: false,
      groupList: [],
      selectedAccounts: [],
      accountsData: [],
      statusList: [
        { id: 1, name: "to_do" },
        { id: 2, name: "in_progress" },
        { id: 3, name: "complete" },
      ],
      priorityList: [
        { id: 1, name: "low" },
        { id: 2, name: "medium" },
        { id: 3, name: "high" },
      ],
      name: "",
      taskLists: [],
      tasksData: [],
      selectedTasks: [],
      dropdownTasks: false,
      mobileOpen: false,
      open: false,
      openDialog: false,
      drawerOpen: false,
      taskProfile:[],
      taskView:{
        id:"",
        attributes: {
          task_name: "",
          legel_service_type: {
            id: 1,
            name: "",
            legal_service_name: "",
            legel_service_description: "",
            created_at: "",
            updated_at: ""
          },
          trademark_name: "",
          trademark_application_number: "",
          state: "",
          status: "",
          jurisdiction:"" ,
          deadline:"",
          description:"",
          request: {  data: {  id: '',  type: '',
              attributes: {
                price: '',
              }
            }
          },
          created_at : "",
            }
        },
      taskViewId:"",
      isExpanded: false, 
      shouldShowSeeMore: false, 
      isContentLong: false,
      acceptTask:false,
      rejectTask:false,
      accepted:false,
      isTaskPosted:false,
      loading:true,
      todoList:[],
      openFinishTaskPopup:false,
      filterTask:[],
      taskName:'',
      searchMessage:false,
      filterState:false,
      openFilter:false,
      filterStateOption:[
        { name: "new_task", bgColor: "#DFFFE1", textColor: "#008000" },{ name: "in_progress", bgColor: "#FFF4C2", textColor: "#D89A00" },
        { name: "pending", bgColor: "#DCE8FF", textColor: "#0055FF" }, { name: "closed", bgColor: "#FFE3E3", textColor: "#D30000" },
      ],
      filterStatusOption:['complete','assigned','digip_assessment','no_legal_case','draft','pending_client_approval','task_posted'],
      filterServiceOption:[],
      selectedTaskState: "", 
      selectedTaskStatus: "",
      selectedTaskService:"",
      responseShow:{
        success:"",
        errors:""},
      isLibraryHover: false,
      openTaskForm:false,
      isSaveDraft:false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getTaskLists();
    this.getServicesList();
  }
  handleDocumentFolderNavigation = (taskViewId:string|number) =>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "Documentation");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    sessionStorage.setItem("taskID", `${taskViewId}`)
    this.send(message);
  }

  getTaskLists = async() => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);
    const header = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksListApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

    handleEditProject = () => {
      const message: Message = new Message(getName(MessageEnum.NavigationProjectTemplatesMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationProjectTemplatesWithId), this.state.taskViewId);
  
      this.send(message);

    }

  handleNavigationProject = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "TaskListLawyer");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }
  handleAcceptTask = () => {
    this.setState((prevState) => ({
      accepted: !prevState.accepted,
      drawerOpen: false
    }));
  };  
  handleClosePopup = () => {
    this.setState({ accepted: false});
}
handleClosed = () => {
  this.setState({rejectTask: false });
}

handleChange = (event:any) => {
  this.setState({ 
    taskName: event.target.value 
  }, () => {
    this.handleLawyerSearchAndFilter();
  });
};
handleLawyerKeyDown = (event:any) => {
  if (event.key === 'Enter') {
    this.handleLawyerSearchAndFilter();
  }
};

handleTaskStateChange = (stateValue: string) => {
  this.setState({ 
    selectedTaskState: stateValue
  }, () => {
    this.handleLawyerSearchAndFilter();
  });
};

handleTaskStatusChange = (statusValue: string) => {
  this.setState({ 
    selectedTaskStatus: statusValue 
  }, () => {
    this.handleLawyerSearchAndFilter();
  });
};

handleTaskServiceChange = (serviceValue: string) => {
  this.setState({
    selectedTaskService: serviceValue
  }, () => {
    this.handleLawyerSearchAndFilter(); 
  });
}

searchLawyerAttribute =(task:any,taskName:string)=>{
  const taskNameAttr = task.attributes.task_name?.toLowerCase() || "";
  const trademarkName = task.attributes.trademark_name?.toLowerCase() || "";
  const applicationNumber = task.attributes.trademark_application_number?.toLowerCase() || "";
  const searchLawyerQuery = taskName?.toLowerCase() || "";
  const matchesLawerSearch = searchLawyerQuery === "" || 
  taskNameAttr.includes(searchLawyerQuery) ||
  trademarkName.includes(searchLawyerQuery) ||
  applicationNumber.includes(searchLawyerQuery);
  return {matchesLawerSearch,searchLawyerQuery}

}

handleLawyerSearchAndFilter = () => {
  const { taskName, selectedTaskState, selectedTaskStatus, selectedTaskService, taskProfile } = this.state;

  const filteredLawyerResults = taskProfile.filter(task => {
      if (!task || !task.attributes) return false;

      const { matchesLawerSearch, searchLawyerQuery } = this.searchLawyerAttribute(task, taskName);
      const matchesAttributes = this.filterByLawyerAttributes(task, selectedTaskState, selectedTaskStatus, selectedTaskService);

      if (searchLawyerQuery && (selectedTaskState || selectedTaskStatus || selectedTaskService)) {
          return matchesLawerSearch && matchesAttributes;
      } else if (searchLawyerQuery) {
          return matchesLawerSearch;
      } else if (selectedTaskState || selectedTaskStatus || selectedTaskService) {
          return matchesAttributes;
      }

      return true;
  });

  this.setState({
    filterState: Boolean(taskName) || Boolean(selectedTaskState) || Boolean(selectedTaskStatus) || Boolean(selectedTaskService),
    filterTask: filteredLawyerResults,
    searchMessage: true
  });
};
handleNavigationChat = () => {
  const message: Message = new Message(getName(MessageEnum.NavigationChatMessage));
  message.addData(getName(MessageEnum.NavigationChatMessage), true);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationChatMessageId), this.state.taskViewId);
  this.send(message);
};

filterByLawyerAttributes = (task:any, selectedTaskState:any, selectedTaskStatus:any, selectedTaskService:any) => {
  const stateLawyerOption = task.attributes.state?.toLowerCase() || "";
  const statusLawyerOption = task.attributes.status?.toLowerCase() || "";
  const serviceLawyerOption = task.attributes.legel_service_type?.name?.toLowerCase() || "";

  const matchesState = !selectedTaskState || stateLawyerOption === selectedTaskState.toLowerCase();
  const matchesStatus = !selectedTaskStatus || statusLawyerOption === selectedTaskStatus.toLowerCase();
  const matchService = !selectedTaskService || serviceLawyerOption === selectedTaskService.toLowerCase();

  return matchesState && matchesStatus && matchService;
};
handleLawyerOpenFilter =()=>{
   this.setState({openFilter:true})
}
 handleRejectTask = () => {
    this.setState((prevState) => ({
      rejectTask: !prevState.rejectTask,
      drawerOpen: false
    }));
  };

  handleNavigateDashboard = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "LawyerDashboard");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }
  handleOpenFinishTaskPopup=()=>{
    this.areAllToDosCompleted = this.state.todoList.every(todo => todo.attributes.status.toLowerCase() === "completed")
    this.setState({openFinishTaskPopup:true,drawerOpen:false})
  }
  handleCloseFinishTaskPopup=()=>{
    this.setState({openFinishTaskPopup:false})
  }
  handleNavigationProjectTrack = (showPopup: boolean) => () => {
    const message: Message = new Message(getName(MessageEnum.NavigationProjectTrackingMessage));
      message.addData(getName(MessageEnum.ProjectTrackingMessage), true);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationProjectTrackingId), this.state.taskViewId);
      sessionStorage.setItem("showAddToDoPopup", `${showPopup}`)  
      sessionStorage.setItem("taskViewId",this.state.taskViewId)
      this.send(message);
  }

  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getTaskListsApiCallId) {
      if (responseJson.data) {
        this.setState({ 
          loading:false,
          taskProfile: responseJson.data,
        });
      } else {
        this.setState({ taskProfile: [],loading:false });
      }
    } 
    if (apiRequestCallId === this.getTasksApiCallId) {
      if (responseJson.data) {
        const status = responseJson.data.attributes.status;
        this.setState({ taskView: responseJson.data,
          isTaskPosted: status === "task_posted"
        });
      } else{
        this.setState({ 
          isTaskPosted: false
          
        });
      }
    }
    if (apiRequestCallId === this.getServiceApiCallId) {
      if (Array.isArray(responseJson)) {
        const legalServiceNames = responseJson.map(service => service.legal_service_name || "");


        this.setState({
          filterServiceOption: legalServiceNames
        });
      }

    }
    if(apiRequestCallId === this.getTodoListId){
      this.handleTodoApiResponse(responseJson)
   }  
   if(apiRequestCallId === this.finishTaskId){
    this.handleFinishTask(responseJson)
}

if (apiRequestCallId === this.apiCreateProjectCallId) {  

  this.setState({
    openTaskForm:false
  })
}};
  submitData = async (formValues:any, isSavedraft:boolean) => 
    { const signupTokens: string = await getStorageData("userdetails");
     const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens); const metaLoginToken = 
    JSON.parse(loginTokens);
    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);

    const header = {"token": maintoken?.serialized_data?.meta?.token 
      || maintoken?.meta?.token,
    };

    const formData = new FormData();
   
    formData.append("description", formValues.description);
    formData.append("additional_info",'true');
    if (isSavedraft) {
      formData.append("save_as_draft", "true");
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    let endpoint = `${configJSON.updateTask}`;
    
    if (!this.state.isSaveDraft) {
      endpoint = `${configJSON.updateTask}/${this.state.taskViewId}`;
    }
    
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    this.apiCreateProjectCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    const method = this.state.isSaveDraft ? 
    configJSON.postApiMethodPost : configJSON.putApiMethodPut;
    
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);

    runEngine.sendMessage(requestMessage.id, requestMessage);
};


  handleSaveDraft = async (values:any) => { await this.submitData(values, true); 
  };

  handleAdditionanlFunction=() => { const taskId = sessionStorage.getItem("TaskID");
    
    this.setState(prevState => ({
      drawerOpen: !prevState.drawerOpen, 
      taskViewId: taskId || '', 
      openTaskForm:true,
    }));
   
  };
  handleFinishTask = (responseJson: { meta: { message: any; }; errors?: { message: string }[]; }) => {
    if (responseJson?.errors ){
      this.setState({ openDialog: true,
        responseShow: { errors: responseJson.errors[0].message, success: "" } 
      });
    } else if (responseJson?.meta?.message) {
      this.setState({ openDialog: true,
        responseShow: { errors: "", success: responseJson.meta.message }
      });
    }
  
    setTimeout(() => {
      this.setState({ openDialog: false });
    }, 700);
   
    this.setState({
        openFinishTaskPopup:false,drawerOpen:false});
  };
  handleTodoApiResponse = (responseJson:any) => {
    if (responseJson.data) {
      this.setState({
        todoList: responseJson.data,
        loading: false
      });
    }
};
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  hideAssignModal = () => {
    this.setState({ isVisibleAssignModal: false });
  };

  handleInputTitle = (title: string) => {
    this.setState({ title });
  };

  handleInputDescription = (description: string) => {
    this.setState({ description });
  };

  toggleAssignModal = () => {
    this.setState({
      dropdownAccount: false,
      dropdownGroup: false,
      isVisibleAssignModal: !this.state.isVisibleAssignModal,
      editMode: true,
    });
  };

  showAddModal = () => {
    this.setState({
      title: "",
      description: "",
      priority: "",
      status: "",
      isVisibleModal: !this.state.isVisibleModal,
      editMode: false,
    });
  };

  showEditModal = () => {
    this.setState({
      isVisibleModal: !this.state.isVisibleModal,
      editMode: true,
    });
  };

  expandGroupView = () => {
    this.setState({
      dropdownGroup: !this.state.dropdownGroup,
    });
  };

  expandStatusView = () => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus,
    });
  };

  expandPriorityView = () => {
    this.setState({
      dropdownPriority: !this.state.dropdownPriority,
    });
  };

  expandAccountsView = () => {
    this.setState({ dropdownAccount: !this.state.dropdownAccount });
  };

  handleAccountSelect = (dataId: string) => {
    let newData = this.state.accountsData.map((account: IAccount) => {
      if (account.id === dataId) {
        return { ...account, isSelected: !account.isSelected };
      }
      return account;
    });
    this.setState({ accountsData: newData });
  };

  handleSelectPriority = (item: Priority) => {
    this.setState({ priority: item.name, dropdownPriority: false });
  };

  handleSelectStatus = (item: Status) => {
    this.setState({ status: item.name, dropdownStatus: false });
  };

  handleAssignToModal = (item: ITask) => {
    this.setState({
      id: item.id,
      title: item.attributes.title,
      description: item.attributes.description,
      priority: item.attributes.priority,
      status: item.attributes.status,
    });
    if (item.attributes.assigned_to) {
      this.setState({
        assign_to:
          item.attributes.assigned_to.type === "group"
            ? item.attributes.assigned_to.attributes.name
            : item.attributes.assigned_to.attributes?.first_name || "",
        assign_to_type: item.attributes.assigned_to.type,
      });
    }
    this.toggleAssignModal();
  };

  handleEditTask = (item: ITask) => {
    this.setState({
      id: item.id,
      title: item.attributes.title,
      description: item.attributes.description,
      priority: item.attributes.priority,
      status: item.attributes.status,
    });
    this.showEditModal();
  };

  getTasks = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTasksApiCallIdd = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addTask = () => {
    if (
      this.isStringNullOrBlank(this.state.title) ||
      this.isStringNullOrBlank(this.state.description)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        title: this.state.title,
        description: this.state.description,
        status: this.state.status,
        priority: this.state.priority,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postTaskApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  editTask = (taskId: string) => {
    if (
      this.isStringNullOrBlank(this.state.title) ||
      this.isStringNullOrBlank(this.state.description)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        title: this.state.title,
        description: this.state.description,
        status: this.state.status,
        priority: this.state.priority,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.putTaskApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksApiEndPoint + "/" + `${taskId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  deleteTask = (taskId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTaskApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.tasksApiEndPoint + "/" + `${taskId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleAssignTo = (
    taskId: string,
    assignToType: string,
    assignToId: string
  ) => {
    if (
      this.isStringNullOrBlank(taskId) ||
      this.isStringNullOrBlank(assignToId)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        assigned_to_type: assignToType,
        assigned_to_id: assignToId,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.assignGroupApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.tasksApiEndPoint + "/" + `${taskId}` + "/assign"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };



  expandTasksView = () => {
    this.setState({ dropdownTasks: !this.state.dropdownTasks });
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });

  }

  handleClickOpen = () => {
    this.setState({ open: true })

  };

  handleClose = () => {
    this.setState({ open: false });

  };

  toggleDrawer = (task:{id:string}) => {   
    const taskId = String(task.id); 
    sessionStorage.setItem("TaskID",taskId)
    this.setState(prevState => ({
      drawerOpen: !prevState.drawerOpen, 
      taskViewId: taskId, 
    }));
    this.lawyerTasks()
    this.todoList()
  };


  handleSeeMoreClick = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  toggleDrawerClose = () => {
    this.setState({ drawerOpen: false ,isExpanded:false}); 
  };



  handleSettingIn = async () => {
    const ctoken: string = await getStorageData("clientTokes");

    const message = new Message(getName(MessageEnum.NavigationMessage));

    if (ctoken) {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientSettings");
    } else {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
    }

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  determineMainToken1 = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };

  handleCloseDelete = () => {
    this.setState({ openDialog: false });
  };

  getServicesList= async () => {
    const header = {
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getServiceApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.serviseListApi}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 
  lawyerTasks = async () => {
    const signupTokens: string = await getStorageData("userdetails");    
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTasksApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}/${this.state.taskViewId}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  finishTodo = async  ()=>{
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpTokenn = JSON.parse(signupTokens);
    const metaLoginTokenn = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpTokenn, metaLoginTokenn);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json", };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage) );
    this.finishTaskId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.finishTaskApiEndPoint}${this.state.taskViewId}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  todoList = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    
    const loginTokens: string = await getStorageData("token");
    const metaSignUpTokens = JSON.parse(signupTokens);
    const metaLoginTokens = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken1(metaSignUpTokens, metaLoginTokens);

    const header: { "Content-Type": string; token: string | undefined } = {
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTodoListId = requestMessage.messageId;
    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.todoListApi}${this.state.taskViewId}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setIsHovered = (isHover:boolean) => {
    this.setState({isLibraryHover:isHover})
  }

  handleDocumentFolderMenu = async() => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Documentation");   
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
    sessionStorage.setItem("Document", "ture")
  };

  // Customizable Area End
}
