//@ts-nocheck
import React from "react";
// Customizable Area Start
import { Typography, Button, Badge, styled, Divider } from "@material-ui/core";
// Customizable Area End

import InvoiceBillingController, {
  Props,
  configJSON,
} from "./InvoiceBillingController.web";
import { digipIcon, download, taskInfo, transactionId } from "./assets";
import moment from "moment";

export default class InvoiceBilling extends InvoiceBillingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderStatus = (status: string) => {
    let statusVal
    let statusColor
    let statusBgColor
    switch (status) {
      case 'pending':
        statusVal = "Pending"
        statusBgColor = "#DCECFF"
        statusColor = "#355EE7"
        break
      case 'rejected':
        statusVal = "CLOSED"
        statusBgColor = "#FEE2E2"
        statusColor = "#DC2626"
        break
      case 'paid':
        statusVal = "PAID"
        statusBgColor = "#D1FAE5"
        statusColor = "#059669"
        break
    }
    return { statusVal, statusColor, statusBgColor }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { paymentTaskInfo } = this.props
    return (
      <StyleDiv className="flex items-center" style={{ gap: 12 }} >
        <div >
          <StyledBadge style={{ backgroundColor: this.renderStatus(paymentTaskInfo?.status as string).statusBgColor, color: this.renderStatus(paymentTaskInfo?.status as string).statusColor }} data-state="paid">{this.renderStatus(paymentTaskInfo?.status as string).statusVal}</StyledBadge>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography component="p" className="transaction_info">
             <img src={transactionId} />
             <div>
              Transaction ID: <span style={{ color: "#355EE7" }}> {paymentTaskInfo?.transaction_id} </span>
            </div>
            </Typography>
          <StyleButton onClick={this.handleDownloadInvoice} data-test-id="download_invoice" variant="outlined" startIcon={<img src={download} />}>
            <span style={{ marginTop: "2px" }}> Download Invoice</span>
          </StyleButton>
        </div>
        <div className="main_invoice_div" >
          <div style={{ justifyContent: "space-between", display: "flex" }}>
            <div>
              <div style={{ flexDirection: "column", display: "flex", gap: "4px" }}>
                <Typography className="billing_key" variant="body2" component="span" >Invoice number:</Typography>
                <Typography className="billing_value" variant="body2" component="span" > {paymentTaskInfo?.invoice_number}</Typography>
              </div>

              <div style={{ marginTop: "20px", display: "flex", flexDirection: "column", gap: "4px", }}>
                <Typography className="billing_key" variant="body2" component="span" >Issue date:</Typography>
                <Typography className="billing_value" variant="body2" component="span" >{moment(paymentTaskInfo?.issue_date).format("DD/MM/yyyy")}</Typography>
              </div>
            </div>
            <div style={{display:"flex",flexDirection:"column",gap:"10px"}}>
              <img src={digipIcon} style={{width:"76px"}}/>
              <Typography style={{fontSize:"10",fontWeight:400,width:"102px",color:"#8C8C8C"}}>Digip sas
              Luntmakargatan 26, Stockholm, Stockholm County 11137, SE</Typography>
            </div>
          </div>

          <Divider className="dividermain" style={{ marginTop: "20px" }} />

          <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
            <Typography className="billing_key" variant="body2" component="span" >Provider:</Typography>
            <Typography variant="body2" className="billing_value" component="span" >{paymentTaskInfo?.provider}</Typography>
          </div>

          <div className="info_task_row">
            <Typography className="billing_key" variant="body2" component="span" >Task Price:</Typography>
            <Typography variant="body2" className="billing_value" component="span" >&euro; {paymentTaskInfo?.task_price} </Typography>
          </div>

          <div className="info_task_row" style={{alignItems:"center"}}>
            <Typography variant="body2" className="billing_key_amt" component="span" >Amount paid:</Typography>
            <Typography  className="billing_value_amt" component="span" >&euro; {paymentTaskInfo?.task_price?.toFixed(2)} </Typography>
          </div>

          <Divider style={{ marginTop: "20px" }} />

          <div>
            <Typography style={{ marginTop: "20px",display: "flex",alignItems:"center",gap:"15px" }} component="p" className="title_info">
              <img src={taskInfo}/>
              Task Info
            </Typography>

            <div className="info_task_row">
              <Typography className="billing_key" variant="body2" component="span" >Task Name</Typography>
              <Typography className="billing_value" variant="body2" component="span" >{paymentTaskInfo?.task_name}</Typography>
            </div>

            <div className="info_task_row">
              <Typography className="billing_key" variant="body2" component="span" >Trademark Name</Typography>
              <Typography className="billing_value" variant="body2" component="span" >{paymentTaskInfo?.trademark_name}</Typography>
            </div>

            <div className="info_task_row">
              <Typography className="billing_key" variant="body2" component="span" >Trademark Application Number</Typography>
              <Typography className="billing_value" variant="body2" component="span" >{paymentTaskInfo?.trademark_application_number}</Typography>
            </div>

            <div className="info_task_row">
              <Typography className="billing_key" variant="body2" component="span" >Date created</Typography>
              <Typography className="billing_value" variant="body2" component="span" >{moment(paymentTaskInfo?.task_created_at).format("DD/MM/yyyy")}</Typography>
            </div>
          </div>

        </div>
      </StyleDiv>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyleDiv = styled("div")({
  "& .transaction_info": {
    fontSize: "14px",
    fontWeight: 700,
    color: "#262626",
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    gap: "10px" 
  },
  "& .main_invoice_div": {
    marginTop: "30px"
  },
  "& .info_task_row": {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px"
  },
  "& .billing_key": {
    fontSize: "12px",
    fontWeight: 400,
    color: "#8C8C8C",
  },
  "& .billing_value": {
    fontSize: "12px",
    fontWeight: 400,
    color: "#0F172A",
  },
  "& .billing_key_amt": {
    fontSize: "16px",
    fontWeight: 500,
    color: "#0F172A",
  },
  "& .billing_value_amt": {
    fontSize: "16px",
    fontWeight: 500,
    color: "#0F172A",
    background: "#ECF1FF",
    borderRadius: "50px",
    padding:"8px"
  },
  "& .title_info": {
    fontSize: "16px",
    color: "#262626",
    fontWeight: 700,
  },
})

const StyledBadge = styled(Badge)({
  display: "inline-block",
  textAlign: "center",
  minWidth: 100,
  padding: "8px 0px",
  backgroundColor: "#DCECFF",
  fontFamily: "DM Sans",
  borderRadius: 40,
  '& + span': {
    fontWeight: 500,
    color: "#434343",
    fontSize: 12,
    fontFamily: "DM Sans",
    lineHeight: "15.62px",
  },
  fontWeight: 700,
  fontSize: 12,
  lineHeight: "18px",
  color: "#355EE7",
  '&[data-state="paid"]': {
    color: "#059669",
    backgroundColor: "#D1FAE5",
  },

})

const StyleButton = styled(Button)({
  fontSize: 12,
  height: "28px",
  borderColor: "#355EE7",
  color: "#355EE7",
  width: "173px",
  borderRadius: "8px",
  marginTop: "18px",
  fontFamily: "DM Sans",
  fontWeight: 700,
})

// Customizable Area End
