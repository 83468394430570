export const user1 = require("../assets/user3.png");
export const user2 = require("../assets/user.jpg");
export const user3 = require("../assets/User2.png");
export const iconsetting =require("../assets/iconsetting.jpg");
export const dollericon =require("../assets/dollericon.jpg");
export const group = require("../assets/group_.svg")
export const Services = require("../assets/services.png")
export const userClient1 = require("../assets/userClient1.jpg")
export const userClient2 = require("../assets/userClient2.jpg")
export const userClient3 = require("../assets/userClient3.png")
export const tableUser = require("../assets/tableUser.png")
export const Peoples = require("../assets/peoples.png")
export const noMessages = require("../assets/noMessage.png")
export const message = require("../assets/messages.png")
export const redirectArrow = require("../assets/redirectarrow.png")
export const serviceRequest = require("../assets/serviceRequest.png")
export const fileDashboard = require("../assets/filedashboard.png")
export const noFiles = require("../assets/noFiles.png")
export const taskView = require("../assets/taskView.png")
export const noTaskOverview = require("../assets/noTaskOverview.png")
export const chatCount = require("../assets/chatCount.png")
export const noTaskList = require("../assets/noTaskList.png")
export const dashboardFiles = require("../assets/dashboardFiles.png")


