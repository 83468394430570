import React from "react";

// Customizable Area Start
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Typography
} from "@material-ui/core";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Activeicon from "../../documentation/src/components/Activeicon";
import Closeicon from "../../documentation/src/components/Closeicon";
import { arrowpng, attachmentIcon, countryIcon, dateIcon, filterLiveIcon, leftarrowIcon, messageIcon, searchIcon, sendmessageIcon, taskIcon, threeDots, userIcon } from "./assets";
import { ChevronRight } from "@material-ui/icons";

// Customizable Area End

import ViewChatController, { Props } from "./ViewChatController";
import ActiveMsgIcon from "./components/ActiveMsgIcon";
import ArchiveMsgIcon from "./components/ArchiveMsgIcon";

// Customizable Area Start
// Customizable Area End

export default class ViewChat extends ViewChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderTabs = () => {
    return (
      <StyleTabBox>
        <button data-test-id="active-tab-button" onClick={() => this.handleTab("active")}
          style={{
            width: '90px',
            fontWeight: 600,
            textAlign: 'center',
            background: 'none',
            border: 'none',
            color: this.state.activeTab === "active" ? "#355EE7" : "gray",
            borderBottom: this.state.activeTab === "active" ? "1px solid #355EE7" : "none",
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            paddingBottom: '5px',
            cursor: 'pointer',
          }}
        >
          <Activeicon data-test-id="active" color={this.state.activeTab === "active" ? "#355EE7" : "gray"} />
          Active
        </button>
        <button
          data-test-id="close-tab-button"
          onClick={() => this.handleTab("close")}
          style={{
            fontWeight: 600,
            width: '80px',
            textAlign: 'center',
            background: 'none',
            cursor: 'pointer',
            border: 'none',
            borderBottom: this.state.activeTab === "close" ? "1px solid #355EE7" : "none",
            color: this.state.activeTab === "close" ? "#355EE7" : "gray",
            display: "flex",
            justifyContent: 'center',
            paddingBottom: '5px',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Closeicon data-test-id="close" color={this.state.activeTab === "close" ? "#355EE7" : "gray"} />
          Close
        </button>
      </StyleTabBox>
    )
  }

  renderMessageTabs = () => {
    return (
      <Box style={{ display: "flex", justifyContent: 'start', alignItems: 'end', paddingLeft: '10px', gap: '20px', borderBottom: "1px solid #ddd", marginTop: '10px' }}>
        <button data-test-id="active-message-tab-button" onClick={() => this.handleTab("active")}
          style={{
            fontWeight: 600,
            width: '90px',
            textAlign: 'center',
            background: 'none',
            border: 'none',
            borderBottom: this.state.activeTab === "active" ? "1px solid #355EE7" : "none",
            color: this.state.activeTab === "active" ? "#355EE7" : "gray",
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            paddingBottom: '5px',
            cursor: 'pointer',
          }}
        >
          <ActiveMsgIcon color={this.state.activeTab === "active" ? "#355EE7" : "gray"} />
          Active
        </button>
        <button
          data-test-id="archive-message-tab-button"
          onClick={() => this.handleTab("close")}
          style={{
            fontWeight: 600,
            textAlign: 'center',
            cursor: 'pointer',
            background: 'none',
            border: 'none',
            borderBottom: this.state.activeTab === "close" ? "1px solid #355EE7" : "none",
            color: this.state.activeTab === "close" ? "#355EE7" : "gray",
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            paddingBottom: '5px'
          }}
        >
          <ArchiveMsgIcon color={this.state.activeTab === "close" ? "#355EE7" : "gray"} />
          Archived
        </button>
      </Box>
    )
  }

  renderTaskColumn = () => {
    return (
      <>
        <MainTaskColumnDiv>
          <div style={{ display: "flex", justifyContent: "end", cursor: "pointer" }}>
            <img data-test-id="drawer-toggle" src={this.state.taskDrawer ? arrowpng : leftarrowIcon} onClick={this.handleTaskDrawer} />
          </div>
          <div className="titleHeader" style={{ paddingTop: 0 }}>
            <img src={taskIcon} />
            <span className="maintitle">Tasks</span>
          </div>
          {this.renderTabs()}
          <div className="filterSection">
            <StyleTextField
              inputProps={{ "data-test-id": "account_number" }}
              InputProps={{
                style: {
                  borderRadius: '12px',
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                  </InputAdornment>
                ),
              }}
              name="account_number"
              type="text"
              fullWidth
              variant="outlined"
              placeholder="Task Name"
            />
            <img src={filterLiveIcon} />
          </div>
          <div className="tasksection">
            {this.state.taskDrawer && this.state.taskListData.map((item) => (
              <div data-test-id="task-selected" onClick={() => this.handleSelectedTask(item.id)} className="tasklistcontainer" style={this.state.selectedTask === item.id ? { background: "#ECF1FF" } : {}}>
                <div className="listrow">
                  <Typography className="msg_name">{item.name}</Typography>
                  <div>
                    <Typography className="msg_hrs">1h</Typography>
                    <Typography className="msg_count" style={{ width: "14px", padding: "3px" }}>2</Typography>
                  </div>
                  <ChevronRight fontSize="small" style={{ color: "#355EE7" }} />
                </div>
                <Divider style={{ marginTop: "20px" }} />
              </div>
            ))
            }

            {!this.state.taskDrawer && this.state.taskListData.map((item) => (
              <StyledAccordion
                key={item.id}
                expanded={this.state.selectedTask === item.id}
                onChange={() => this.handleSelectedTask(item.id)}
              >
                <AccordionSummary>
                  <div className="tasklistcontainer" style={this.state.selectedTask === item.id ? { background: "#ECF1FF", width: "100%", marginBottom: "-30px" } : { width: "100%" }}>
                    <div className="listrow">
                      <Typography className="msg_name">{item.name}</Typography>
                      <div>
                        <Typography className="msg_count" style={{ width: "14px", padding: "3px" }}>2</Typography>
                      </div>
                      <ChevronRight fontSize="small" style={{ color: "#355EE7" }} />
                    </div>
                    <Divider style={{ marginTop: "20px" }} />
                  </div>
                </AccordionSummary>
                <AccordionDetails>

                  {this.state.selectedTask === item.id && <div  style={{ marginTop: "0", width: "100%" }} className="tasksection">
                    {this.state.lawerData.map((item1) => (
                      <div className="tasklistcontainer"  onClick={() => this.handleSelectedMessage(item1.id)} style={this.state.selectedMessage === item.id ? { background: "#ECF1FF" } : {}}>
                        <div style={{alignItems: "center" , display: "flex", }}>
                          <StyledBadge
                            overlap="circular"
                            variant="dot"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          >
                            <Avatar src={userIcon} alt="Remy Sharp"  style={{ borderColor: "#355EE7" }} />
                          </StyledBadge>
                          <div data-test-id="message-list" className="messagelistrow">
                            <div>
                              <Typography className="msg_name">{item1.name}</Typography>
                              <Typography className="msg_desc">{item1.description}</Typography>
                            </div>
                            <div>
                              <Typography  style={{ fontSize: "12px" }} className="msg_count">2</Typography>
                            </div>
                          </div>
                        </div>
                        <Divider data-test-id="divider" style={{ marginTop: "20px" }} />
                      </div>
                    ))
                    }

                  </div>}
                </AccordionDetails>
              </StyledAccordion>
            ))}

          </div>
        </MainTaskColumnDiv>
      </>
    )
  }

  renderMessagedColumn = () => {
    return (
      <>
        <MainTaskColumnDiv>
          <div className="titleHeader">
            <img src={messageIcon} />
            <span className="maintitle">Message</span>
          </div>
          {this.renderMessageTabs()}
          <div className="filterSection">
            <StyleTextField
              inputProps={{ "data-test-id": "account_number" }}
              InputProps={{
                style: {
                  borderRadius: '12px',
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} />
                  </InputAdornment>
                ),
              }}
              name="account_number"
              type="text"
              fullWidth
              variant="outlined"
              placeholder="Search by Lawyer name"
            />
          </div>
          <div className="tasksection">
            {this.state.lawerData.map((item) => (
              <div data-test-id="message-selected" onClick={() => this.handleSelectedMessage(item.id)} className="tasklistcontainer" style={this.state.selectedMessage === item.id ? { background: "#ECF1FF" } : {}}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    variant="dot"
                  >
                    <Avatar alt="Remy Sharp" src={userIcon} style={{ borderColor: "#355EE7" }} />
                  </StyledBadge>
                  <div className="messagelistrow">
                    <div>
                      <Typography className="msg_name">{item.name}</Typography>
                      <Typography className="msg_desc">{item.description}</Typography>
                    </div>
                    <div>
                      <Typography className="msg_hrs">1h</Typography>
                      <Typography className="msg_count" style={{ fontSize: "12px" }}>2</Typography>
                    </div>
                  </div>
                </div>
                <Divider style={{ marginTop: "20px" }} />
              </div>
            ))
            }

          </div>
        </MainTaskColumnDiv>
      </>
    )
  }

  renderChatScreen = () => {

    const messages1 = [
      { id: 1, sender: 'Anita John', text: 'Oh no! Is that going to stop us from getting the trademark?', time: '4:11 PM', isSender: true, date: '2025-03-18' },
      { id: 2, sender: 'Anita John', text: 'What can we do about it?', time: '4:11 PM', isSender: true, date: '2025-03-18' },
      { id: 3, sender: 'Maya Diab', text: `Not necessarily. It's a similar mark, but it operates in a different industry...`, time: '4:15 PM', isSender: false, date: '2025-03-18' },
      { id: 4, sender: 'Anita John', text: `That's a relief! How long does it usually take for them to review our response?`, time: '4:17 PM', isSender: true, date: '2025-03-18' },
      { id: 5, sender: 'Maya Diab', text: `It typically takes about 2-3 months after submission, depending on their backlog...`, time: '4:20 PM', isSender: false, date: '2025-03-18' },
      { id: 6, sender: 'Anita John', text: `Got it, thanks so much Maya. I really appreciate you staying on top of this!`, time: '4:21 PM', isSender: true, date: '2025-03-18' },
      { id: 7, sender: 'Anita John', text: `Got it, thanks so much Maya. I really appreciate you staying on top of this!`, time: '4:21 PM', isSender: false, date: '2025-03-18' },
      { id: 8, sender: 'Anita John', text: `Got it, thanks so much Maya. I really appreciate you staying on top of this!`, time: '4:21 PM', isSender: true, date: '2025-03-19' },
      { id: 9, sender: 'Anita John', text: `Got it, thanks so much Maya. I really appreciate you staying on top of this!`, time: '4:21 PM', isSender: false, date: '2025-03-19' },
      { id: 10, sender: 'Anita John', text: `Got it, thanks so much Maya. I really appreciate you staying on top of this!`, time: '4:21 PM', isSender: true, date: '2025-03-19' },
      { id: 11, sender: 'Anita John', text: `Got it, thanks so much Maya. I really appreciate you staying on top of this!`, time: '4:21 PM', isSender: false, date: '2025-03-19' },
      { id: 12, sender: 'Anita John', text: `Got it, thanks so much Maya. I really appreciate you staying on top of this!`, time: '4:21 PM', isSender: true, date: '2025-03-20' },
      { id: 13, sender: 'Anita John', text: `Got it, thanks so much Maya. I really appreciate you staying on top of this!`, time: '4:21 PM', isSender: false, date: '2025-03-20' },
      { id: 14, sender: 'Anita John', text: `jjjjjj kkkkk 12 1 2`, time: '4:21 PM', isSender: true, date: '2025-03-20' },
    ];

    return (
      <MainChatDiv>
        <div className="headersection">
          <div className="titlesection">
            <Typography className="mainchattitle">Conduct Trademark Search</Typography>
            <StyleBadge data-state="digip"  >In progress</StyleBadge>
          </div>
          <FlexDiv>
            <div>
              <StyleBadgeMeet >
                <img src={dateIcon} />Schedule meeting
              </StyleBadgeMeet>
            </div>
            <FlexDiv>
              <Avatar
                src={userIcon}
                style={{ width: 39, height: 38, marginRight: '8px' }}
              />
              <FlexDiv style={{ flexDirection: "column", alignItems: "start" }}>
                <Typography className="username">Anita John</Typography>
                <Typography className="countryname"><img src={countryIcon} />USA</Typography>
              </FlexDiv>
            </FlexDiv>
          </FlexDiv>
          <div>
            <img src={threeDots} />
          </div>
        </div>


        <StyleMessgeDiv>
          {messages1.map((msg, index) => {
            const isToday = new Date(msg.date).toDateString() === new Date().toDateString();
            const isFirstMessageToday =
              isToday &&
              (index === 0 || new Date(messages1[index - 1].date).toDateString() !== new Date().toDateString());

            return (
              <div key={msg.id}>
                {isFirstMessageToday && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '16px',
                      position: 'relative',
                    }}
                  >
                    <Box
                      style={{
                        position: 'absolute',
                        left: 0,
                        right: "330px",
                        top: '50%',
                        height: '1px',
                        backgroundColor: '#9AB1FF',
                      }}
                    />
                    <Typography
                      style={{
                        backgroundColor: '#FFF',
                        padding: '7px 14px',
                        fontWeight: 600,
                        fontSize: '12px',
                        color: '#9AB1FF',
                        zIndex: 1,
                        border: "1px solid #9AB1FF",
                        borderRadius: "50px"
                      }}
                    >
                      Today
                    </Typography>
                    <Box
                      style={{
                        position: 'absolute',
                        left: "330px",
                        right: "0",
                        top: '50%',
                        height: '1px',
                        backgroundColor: '#9AB1FF',
                      }}
                    />
                  </Box>
                )}

                <div
                  style={{
                    display: 'flex',
                    justifyContent: msg.isSender ? 'flex-end' : 'flex-start',
                    marginBottom: '42px',
                  }}
                >
                  {!msg.isSender && (
                    <Avatar
                      alt={msg.sender}
                      src={userIcon}
                      style={{ width: 44, height: 44, marginRight: '8px' }}
                    />
                  )}
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      maxWidth: '70%',
                      color: '#000',
                    }}
                  >
                    <Typography variant="body2" className="messagedesc">
                      {msg.isSender
                        ? `${msg.sender}    ${msg.time}`
                        : `${msg.time}  ${msg.sender}`}
                    </Typography>
                    <Box
                      style={{
                        backgroundColor: !msg.isSender ? '#dbeafe' : '#f3f4f6',
                        padding: '12px',
                        borderRadius: !msg.isSender
                          ? '16px 16px 0 16px'
                          : '16px 16px 16px 0',
                      }}
                    >
                      <Typography variant="body1" className="messagestext">
                        {msg.text}
                      </Typography>
                    </Box>
                  </Box>
                  {msg.isSender && (
                    <Avatar
                      alt={msg.sender}
                      src="https://i.pravatar.cc/40"
                      style={{ width: 44, height: 44, marginLeft: '8px' }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </StyleMessgeDiv>

        <Box
          style={{
            position: 'sticky',
            bottom: 0,
            left: 0,
            right: 0,
            padding: '8px',
            backgroundColor: '#fff',
          }}
        >
          <TextField
            inputProps={{ 'data-test-id': 'account_number' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ marginRight: "-40px" }}>
                  <IconButton style={{ marginTop: "40px" }}>
                    <img src={attachmentIcon} />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton style={{ marginTop: "40px", backgroundColor: '#355EE7', borderRadius: '8px', color: '#fff', width: "40px", height: "36px" }}>
                    <img src={sendmessageIcon} />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                borderRadius: '12px',
                background: "#FAFAFA"
              },
            }}
            name="account_number"
            type="text"
            multiline
            rows={3}
            fullWidth
            variant="outlined"
            placeholder="Send a Message to Maya Diab"
          />
        </Box>
      </MainChatDiv>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainContainer>
        <Box style={{ display: "flex", justifyContent: "start", gap: "10px", alignItems: "start" }}>
          <Box className="navigationstyle"  >
            <NavigationMenu navigation={this.props.navigation} id={""} />
          </Box>
          <Box
            className="repsonsive"
          >
            <MainDiv >
              <div className="gridDiv1" >
                {this.renderTaskColumn()}
                <Divider orientation="vertical" flexItem style={{ marginLeft: '16px' }} />
              </div>
              {this.state.taskDrawer && <div className="gridDiv2">
                {this.renderMessagedColumn()}
                <Divider orientation="vertical" flexItem style={{ marginLeft: '16px' }} />
              </div>}
              <div className="gridDiv3" style={!this.state.taskDrawer ? {
                width: "70%"
              } : {}}>
                {this.renderChatScreen()}
              </div>
            </MainDiv>
          </Box>
        </Box>
      </MainContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const MainContainer = styled('div')({
  backgroundColor: '#fff',
  fontFamily: 'DM Sans',
  "& .repsonsive": {
    height: '100vh',
    width: "100%",
    overflow: "hidden",
    "@media (max-width: 1280px)": {
      padding: '50px',
      height: '100vh',
      overflowY: 'scroll',
      overflowX: 'hidden'
    },
    "@media (max-width: 1366px)": {
      padding: '50px',
      height: '100vh',
      overflowY: 'scroll',
      overflowX: 'hidden'
    }
  },

});

const MainDiv = styled('div')({
  display: "flex",
  justifyContent: "space-between",
  height: "100vh",
  '@media (max-width: 768px)': {
    gridTemplateColumns: '1fr',
  },
  "& .gridDiv1": {
    display: 'flex',
    width: "30%",
    justifyContent: "space-between"
  },
  "& .gridDiv2": {
    display: 'flex',
    width: "30%",
    justifyContent: "space-between"
  },
  "& .gridDiv3": {
    display: 'flex',
    width: "60%",
    justifyContent: "space-between",
    flexDirection: 'column',
  }
});

const MainTaskColumnDiv = styled('div')({
  paddingTop: "20px",
  width: "100%",
  "& .titleHeader": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "15px",

  },
  "& .maintitle": {
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: 18,
    lineHeight: "14.4px",
    color: "#0F172A",
  },
  "& .filterSection": {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    paddingLeft: "10px",
    paddingRight: "10px"
  },
  '& .tasksection': {
    marginTop: "30px",
    border: "1px solid #F0F0F0",
    borderRadius: 8,
    padding: 12,
    marginLeft: "10px",
    height: "calc(100vh - 260px)",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px"
    },
    "&::-webkit-scrollbar-track": {
      background: "#F5F5F5",
      borderRadius: 10,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#91AAFF",
      borderRadius: 10,
    },
  },
  "& .tasklistcontainer": {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    cursor: "pointer",
  },
  "& .listrow": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px"
  },
  "& .messagelistrow": {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  "& .msg_desc": {
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: 12,
    lineHeight: "14.4px",
    color: "#8C8C8C",
    marginLeft: "10px",
    marginTop: "5px"
  },
  "& .msg_hrs": {
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: 12,
    lineHeight: "14.4px",
    color: "#8C8C8C",
    marginLeft: "10px",
    marginBottom: "5px"
  },
  "& .msg_name": {
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: 14,
    lineHeight: "14.4px",
    color: "#0F172A",
    marginLeft: "10px"
  },
  "& .msg_count": {
    background: "#355EE7",
    borderRadius: "100px",
    fontWeight: 700,
    fontFamily: "DM Sans",
    fontSize: 12,
    lineHeight: "14.4px",
    color: "#FFFFFF",
    width: "16px",
    padding: "4px",
    textAlign: "center"
  }
})

const StyleTextField = styled(TextField)({
  marginTop: "3px",
  "& .MuiOutlinedInput-input": {
    padding: "12.5px 0 !important"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#C3D1FF !important",
    },
    "&.Mui-error fieldset": {
      borderColor: "#DC2626 !important",
    },

  },
})

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#355EE7',
    color: '#355EE7',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));


const MainChatDiv = styled('div')({
  width: "100%",
  "& .headersection": {
    background: "#F0F0F0",
    padding: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .mainchattitle": {
    fontWeight: 600,
    fontFamily: "DM Sans",
    fontSize: 14,
    lineHeight: "14.4px",
    color: "#262626",
  },
  "& .titlesection": {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  }
})

const StyleBadge = styled(Badge)({
  width: 96,
  display: "inline-block",
  borderRadius: 12,
  padding: "4px 0px",
  textAlign: "center",
  fontFamily: "DM Sans",
  fontWeight: 700,
  fontSize: 12,
  lineHeight: "15.62px",
  '&[data-state="digip"]': {
    backgroundColor: "#FEF3C7",
    color: "#D97706",
  },
  '&[data-state="pending"]': {
    backgroundColor: "#DCECFF",
    color: "#355EE7",
  },
  '&[data-state="paid"]': {
    backgroundColor: "#D1FAE5",
    color: "#059669",
  },
});

const StyleBadgeMeet = styled(Badge)({
  width: 160,
  borderRadius: "500px",
  padding: "10px",
  textAlign: "center",
  fontFamily: "DM Sans",
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "15.62px",
  color: "#4872FF",
  background: "#ECF1FF",
  display: "flex",
  gap: "6px",
  alignItems: "center"
});

const StyleMessgeDiv = styled("div")({
  flex: 1,
  overflowY: 'auto',
  padding: '25px',
  backgroundColor: '#fafafa',
  height: "calc(100vh - 200px)",
  "& .messagestext": {
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: 14,
    lineHeight: "14.4px",
    color: "#0F172A",
  },
  "& .messagedesc": {
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: 14,
    lineHeight: "14.4px",
    color: "#8C8C8C",
    marginBottom: '4px'
  }
})

const FlexDiv = styled('div')({
  display: "flex",
  alignItems: "center",
  gap: "3px",
  "& .username": {
    fontWeight: 600,
    fontFamily: "DM Sans",
    fontSize: 14,
    lineHeight: "14.4px",
    color: "#262626",
  },
  "& .countryname": {
    fontWeight: 400,
    fontFamily: "DM Sans",
    fontSize: 14,
    lineHeight: "14.4px",
    color: "#8C8C8C",
    display: "flex",
    alignItems: "center",
    gap: "4px"
  }
})

const StyledAccordion = styled(Accordion)({
  '&::before': {
    display: 'none',
  },
});

const StyleTabBox = styled(Box)({
  display: "flex",
  justifyContent: 'start',
  alignItems: 'end',
  paddingLeft: '10px',
  gap: '20px',
  borderBottom: "1px solid #ddd",
  marginTop: '10px'
})

// Customizable Area End
