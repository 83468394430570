import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Popover,
  styled,
  Avatar,
  Badge,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CloseIcon from '@material-ui/icons/Close';
import ArrowForward from '@material-ui/icons/ArrowForward';

// Customizable Area End

import AuditTrailController, {
  Props,
} from "./AuditTrailController";
import moment from "moment";


export default class AuditTrail extends AuditTrailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderStatus = (status: string) => {
    let statusVal
    let statusColor
    let statusBgColor
    switch (status) {
      case 'Pending':
        statusVal = "Pending"
        statusBgColor = "#DCECFF"
        statusColor = "#355EE7"
        break
      case 'Closed':
        statusVal = "Closed"
        statusBgColor = "#FEE2E2"
        statusColor = "#DC2626"
        break
      case 'In progress':
        statusVal = "In progress"
        statusBgColor = "#FEF3C7"
        statusColor = "#D97706"
        break
      case 'New':
        statusVal = "New"
        statusBgColor = "#D1FAE5"
        statusColor = "#059669"
        break
    }
    return { statusVal, statusColor, statusBgColor }
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
        <Popover
          id="mouse-over-popover"
          style={{ zIndex: 1400,left: "30px" }}
          open={Boolean(this.props.anchorEl)}
          anchorEl={this.props.anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => this.props.handleAuditPopoverClose()}
          disableAutoFocus
          disableEnforceFocus
          disableRestoreFocus
          elevation={2}
          PaperProps={{
            style: {
              width: '362px',
              borderRadius: '12px',
            },
          }}
        >
          <div>
            <StyleDivClose>
              <CloseIcon onClick={this.props.handleAuditPopoverClose} style={{ color: "#8C8C8C" }} fontSize="small" />
            </StyleDivClose>

            <PopoverDiv>
              <Typography className="popover_title">Audit trail</Typography>

              {this.state.isAuditLoading && <div style={{ textAlign: "center", padding: "30px" }}>
                <CircularProgress style={{ color: "#355EE7", width: 40, height: 40 }} />
              </div>}

              {!this.state.isAuditLoading &&
                this.state.auditData &&
                Object.entries(this.state.auditData)
                  .filter(([_, task]) => task !== "")
                  .map(([key, task], index, array) => (
                    task.user_name && (
                      <TaskContainer key={key}>

                        <TimelineLine style={{ bottom: index !== array.length - 1 ? "-30px" : "0px" }} />
                        <TaskDot />
                        <TaskContent>
                          <UserInfo>
                            <Avatar
                              alt={task.user_name}
                              src={typeof task.profile_image === "object" ? task.profile_image.url : ""}
                              style={{ width: 32, height: 32 }}
                            />
                            <Typography className="name_text">{task.user_name}</Typography>
                          </UserInfo>
                          <Typography className="description_text">{task.title}</Typography>
                          <Typography className="date_text">
                            {moment(task.created_at).format("MMMM D, hh:mm A")}
                          </Typography>
                        </TaskContent>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          {task.state &&
                            <StyledBadge style={{ backgroundColor: this.renderStatus(task.state).statusBgColor, color: this.renderStatus(task.state).statusColor }} data-state="paid">{this.renderStatus(task.state).statusVal}</StyledBadge>
                          }
                          {task.state && task.status && <ArrowForward style={{ color: "#8C8C8C" }} fontSize="small" />}
                          {task.status && <StyledBadge style={{ backgroundColor: this.renderStatus(task.status).statusBgColor, color: this.renderStatus(task.status).statusColor }} data-state="paid">{this.renderStatus(task.status).statusVal}</StyledBadge>}
                        </div>
                      </TaskContainer>
                    )
                  ))}


            </PopoverDiv>
          </div>
        </Popover>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const PopoverDiv = styled('div')({
  padding: "20px",
  paddingTop: 0,
  "& .popover_title": {
    fontSize: "22px",
    fontWeight: 400,
    fontFamily: "Dm sans",
    color: "#000000",
    paddingBottom: "18px"
  },
  "& .name_text": {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Dm sans",
    color: "#0F172A"
  },
  "& .first_line": {
    display: "flex",
    gap: "10px",
    alignItems: "center"
  },
  "& .main_status_div": {
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .description_text": {
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Dm sans",
    color: "#434343",
    width: "100%"
  },
  "& .date_text": {
    fontSize: "10px",
    fontWeight: 400,
    fontFamily: "Dm sans",
    color: "#8C8C8C"
  }
})

const StyledBadge = styled(Badge)({
  display: "inline-block",
  textAlign: "center",
  padding: "10px",
  paddingTop: 0,
  paddingBottom: 0,
  backgroundColor: "#DCECFF",
  borderRadius: 40,
  fontFamily: "DM Sans",
  fontWeight: 700,
  fontSize: 12,
  lineHeight: "23px",
  color: "#355EE7",
  '&[data-state="paid"]': {
    backgroundColor: "#D1FAE5",
    color: "#059669",
  },
  '& + span': {
    color: "#434343",
    fontFamily: "DM Sans",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "15.62px",
  },
})

const TaskContainer = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  position: "relative",
  marginBottom: "20px",
  paddingLeft: "25px",
});

const TimelineLine = styled("div")({
  position: "absolute",
  left: "3px",
  top: "8px",
  bottom: "-30px",
  width: "4px",
  backgroundColor: "#F0F0F0",
});

const TaskDot = styled("div")({
  position: "absolute",
  left: "0",
  top: "8px",
  width: "10px",
  height: "10px",
  backgroundColor: "#BFBFBF",
  borderRadius: "50%",
});

const TaskContent = styled("div")({
  flexGrow: 1,
});

const UserInfo = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

const StyleDivClose = styled('div')({
  display: "flex",
  justifyContent: "end",
  cursor: "pointer",
  padding: "7px"
})


// Customizable Area End
