import React, { useState } from 'react';
import {
    Button,
    Container,
    Typography,
    Paper,
    Grid,
    FormControl,
    IconButton,
    makeStyles,
    Theme,
    Box
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(3),
        fontFamily:"DM Sans"
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
        justifyContent: 'space-between',
        fontFamily:"DM Sans"
    },
    title: {
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
        fontFamily:"DM Sans"
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    breadcrumbs: {
        marginLeft: theme.spacing(4),
        display:'flex',
        justifyContent:'center',
        alignItems:"center",
        gap:'10px'
    },
    section: {
        marginBottom: theme.spacing(4),
        borderBottom: "1px solid #F0F0F0",
        justifyContent: 'space-between',
        gap: "50px",
        display:"flex",
        fontFamily:"DM Sans"
    },
    sectionTitle: {
        fontWeight: 700,
        color:'#0F172A',
        fontSize:"18px",
        marginBottom: theme.spacing(2),
        fontFamily:"DM Sans"
    },
    formField: {
        marginBottom: theme.spacing(2),
        fontFamily:"DM Sans"
    },
    notificationIcon: {
        marginLeft: 'auto'
    },
    textArea: {
        width: '100%',
        padding: theme.spacing(1.5),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        fontFamily:"DM Sans",
        fontWeight:400,
        fontSize:'16px',
        '&:focus': {
            outline: 'none',
            borderColor: theme.palette.primary.main
        }
    },
    disabledField: {
        width: "100%",
        height: "53px",
        border: "1px solid #C3D1FF", 
        borderRadius: "12px", 
        display: 'flex', 
        alignItems: 'center', 
        paddingLeft: '12px',
        backdropFilter:'#FAFAFA',
        fontFamily:"DM Sans",
        fontSize:'16px',
        fontWeight:400
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(3),
        fontFamily:"DM Sans",
        '& > *': {
            marginLeft: theme.spacing(2),
        },
    },
    documentButtons: {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        gap:'4px',
        backgroundColor:'#4872FF',
        color: 'white',
        borderRadius:'8px',
        border:'none',
        width:'203px',
        minWidth:'150px',
        height:'44px',
        fontWeight:400,
        fontSize:'16px',
        cursor: 'pointer',       
         fontFamily:"DM Sans"
    },
    saveButton: {
        backgroundColor:'white',
        color: '#355EE7',
        borderRadius:'8px',
        border:'1px solid #355EE7',
        width:'163px',
        height:'44px',
        fontFamily:"DM Sans",
        fontWeight:400,
        fontSize:'16px',
        marginRight:'8px'
    },
    fieldTitle: {
        fontSize:"14px",
        fontWeight:600,
        color:"#434343",
        fontFamily:"DM Sans",
    }
}));

interface TaskFormData {
    taskName: string;
    trademarkName: string;
    applicationNumber: string;
    deadline: string;
    legalServiceType: string;
    jurisdiction: string;
    description: string;
}

interface TaskFormProps {
    task_name: string;
    trademark_name: string;
    trademark_application_number: string;
    deadline: string;
    legalServiceType: string;
    jurisdiction: string;
    description: string;
    saveDraft: (formData: TaskFormData) => void;
    submit: (formData: TaskFormData) => void;
    closeTab:()=>void;
    isDraft:boolean;
    setIsDraft:()=>void;
    handleNavigationProject:()=>void;
}

const TaskForm: React.FC<TaskFormProps> = ({
    task_name,
    trademark_name,
    trademark_application_number,
    deadline,
    legalServiceType,
    jurisdiction,
    description,
    handleNavigationProject,
    saveDraft,
    submit,
    closeTab,
    isDraft,
    setIsDraft
}) => {
    const classes = useStyles();
    const [formData, setFormData] = useState<TaskFormData>({
        taskName: task_name || "",
        trademarkName: trademark_name || "",
        applicationNumber: trademark_application_number || "",
        deadline: deadline || "",
        legalServiceType: legalServiceType || "",
        jurisdiction: jurisdiction || "",
        description: description || ""
    });

    

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSaveDraft = () => {
        saveDraft(formData);
    };

    const handleSubmit = () => {
        submit(formData);
    };

    return (
        <Container>
            <Paper className={classes.root} elevation={0}>
                <div className={classes.header}>
                    <Box>
                        <Typography variant="h6" className={classes.title}>
                            <IconButton className={classes.backButton} size="small"  onClick={closeTab}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.2501 10.8125H4.96571L15.9095 1.3125C16.0845 1.15938 15.9782 0.875 15.747 0.875H12.9813C12.8595 0.875 12.7438 0.91875 12.6532 0.996875L0.84384 11.2437C0.73568 11.3375 0.648936 11.4534 0.589486 11.5836C0.530035 11.7138 0.499268 11.8553 0.499268 11.9984C0.499268 12.1416 0.530035 12.283 0.589486 12.4133C0.648936 12.5435 0.73568 12.6594 0.84384 12.7531L12.722 23.0625C12.7688 23.1031 12.8251 23.125 12.8845 23.125H15.7438C15.9751 23.125 16.0813 22.8375 15.9063 22.6875L4.96571 13.1875H23.2501C23.3876 13.1875 23.5001 13.075 23.5001 12.9375V11.0625C23.5001 10.925 23.3876 10.8125 23.2501 10.8125Z" fill="#7192FF"/>
</svg>

                            </IconButton>
                            New Task
                        </Typography>
                        <Box className={classes.breadcrumbs}>
                            <Typography style={{color:'#475569', fontWeight:400, fontSize:"14px", cursor:"pointer"}} onClick={closeTab}> 
                                All tasks </Typography>
                         <Typography>
                            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.500098 0.592214C0.175098 0.917214 0.175098 1.44221 0.500098 1.76721L3.73343 5.00055L0.500098 8.23388C0.175098 8.55888 0.175098 9.08388 0.500098 9.40888C0.825098 9.73388 1.3501 9.73388 1.6751 9.40888L5.5001 5.58388C5.8251 5.25888 5.8251 4.73388 5.5001 4.40888L1.6751 0.583881C1.35843 0.267214 0.825098 0.267214 0.500098 0.592214Z" fill="#64748B"/>
</svg>
</Typography>

                            <Typography style={{color:"#1E293B", fontWeight:400, cursor:'pointer'}} onClick={handleNavigationProject}>Add new task</Typography>
                        </Box>
                    </Box>
                    <IconButton className={classes.notificationIcon}>
                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.2902 15.04L14.0002 13.75V8.75C14.0002 5.68 12.3602 3.11 9.50017 2.43V1.75C9.50017 0.92 8.83017 0.25 8.00017 0.25C7.17017 0.25 6.50017 0.92 6.50017 1.75V2.43C3.63017 3.11 2.00017 5.67 2.00017 8.75V13.75L0.710173 15.04C0.0801735 15.67 0.520173 16.75 1.41017 16.75H14.5802C15.4802 16.75 15.9202 15.67 15.2902 15.04ZM4.00017 14.75V8.75C4.00017 6.27 5.51017 4.25 8.00017 4.25C10.4902 4.25 12.0002 6.27 12.0002 8.75V14.75H4.00017ZM10.0002 17.75C10.0002 18.85 9.10017 19.75 8.00017 19.75C6.89017 19.75 6.00017 18.85 6.00017 17.75H10.0002Z" fill="#BFBFBF"/>
</svg>
                    </IconButton>
                </div>

                <div className={classes.section}>
                    <Box style={{width:'12%'}}>
                        <Typography variant="h6" className={classes.sectionTitle}>
                            Task Name
                        </Typography>
                    </Box>
                    <Box style={{width:'88%'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography className={classes.fieldTitle}>Task Name<span style={{color:'red'}}>*</span></Typography>
                                <FormControl variant="outlined" fullWidth className={classes.formField}>
                                    <Box className={classes.disabledField}>
                                        {formData.taskName}
                                    </Box>
                                </FormControl>
                            </Grid>

                            <div style={{display:'flex',gap:'35px', width:'99%', padding:"8px"}}>
                            
                            <Grid item xs={6}>
                                <Typography className={classes.fieldTitle}>Trademark Name<span style={{color:'red'}}>*</span></Typography>
                                <FormControl variant="outlined" fullWidth className={classes.formField}>
                                    <Box className={classes.disabledField}>
                                        {formData.trademarkName}
                                    </Box>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.fieldTitle}>Application Number<span style={{color:'red'}}>*</span></Typography>
                                <FormControl variant="outlined" fullWidth className={classes.formField}>
                                    <Box className={classes.disabledField}>
                                        {formData.applicationNumber}
                                    </Box>
                                </FormControl>
                            </Grid>
                            </div>
                        </Grid>
                    </Box>
                </div>

                <div className={classes.section}>
                    <Box style={{width:'12%'}}>
                        <Typography variant="h6" className={classes.sectionTitle}>
                            Legal Info
                        </Typography>
                    </Box>
                    <Box style={{width:'88%'}}>
                        <Grid  container spacing={2}>
                            <div style={{display:'flex', gap:'35px', width:'99%', padding:'8px'}}>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth className={classes.formField}>
                                    <Typography className={classes.fieldTitle}>Deadline<span style={{color:'red'}}>*</span></Typography>
                                    <Box className={classes.disabledField} style={{display:"flex", justifyContent:'space-between', alignItems:'center'}}>
                                        {formData.deadline}
                                        <svg width="20" height="20" style={{marginRight:'13px'}} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.625 2.3125H14.6875V0.8125C14.6875 0.709375 14.6031 0.625 14.5 0.625H13.1875C13.0844 0.625 13 0.709375 13 0.8125V2.3125H7V0.8125C7 0.709375 6.91563 0.625 6.8125 0.625H5.5C5.39687 0.625 5.3125 0.709375 5.3125 0.8125V2.3125H1.375C0.960156 2.3125 0.625 2.64766 0.625 3.0625V18.625C0.625 19.0398 0.960156 19.375 1.375 19.375H18.625C19.0398 19.375 19.375 19.0398 19.375 18.625V3.0625C19.375 2.64766 19.0398 2.3125 18.625 2.3125ZM17.6875 17.6875H2.3125V8.78125H17.6875V17.6875ZM2.3125 7.1875V4H5.3125V5.125C5.3125 5.22813 5.39687 5.3125 5.5 5.3125H6.8125C6.91563 5.3125 7 5.22813 7 5.125V4H13V5.125C13 5.22813 13.0844 5.3125 13.1875 5.3125H14.5C14.6031 5.3125 14.6875 5.22813 14.6875 5.125V4H17.6875V7.1875H2.3125Z" fill="#BFBFBF"/>
</svg>

                                    </Box>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" fullWidth className={classes.formField}>
                                    <Typography className={classes.fieldTitle}>Legal Service Type</Typography>
                                    <Box className={classes.disabledField}>
                                        {formData.legalServiceType}
                                    </Box>
                                </FormControl>
                            </Grid>
                            </div>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth className={classes.formField}>
                                    <Typography className={classes.fieldTitle}>Jurisdiction</Typography>
                                    <Box className={classes.disabledField}>
                                        {formData.jurisdiction}
                                    </Box>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </div>

                <div className={classes.section}>
                    <Box style={{ width: '12%' }}>
                        <Typography variant="h6" className={classes.sectionTitle}>
                            Notes
                        </Typography>
                    </Box>
                    <Box style={{ width: '88%' }}>
                        <Typography className={classes.fieldTitle}>Description</Typography>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            className={classes.textArea}
                            rows={5}
                            placeholder="Description"
                        />
                    </Box>
                </div>

                <div className={classes.buttonsContainer}>
                    <button className={classes.documentButtons}>
                        <AttachFileIcon /> Documents Folder
                    </button>
                    <Box>
                        <Button
                            color="primary"
                            className={classes.saveButton}
                            onClick={handleSaveDraft}
                            disabled
                            style={{ textTransform: "none" }} 
                        >
                            <Typography style={{color:'#355EE7'}}>Save As Draft</Typography>
                        </Button>
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor:'#355EE7',
                                color: 'white',
                                borderRadius:'8px',
                                border:'none',
                                width:'163px',
                                height:'44px',
                                fontWeight:400,
                                fontSize:'16px',
                                textTransform: "none",
                            }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Box>
                </div>
            </Paper>
        </Container>
    );
};

export default TaskForm;