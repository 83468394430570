import React from 'react';
interface ActiveIconProps {
    color: string;
  }
  
  export default function ActiveMsgIcon({ color }: ActiveIconProps) {
    return(
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8884 11.875C10.8884 12.1367 10.9923 12.3876 11.1774 12.5726C11.3624 12.7577 11.6133 12.8616 11.875 12.8616C12.1367 12.8616 12.3876 12.7577 12.5726 12.5726C12.7577 12.3876 12.8616 12.1367 12.8616 11.875C12.8616 11.6133 12.7577 11.3624 12.5726 11.1774C12.3876 10.9923 12.1367 10.8884 11.875 10.8884C11.6133 10.8884 11.3624 10.9923 11.1774 11.1774C10.9923 11.3624 10.8884 11.6133 10.8884 11.875ZM13.9576 11.875C13.9576 12.1367 14.0615 12.3876 14.2465 12.5726C14.4316 12.7577 14.6825 12.8616 14.9442 12.8616C15.2058 12.8616 15.4568 12.7577 15.6418 12.5726C15.8268 12.3876 15.9308 12.1367 15.9308 11.875C15.9308 11.6133 15.8268 11.3624 15.6418 11.1774C15.4568 10.9923 15.2058 10.8884 14.9442 10.8884C14.6825 10.8884 14.4316 10.9923 14.2465 11.1774C14.0615 11.3624 13.9576 11.6133 13.9576 11.875ZM7.81925 11.875C7.81925 12.1367 7.92319 12.3876 8.10821 12.5726C8.29323 12.7577 8.54418 12.8616 8.80584 12.8616C9.06751 12.8616 9.31845 12.7577 9.50347 12.5726C9.6885 12.3876 9.79244 12.1367 9.79244 11.875C9.79244 11.6133 9.6885 11.3624 9.50347 11.1774C9.31845 10.9923 9.06751 10.8884 8.80584 10.8884C8.54418 10.8884 8.29323 10.9923 8.10821 11.1774C7.92319 11.3624 7.81925 11.6133 7.81925 11.875Z" fill={color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9999 11.9069C19.0047 10.9387 18.817 9.99852 18.4465 9.11432C18.0871 8.26041 17.5715 7.4934 16.9152 6.83538C16.2628 6.18067 15.4884 5.66024 14.6358 5.30355C13.7609 4.93605 12.8317 4.75 11.875 4.75L11.8443 4.75L11.8431 4.75001C10.8804 4.75477 9.94664 4.9455 9.06832 5.321C8.22299 5.68133 7.45584 6.20268 6.80963 6.856C6.15961 7.51241 5.64879 8.27611 5.29554 9.1274C4.92977 10.0085 4.74532 10.9453 4.75009 11.9085C4.75539 12.9929 5.00739 14.0616 5.48668 15.0337V17.3075C5.48668 17.561 5.5874 17.8041 5.76666 17.9834C5.94593 18.1627 6.18907 18.2634 6.44259 18.2634H8.71786C9.69 18.7427 10.7587 18.9947 11.8431 19H11.8765C12.8285 19 13.7528 18.8155 14.6227 18.4545C15.471 18.1021 16.2425 17.5878 16.894 16.9403C17.5507 16.29 18.0679 15.5294 18.429 14.6815C18.8045 13.8033 18.9951 12.8695 18.9999 11.9069ZM9.31016 16.6955C10.0855 17.1088 10.9647 17.3292 11.8497 17.3337H11.875C13.3286 17.3337 14.6924 16.7734 15.7212 15.7549C16.7542 14.7306 17.3279 13.3623 17.3367 11.8998C17.3396 11.1565 17.1972 10.4379 16.9127 9.76031C16.6384 9.10609 16.2424 8.51786 15.7388 8.01425C15.2337 7.50914 14.6468 7.1146 13.9926 6.84028C13.3223 6.55867 12.611 6.4163 11.8765 6.4163H11.851C10.3871 6.42229 9.01913 6.99453 7.99511 8.02884C6.96951 9.06325 6.41053 10.4357 6.41636 11.8999C6.42078 12.785 6.64122 13.6644 7.05452 14.4399L7.15297 14.6236V16.5971H9.1264L9.31016 16.6955Z" fill={color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8884 11.875C10.8884 12.1367 10.9923 12.3876 11.1774 12.5726C11.3624 12.7577 11.6133 12.8616 11.875 12.8616C12.1367 12.8616 12.3876 12.7577 12.5726 12.5726C12.7577 12.3876 12.8616 12.1367 12.8616 11.875C12.8616 11.6133 12.7577 11.3624 12.5726 11.1774C12.3876 10.9923 12.1367 10.8884 11.875 10.8884C11.6133 10.8884 11.3624 10.9923 11.1774 11.1774C10.9923 11.3624 10.8884 11.6133 10.8884 11.875ZM13.9576 11.875C13.9576 12.1367 14.0615 12.3876 14.2465 12.5726C14.4316 12.7577 14.6825 12.8616 14.9442 12.8616C15.2058 12.8616 15.4568 12.7577 15.6418 12.5726C15.8268 12.3876 15.9308 12.1367 15.9308 11.875C15.9308 11.6133 15.8268 11.3624 15.6418 11.1774C15.4568 10.9923 15.2058 10.8884 14.9442 10.8884C14.6825 10.8884 14.4316 10.9923 14.2465 11.1774C14.0615 11.3624 13.9576 11.6133 13.9576 11.875ZM7.81925 11.875C7.81925 12.1367 7.92319 12.3876 8.10821 12.5726C8.29323 12.7577 8.54418 12.8616 8.80584 12.8616C9.06751 12.8616 9.31845 12.7577 9.50347 12.5726C9.6885 12.3876 9.79244 12.1367 9.79244 11.875C9.79244 11.6133 9.6885 11.3624 9.50347 11.1774C9.31845 10.9923 9.06751 10.8884 8.80584 10.8884C8.54418 10.8884 8.29323 10.9923 8.10821 11.1774C7.92319 11.3624 7.81925 11.6133 7.81925 11.875Z" fill={color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.13396 3.16689C2.48016 3.82305 1.96647 4.58632 1.61121 5.4372L3.13396 3.16689Z" fill="#355EE7"/>
      <path d="M5.4049 1.63304C4.55506 1.99307 3.78374 2.51401 3.13396 3.16689C2.48016 3.82305 1.96647 4.58632 1.61121 5.4372C1.24333 6.31791 1.05779 7.25442 1.06259 8.21739C1.06794 9.30643 1.32373 10.3796 1.81009 11.3549V13.663C1.81009 13.902 1.9056 14.1309 2.07523 14.2995C2.24482 14.468 2.47459 14.5625 2.71394 14.5625H4.37488C4.06238 14.0625 3.9627 13.4375 3.9541 13.0112H3.36863V10.9721L3.2762 10.8007C2.85171 10.0092 2.62565 9.11229 2.62113 8.20934C2.61515 6.71598 3.18902 5.31525 4.24211 4.25961C5.29367 3.20401 6.69884 2.61989 8.20221 2.6138H8.2283C8.98247 2.6138 9.71329 2.75919 10.4018 3.04668C10.7476 3.19077 11.1338 3.35363 11.5624 3.70237C12.4502 3.63694 12.8921 3.66431 13.717 3.75003C13.6454 3.65784 13.5623 3.56091 13.4713 3.46208C13.2689 3.24215 13.0177 3.0019 12.744 2.76732C12.1997 2.301 11.5501 1.84334 11.0024 1.61559C10.1228 1.2484 9.18875 1.0625 8.22675 1.0625L8.1956 1.0625L8.19468 1.0625C7.22662 1.06727 6.28786 1.25787 5.4049 1.63304Z" fill={color}/>
      </svg>
      
    )

}