import React from "react";
// Customizable Area Start
import { Badge, Button, CircularProgress, Dialog, DialogContent, Divider, Grid, IconButton, styled, Typography } from "@material-ui/core";

import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import InvoiceBilling from "../../../blocks/invoicebilling/src/InvoiceBilling.web";
import { NotificationsNoneRounded, KeyboardArrowDown, ArrowForwardIosRounded } from "@material-ui/icons";

import DashboardController, {
    Props,
} from "./PaymentController";
import { date, group, history, historyFill, pending, pendingFill, rejectIcon, step1, step2, successIcon, transaction1, transaction2, user } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import { ToastContainer } from "react-toastify";

import moment from "moment";

// Customizable Area End

export default class Payment extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderHeader = () => {
        return <header className="header" >
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="44" height="44" rx="22" fill="#C3D1FF" />
                <rect width="24" height="24" transform="translate(10 10)" fill="#C3D1FF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M31.75 13.75H12.25C11.8352 13.75 11.5 14.0852 11.5 14.5V29.5C11.5 29.9148 11.8352 30.25 12.25 30.25H22.75C22.2962 29.6489 22.1345 29.2785 22 28.5625H13.1875V20.3125H30.8125V20.875C31.6932 21.146 31.7035 21.1645 32.5 21.8218V14.5C32.5 14.0852 32.1648 13.75 31.75 13.75ZM30.8125 15.4375H13.1875V18.25H30.8125V15.4375Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M28.5625 22C25.7664 22 23.5 24.2664 23.5 27.0625C23.5 29.8586 25.7664 32.125 28.5625 32.125C31.3586 32.125 33.625 29.8586 33.625 27.0625C33.625 24.2664 31.3586 22 28.5625 22ZM28.5625 30.625C29.5141 30.625 30.4094 30.2547 31.082 29.582C31.7547 28.9094 32.125 28.0141 32.125 27.0625C32.125 26.1109 31.7547 25.2156 31.082 24.543C30.4094 23.8703 29.5141 23.5 28.5625 23.5C27.6109 23.5 26.7156 23.8703 26.043 24.543C25.3703 25.2156 25 26.1109 25 27.0625C25 28.0141 25.3703 28.9094 26.043 29.582C26.7156 30.2547 27.6109 30.625 28.5625 30.625Z" fill="white" />
                <path d="M28.3119 30.1806V24.2474H28.7507V30.1806H28.3119ZM28.5887 29.6744C28.2512 29.6744 27.9452 29.6181 27.6707 29.5056C27.4007 29.3886 27.1869 29.2176 27.0294 28.9926C26.8719 28.7676 26.7864 28.4909 26.7729 28.1624H27.8529C27.8574 28.2929 27.8912 28.4076 27.9542 28.5066C28.0172 28.6056 28.1027 28.6844 28.2107 28.7429C28.3187 28.7969 28.4447 28.8239 28.5887 28.8239C28.7102 28.8239 28.8159 28.8059 28.9059 28.7699C29.0004 28.7294 29.0747 28.6731 29.1287 28.6011C29.1827 28.5246 29.2097 28.4301 29.2097 28.3176C29.2097 28.2006 29.1782 28.1016 29.1152 28.0206C29.0522 27.9351 28.9667 27.8631 28.8587 27.8046C28.7507 27.7416 28.6247 27.6854 28.4807 27.6359C28.3412 27.5819 28.1904 27.5301 28.0284 27.4806C27.6549 27.3591 27.3669 27.1949 27.1644 26.9879C26.9664 26.7809 26.8674 26.5041 26.8674 26.1576C26.8674 25.8696 26.9372 25.6244 27.0767 25.4219C27.2207 25.2149 27.4164 25.0574 27.6639 24.9494C27.9114 24.8414 28.1927 24.7874 28.5077 24.7874C28.8317 24.7874 29.1152 24.8436 29.3582 24.9561C29.6057 25.0641 29.8014 25.2239 29.9454 25.4354C30.0939 25.6424 30.1727 25.8899 30.1817 26.1779H29.0949C29.0904 26.0789 29.0612 25.9889 29.0072 25.9079C28.9577 25.8269 28.8879 25.7616 28.7979 25.7121C28.7124 25.6626 28.6112 25.6379 28.4942 25.6379C28.3907 25.6334 28.2962 25.6491 28.2107 25.6851C28.1252 25.7166 28.0577 25.7684 28.0082 25.8404C27.9587 25.9079 27.9339 25.9934 27.9339 26.0969C27.9339 26.1959 27.9587 26.2836 28.0082 26.3601C28.0622 26.4321 28.1342 26.4951 28.2242 26.5491C28.3187 26.5986 28.4267 26.6459 28.5482 26.6909C28.6742 26.7359 28.8114 26.7809 28.9599 26.8259C29.1984 26.9069 29.4167 27.0036 29.6147 27.1161C29.8172 27.2241 29.9792 27.3681 30.1007 27.5481C30.2267 27.7236 30.2897 27.9576 30.2897 28.2501C30.2897 28.5066 30.2222 28.7429 30.0872 28.9589C29.9567 29.1749 29.7654 29.3481 29.5134 29.4786C29.2659 29.6091 28.9577 29.6744 28.5887 29.6744Z" fill="white" />
            </svg>
            <h1>Payments</h1>
            <IconButton style={{ padding: 0, marginLeft: "auto" }} >
                <NotificationsNoneRounded htmlColor="#BFBFBF" />
            </IconButton>
        </header>
    }

    renderTaskList = () => {
        return (
            <>
                {this.state.IsTaskLoading &&
                    <div style={{ textAlign: "center", padding: "30px" }}>
                        <CircularProgress style={{ color: "#355EE7", width: 40, height: 40 }} />
                    </div>
                }
                {!this.state.IsTaskLoading && this.state.paymentTasks.map((task) => (
                    <div className="main_row" style={this.state.taskId === String(task.task_id) ? { background: "#ECF1FF" } : {}} data-test-id={`pending-row-child-${task.task_id}`} onClick={() => this.handleClickPendingTaskRow(task)}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <img src={group} width={44} height={44} />
                                    <div className="flex flex-column" style={{ gap: 8 }} >
                                        <Typography className="task_title" component="p" >{task.task_name}</Typography>
                                        <Typography className="task_date" component="p" ><img src={date} /> {moment(task.date).format("DD/MM/yyyy")}</Typography>
                                        <Typography className="last_desc" component="p" > <img src={user} />{task.lawfirm} </Typography>

                                    </div>
                                </div>
                                <Typography className="task_amount" component="p" >&euro; {task.amount} EUR</Typography>
                            </div>
                            <div>
                                <IconButton style={{ padding: 0 }}>
                                    <ArrowForwardIosRounded
                                        htmlColor="#355EE7"
                                        style={{ fontSize: "1.25rem" }}
                                    />
                                </IconButton>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                ))}
            </>
        )
    }

    renderStatus = (status: string) => {
        let statusVal
        let statusColor
        let statusBgColor
        switch (status) {
            case 'pending':
                statusVal = "Pending"
                statusBgColor = "#DCECFF"
                statusColor = "#355EE7"
                break
            case 'rejected':
                statusVal = "Rejected"
                statusBgColor = "#FEE2E2"
                statusColor = "#DC2626"
                break
            case 'paid':
                statusVal = "Paid"
                statusBgColor = "#D1FAE5"
                statusColor = "#059669"
                break
        }
        return { statusVal, statusColor, statusBgColor }
    }

    renderStep1 = () => {
        const { pendingTaskItem, pendingTaskInfo } = this.state
        return <div className="flex items-center" style={{ gap: 12 }} >
            <img src={step1} style={{ width: "100%", marginBottom: "30px" }} />
            <div style={{ marginTop: "-10px", marginBottom: "20px", display: "flex", alignItems: "center", gap: "10px" }}>
                <img src={transaction1} />
                <div> <Typography component="span" className="transaction_info" style={{ marginTop: "10px", marginBottom: "10px" }}>Transaction ID:</Typography> <span className="transaction_info" style={{ color: "#355EE7" }}>{pendingTaskInfo?.payment.transaction_id}</span></div>
            </div>
            <Typography component="p" className="title_info">Payment Info</Typography>
            <div style={{ marginTop: "40px" }} >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="body2" className="info_key" component="span" >Provider:</Typography>
                    <Typography variant="body2" className="info_value" component="span" >{pendingTaskItem?.lawfirm} </Typography>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                    <Typography variant="body2" className="info_key" component="span" >Task Price:</Typography>
                    <Typography variant="body2" className="info_value" component="span" >&euro; {pendingTaskItem?.amount?.toFixed(2)}  EUR </Typography>
                </div>
                <hr className="border_total" />
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                    <Typography variant="body2" className="total_key" component="span" >TOTAL:</Typography>
                    <Typography variant="body2" className="total_val" component="span" >&euro; {pendingTaskItem?.amount?.toFixed(2)} EUR </Typography>
                </div>
            </div>
        </div>
    }

    renderStep2 = () => {
        const { pendingTaskInfo } = this.state
        return <div className="flex items-center" style={{ gap: 12 }} >
            <img src={step2} style={{ width: "100%", marginBottom: "30px" }} />
            <div style={{ marginTop: "-10px", marginBottom: "20px", display: "flex", alignItems: "center", gap: "10px" }}>
                <img src={transaction2} />
                <div> <Typography component="span" className="transaction_info" style={{ marginTop: "10px", marginBottom: "10px" }}>Transaction ID:</Typography> <span className="transaction_info" style={{ color: "#355EE7" }}>{pendingTaskInfo?.task.transaction_id}</span></div>
            </div>
            <Typography component="p" className="title_info">Task Info</Typography>
            <div style={{ marginTop: "30px" }} >
                <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                    <Typography variant="body2" className="task_key" component="span" >{pendingTaskInfo?.task.task_name}</Typography>
                    <Typography variant="body2" className="task_value" component="span" style={{display: "flex",alignItems:"center",gap:"6px"}}>
                        {moment(pendingTaskInfo?.task.created_at).format("DD/MM/yyyy")}
                        <img src={date} style={{marginBottom:"4px"}} />
                    </Typography>
                </div>

                <div style={{ display: "flex", flexDirection: "column", gap: "4px", marginTop: "20px" }}>
                    <Typography variant="body2" className="task_key" component="span" >Trademark Name</Typography>
                    <Typography variant="body2" className="task_value" component="span" >{pendingTaskInfo?.task.trademark_name}</Typography>
                </div>

                <div style={{ display: "flex", flexDirection: "column", gap: "4px", marginTop: "20px" }}>
                    <Typography variant="body2" className="task_key" component="span" >Trademark Application Number</Typography>
                    <Typography variant="body2" className="task_value" component="span" >{pendingTaskInfo?.task.trademark_application_number}</Typography>
                </div>

                <hr className="border_total" />
                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                    <Typography variant="body2" className="total_key" component="span" >TOTAL:</Typography>
                    <Typography variant="body2" className="total_val" component="span" >&euro; {pendingTaskInfo?.payment.task_price?.toFixed(2)} EUR </Typography>
                </div>
            </div>
        </div>
    }

    renderTaskInfo = () => {

        if (this.state.taskInfoLoading) {
            return (
                <div style={{ textAlign: "center", padding: "30px" }}>
                    <CircularProgress style={{ color: "#355EE7", width: 40, height: 40 }} />
                </div>
            )
        }

        if (!this.state.taskId) {
            return (
                <MainDivNoInvoice >
                    <div className="icon" >
                        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.4982 10.2539V8.7539C23.4982 8.6164 23.3857 8.5039 23.2482 8.5039H11.2482C11.1107 8.5039 10.9982 8.6164 10.9982 8.7539V10.2539C10.9982 10.3914 11.1107 10.5039 11.2482 10.5039H23.2482C23.3857 10.5039 23.4982 10.3914 23.4982 10.2539ZM11.2482 13.0039C11.1107 13.0039 10.9982 13.1164 10.9982 13.2539V14.7539C10.9982 14.8914 11.1107 15.0039 11.2482 15.0039H16.9982C17.1357 15.0039 17.2482 14.8914 17.2482 14.7539V13.2539C17.2482 13.1164 17.1357 13.0039 16.9982 13.0039H11.2482Z" fill="#8C8C8C" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M23.749 18.0039C20.0209 18.0039 16.999 21.0258 16.999 24.7539C16.999 28.482 20.0209 31.5039 23.749 31.5039C27.4771 31.5039 30.499 28.482 30.499 24.7539C30.499 21.0258 27.4771 18.0039 23.749 18.0039ZM23.749 29.5039C25.0178 29.5039 26.2115 29.0102 27.1084 28.1133C28.0053 27.2164 28.499 26.0227 28.499 24.7539C28.499 23.4852 28.0053 22.2914 27.1084 21.3945C26.2115 20.4977 25.0178 20.0039 23.749 20.0039C22.4803 20.0039 21.2865 20.4977 20.3896 21.3945C19.4928 22.2914 18.999 23.4852 18.999 24.7539C18.999 26.0227 19.4928 27.2164 20.3896 28.1133C21.2865 29.0102 22.4803 29.5039 23.749 29.5039Z" fill="#8C8C8C" />
                            <path d="M23.4149 28.9114V21.0004H23.9999V28.9114H23.4149ZM23.7839 28.2364C23.3339 28.2364 22.9259 28.1614 22.5599 28.0114C22.1999 27.8554 21.9149 27.6274 21.7049 27.3274C21.4949 27.0274 21.3809 26.6584 21.3629 26.2204H22.8029C22.8089 26.3944 22.8539 26.5474 22.9379 26.6794C23.0219 26.8114 23.1359 26.9164 23.2799 26.9944C23.4239 27.0664 23.5919 27.1024 23.7839 27.1024C23.9459 27.1024 24.0869 27.0784 24.2069 27.0304C24.3329 26.9764 24.4319 26.9014 24.5039 26.8054C24.5759 26.7034 24.6119 26.5774 24.6119 26.4274C24.6119 26.2714 24.5699 26.1394 24.4859 26.0314C24.4019 25.9174 24.2879 25.8214 24.1439 25.7434C23.9999 25.6594 23.8319 25.5844 23.6399 25.5184C23.4539 25.4464 23.2529 25.3774 23.0369 25.3114C22.5389 25.1494 22.1549 24.9304 21.8849 24.6544C21.6209 24.3784 21.4889 24.0094 21.4889 23.5474C21.4889 23.1634 21.5819 22.8364 21.7679 22.5664C21.9599 22.2904 22.2209 22.0804 22.5509 21.9364C22.8809 21.7924 23.2559 21.7204 23.6759 21.7204C24.1079 21.7204 24.4859 21.7954 24.8099 21.9454C25.1399 22.0894 25.4009 22.3024 25.5929 22.5844C25.7909 22.8604 25.8959 23.1904 25.9079 23.5744H24.4589C24.4529 23.4424 24.4139 23.3224 24.3419 23.2144C24.2759 23.1064 24.1829 23.0194 24.0629 22.9534C23.9489 22.8874 23.8139 22.8544 23.6579 22.8544C23.5199 22.8484 23.3939 22.8694 23.2799 22.9174C23.1659 22.9594 23.0759 23.0284 23.0099 23.1244C22.9439 23.2144 22.9109 23.3284 22.9109 23.4664C22.9109 23.5984 22.9439 23.7154 23.0099 23.8174C23.0819 23.9134 23.1779 23.9974 23.2979 24.0694C23.4239 24.1354 23.5679 24.1984 23.7299 24.2584C23.8979 24.3184 24.0809 24.3784 24.2789 24.4384C24.5969 24.5464 24.8879 24.6754 25.1519 24.8254C25.4219 24.9694 25.6379 25.1614 25.7999 25.4014C25.9679 25.6354 26.0519 25.9474 26.0519 26.3374C26.0519 26.6794 25.9619 26.9944 25.7819 27.2824C25.6079 27.5704 25.3529 27.8014 25.0169 27.9754C24.6869 28.1494 24.2759 28.2364 23.7839 28.2364Z" fill="#8C8C8C" />
                            <path d="M8.99866 27.0039V5.12889H24.2488C24.801 5.12889 25.2488 5.57661 25.2488 6.1289V16.1289C26.1473 16.3588 26.7102 16.5327 27.4988 17.0039V5.87897C27.4988 4.22212 26.1556 2.87897 24.4988 2.87896L5.99817 2.87891C3.78906 2.8789 1.99821 4.6697 1.99815 6.87881L1.99805 11.0039C1.99803 11.5562 2.44575 12.0039 2.99805 12.0039H4.49848H6.74866V9.5039H4.49848V6.96627C4.49848 5.80823 5.69041 5.0336 6.74866 5.5039L-nan -nanL6.74866 5.5039V6.5039V9.5039V12.0039V30.505C6.74866 30.6553 6.9084 30.7519 7.04157 30.6821L8.56201 29.8845C8.62019 29.8539 8.68965 29.8539 8.74783 29.8845L10.4583 30.7818C10.5219 30.8151 10.5985 30.8118 10.659 30.7731L12.0159 29.9047C12.0816 29.8627 12.1658 29.8627 12.2315 29.9047L13.5784 30.7667C13.6441 30.8088 13.7283 30.8088 13.794 30.7667L15.1468 29.901C15.2095 29.8609 15.2892 29.8589 15.3538 29.8958L16.999 30.8357C16.1635 29.8953 15.8181 28.3468 15.499 27.1301L15.3437 27.0518C15.2843 27.0219 15.214 27.0234 15.156 27.0559L13.7839 27.8242C13.7232 27.8582 13.6492 27.8582 13.5885 27.8242L12.2214 27.0586C12.1607 27.0246 12.0867 27.0246 12.026 27.0586L10.6589 27.8242C10.5982 27.8582 10.5242 27.8582 10.4635 27.8242L8.99866 27.0039Z" fill="#8C8C8C" />
                        </svg>
                    </div>
                    <p>Select an invoice to see all its details here. </p>
                </MainDivNoInvoice>
            )
        }
        if (this.state.selectedTab === "pending") {
            switch (String(this.state.currentStep)) {
                case "0":
                    return (
                        <MainDiv>
                            {this.renderStep1()}
                        </MainDiv>
                    )
                case "1":
                    return (
                        <MainDiv>
                            {this.renderStep2()}
                        </MainDiv>
                    )
            }
        } else {
            return (
                <MainDiv style={{backgroundColor:"#F0F0F0"}}>
                    <InvoiceBilling navigation={{}} id="" paymentTaskInfo={this.state.taskInfo} />
                </MainDiv>
            )
        }

    }

    historyRender = () => {
        return (
            <div className="flex flex-column" >
                <div className="task-grid">
                    <div style={{ display: "flex", gap: 12, alignItems: "center", }} id="task-id" >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6876 8.28125V7.34375C14.6876 7.25781 14.6173 7.1875 14.5313 7.1875H7.03133C6.9454 7.1875 6.87508 7.25781 6.87508 7.34375V8.28125C6.87508 8.36719 6.9454 8.4375 7.03133 8.4375H14.5313C14.6173 8.4375 14.6876 8.36719 14.6876 8.28125ZM7.03133 10C6.9454 10 6.87508 10.0703 6.87508 10.1562V11.0938C6.87508 11.1797 6.9454 11.25 7.03133 11.25H10.6251C10.711 11.25 10.7813 11.1797 10.7813 11.0938V10.1562C10.7813 10.0703 10.711 10 10.6251 10H7.03133Z" fill="#BFBFBF" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M17.1879 18.3628V3.35941C17.1879 2.32388 16.3485 1.48441 15.313 1.48441L3.75008 1.48438C2.36938 1.48437 1.2501 2.60362 1.25007 3.98431L1.25 6.56248C1.24999 6.90766 1.52982 7.18749 1.875 7.18749H2.81277H4.21913V5.62499H2.81277V4.03898C2.81277 3.3152 3.55773 2.83106 4.21913 3.12499V3.74999V5.62499V7.18749V18.7507C4.21913 18.8446 4.31897 18.905 4.4022 18.8613L5.35248 18.3628C5.38884 18.3438 5.43225 18.3438 5.46861 18.3628L6.53766 18.9237C6.57742 18.9445 6.6253 18.9424 6.66311 18.9182L7.51116 18.3755C7.55223 18.3492 7.60484 18.3492 7.64592 18.3755L8.48773 18.9143C8.52881 18.9405 8.58142 18.9405 8.62249 18.9143L9.55872 18.3732C9.59789 18.3481 9.64773 18.3468 9.68811 18.3699L10.5648 18.9193C10.6025 18.9429 10.6498 18.9447 10.6891 18.9241L11.6859 18.4026C11.7247 18.3823 11.7714 18.3838 11.8088 18.4066L12.6527 18.9193C12.6914 18.9428 12.7399 18.9435 12.7793 18.9211L13.6857 18.4067C13.7257 18.384 13.7749 18.3851 13.8138 18.4095L14.6262 18.9186C14.6645 18.9426 14.7126 18.944 14.7523 18.9225L15.7288 18.3916C15.7622 18.3734 15.8021 18.3715 15.8372 18.3863L17.0143 18.884C17.0967 18.9188 17.1879 18.8583 17.1879 18.7688V18.3628ZM5.62538 2.89062V16.5625L6.54088 17.0752C6.57883 17.0964 6.62509 17.0964 6.66303 17.0752L7.51746 16.5967C7.55541 16.5755 7.60167 16.5755 7.63961 16.5967L8.49404 17.0752C8.53198 17.0964 8.57824 17.0964 8.61619 17.0752L9.56443 16.595C9.60069 16.5747 9.64468 16.5737 9.68179 16.5924L9.77884 16.6414L10.5733 17.0484C10.6064 17.0653 10.6454 17.0667 10.6796 17.0519L11.6952 16.6153C11.729 16.6008 11.7675 16.6019 11.8004 16.6183L12.6617 17.0483C12.6957 17.0653 12.7355 17.0659 12.77 17.05L13.689 16.6252C13.7274 16.6074 13.7722 16.6103 13.8081 16.6329L14.6261 17.1484C14.6644 17.1726 14.7127 17.1741 14.7525 17.1525L15.7164 16.6279C15.7567 16.606 15.7817 16.5639 15.7817 16.5181V3.51562C15.7817 3.17044 15.5019 2.89062 15.1567 2.89062H5.62538Z" fill="#BFBFBF" />
                            <path d="M14.6876 5.625V4.6875C14.6876 4.60156 14.6173 4.53125 14.5313 4.53125H7.03133C6.9454 4.53125 6.87508 4.60156 6.87508 4.6875V5.625C6.87508 5.71094 6.9454 5.78125 7.03133 5.78125H14.5313C14.6173 5.78125 14.6876 5.71094 14.6876 5.625Z" fill="#BFBFBF" />
                        </svg>
                        <Typography variant="body2" className="history_header" component="span" style={{ fontWeight: 500 }} >ID</Typography>
                    </div>
                    <Typography variant="body2" className="history_header" style={{ fontWeight: 500 }} >Date</Typography>
                    <Typography variant="body2" className="history_header" id="task-name" style={{ fontWeight: 500 }} >Task Name</Typography>
                    <Typography variant="body2" className="history_header" id="task-amount" style={{ fontWeight: 500 }} >Amount</Typography>
                    <Typography variant="body2" className="history_header" id="task-status" style={{ fontWeight: 500 }} >Status</Typography>
                </div>
                <TaskContainer style={{ marginTop: "20px" }}>
                    {this.state.IsTaskLoading &&
                        <div style={{ textAlign: "center", padding: "30px" }}>
                            <CircularProgress style={{ color: "#355EE7", width: 40, height: 40 }} />
                        </div>
                    }
                    {!this.state.IsTaskLoading && <div className="inner-task-list-container">
                        {this.state.historyTasks?.map((tasks) => (
                            <div className={"row"} style={this.state.taskId === String(tasks.task_id) ? { background: "#ECF1FF" } : {}} data-test-id={`row-child-history-${tasks.task_id}`} onClick={() => this.handleClickTaskRow(String(tasks.task_id), String(tasks.payment_id))}>
                                <div className={"id"}>{tasks.task_id}</div>
                                <div className={"date"}>{moment(tasks.date).format("DD/MM/YYYY")}</div>
                                <div className={"taskName"}>
                                    <span>{tasks.task_name}</span>
                                </div>
                                <div className={"amount"}>
                                    <span>&euro; </span> <span>{tasks.amount.toFixed(2)}</span>
                                </div>
                                <div className={"status"}>
                                    <StyleBadge style={{ backgroundColor: this.renderStatus(tasks.status).statusBgColor, color: this.renderStatus(tasks.status).statusColor }} data-state="paid">{this.renderStatus(tasks.status).statusVal}</StyleBadge>
                                    <IconButton style={{ padding: 0 }}>
                                        <ArrowForwardIosRounded
                                            htmlColor="#355EE7"
                                            style={{ fontSize: "1.25rem" }}
                                        />
                                    </IconButton>
                                </div>
                            </div>
                        ))
                        }
                    </div>}
                </TaskContainer>
            </div>
        )
    }

    getButtonRender = () => {
        switch (this.state.currentStep) {
            case 0:
                return (
                    <Button onClick={() => this.handleContinue()} type="submit" data-test-id="continueBtn" variant="outlined" className="titlbtn1"  >
                        Continue
                    </Button>
                )
            case 1:
                return (
                    <Button data-test-id="payBtn" disabled={this.state.createPaymentLoading} style={this.state.createPaymentLoading ? { opacity: "0.8" } : {}} onClick={() => this.handleRedirectStripPay()} type="submit" variant="outlined" className="titlbtn1"  >
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                            {this.state.createPaymentLoading && <CircularProgress style={{ color: "#FFF", width: 20, height: 20, marginLeft: "-17px" }} />}
                            <span>Pay</span>
                        </div>
                    </Button>
                )
        }

    }

    renderPaymentStatusModal = () => {
        const { paymentStatus } = this.state
        return (
            paymentStatus && <div>
                <BootstrapDialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={true}
                >
                    <DialogContent className="main_div_modal" >
                        <IconButton
                            data-test-id="close_modal"
                            aria-label="close"
                            onClick={this.handleClose}
                            style={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <div className="content_modal">
                            <img src={paymentStatus === "completed" ? successIcon : rejectIcon} />
                            <Typography className="main_title">{paymentStatus === "completed" ? "Payment completed successfully!" : "Payment rejected!"}</Typography>
                            {paymentStatus === "completed" ?
                                <Typography className="desc_style">Remember Digip will only release your payment to the provider, when you mark the task as finished, if you have any doubts please contact <span style={{ color: "#7192FF" }}>userhelp@digip.com</span></Typography>
                                : <Typography className="desc_style">There has been an error on the payment process, please try again later or double check your payment method.</Typography>}
                        </div>
                    </DialogContent>

                </BootstrapDialog>
            </div>
        )

    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <MainContainer data-test-id="ClientDashboarddMain">
                <ToastContainer />
                <Grid container spacing={0}>
                    <Grid item lg={2} md={3} sm={1} xs={1} >
                        <NavigationMenu navigation={this.props.navigation} id={""} />
                    </Grid>
                    <Grid
                        item lg={10} md={9} sm={11} xs={11}
                        className="repsonsive"
                    >
                        {this.renderHeader()}
                        <div className="main-content-container">
                            <div className="main-content-header" >
                                <Stylediv data-test-id="pending_click" onClick={() => this.handleChangeTab("pending")}>
                                    <img src={this.state.selectedTab === "pending" ? pendingFill : pending} />
                                    <span className={this.state.selectedTab === "pending" ? "tab_title" : "unselect_tab_title"}>Pending</span>
                                    <div className={this.state.selectedTab === "pending" ? "active" : ""} />
                                </Stylediv>
                                <Stylediv data-test-id="history_click" onClick={() => this.handleChangeTab("history")}>
                                    <img src={this.state.selectedTab === "history" ? historyFill : history} />
                                    <span className={this.state.selectedTab === "history" ? "tab_title" : "unselect_tab_title"}>History</span>
                                    <div className={this.state.selectedTab === "history" ? "active" : ""} />
                                </Stylediv>
                            </div>

                            <PayoutContainer>
                                <div className="payouts">
                                    <div className="flex flex-column" style={{ gap: 8 }} >
                                        <Typography className="main_title" component="p" >Pending payments</Typography>
                                        <Typography className="tile_desc" component="p" >
                                            {this.state.selectedTab === "pending" ? "Manage your pending payments." : "Manage your credit cards and payment options."}
                                        </Typography>
                                    </div>
                                    {this.state.selectedTab === "pending" ? <div>
                                        <TaskContainer>
                                            <div className="inner-task-list-container">
                                                {this.renderTaskList()}
                                            </div>
                                        </TaskContainer>
                                    </div> : this.historyRender()}

                                </div>
                                <div className="payout-overview">
                                    {this.renderTaskInfo()}
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                        {this.state.taskId && this.state.selectedTab === "pending" && this.getButtonRender()}

                                    </div>
                                </div>
                            </PayoutContainer>
                        </div>
                    </Grid>
                </Grid>

                {this.renderPaymentStatusModal()}

            </MainContainer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const MainContainer = styled('div')({
    backgroundColor: '#fff',
    fontFamily: 'DM Sans',
    "& .repsonsive": {
        padding: '30px',
        height: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',
        "@media (max-width: 1280px)": {
            padding: '50px',
            height: '100vh',
            overflowY: 'scroll',
            overflowX: 'hidden'
        },
        "@media (max-width: 1366px)": {
            padding: '50px',
            height: '100vh',
            overflowY: 'scroll',
            overflowX: 'hidden'
        }
    },
    '& .header': {
        display: "flex",
        gap: 9,
        alignItems: "center",
        '& > h1': {
            margin: 0,
            fontFamily: "DM Sans",
            fontWeight: 700,
            fontSize: 24,
            color: "#0F172A",
        },
    },
    '& .main-content-container': {
        paddingTop: 24,
        backgroundColor: "#FFFFFF",
        borderRadius: 12,
        flex: 1,
    },
    '& .main-content-header': {
        padding: "0px 24px",
        borderBottom: "1px solid #F0F0F0",
        display: "flex",
        gap: "15px",
        '& > div': {
            display: "flex",
            gap: 6,
            padding: "6px 4px",
            width: "max-content",
            boxSizing: "border-box",
            alignItems: "center",
            position: "relative",
            '& .active': {
                position: "absolute",
                width: "100%",
                height: "1px",
                bottom: -1,
                left: 0,
                backgroundColor: "#355EE7",
                zIndex: 2,
            },
        },
    },
    '& .task-grid': {
        display: 'grid',
        gap: 12,
        gridTemplateColumns: '41fr 41fr 127fr 50fr 74fr',
    },
    "& .history_header": {
        fontWeight: 500,
        fontFamily: "DM Sans",
        fontSize: 12,
        color: "#8C8C8C",
    }
});

const PayoutContainer = styled("div")(({ theme }) => ({
    display: 'flex',
    boxSizing: 'border-box',
    '& .payouts': {
        boxSizing: 'border-box',
        padding: 24,
        borderRight: '1px solid #F0F0F0',
        display: 'flex',
        flexDirection: 'column',
        gap: 32,
        width: "60%"
    },
    '& .main_title': {
        fontSize: "18px",
        fontWeight: 700,
        color: "#0F172A"
    },
    "& .tile_desc": {
        fontSize: "14px",
        fontWeight: 400,
        color: "#8C8C8C"
    },
    '& .payout-overview': {
        boxSizing: 'border-box',
        padding: 24,
        width: "40%",
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        justifyContent: "space-between"
    },
    "& .titlbtn1": {
        height: '44px', width: '150px',
        border: '1px solid #355EE7', borderRadius: '8px',
        padding: '10px 12px', fontSize: '16px',
        fontWeight: 500, color: '#fff',
        backgroundColor: "#355EE7", fontFamily: 'DM Sans',
        textTransform: "none",
        '&:hover': {
            border: '2px solid #B9C5FF', boxShadow: 'none !important'
        },
        "@media (max-width: 450px)": {
            height: '40px', width: '140px', padding: '7px 9px',
        },
    },
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& .payouts': {
            width: '100%',
            borderRight: 'none',
        },
        '& .payout-overview': {
            width: '100%',
        },
        '& .task-scroll': {
            width: '100%',
            overflowX: 'visible',
        },
    },
}))



const TaskContainer = styled("div")({
    border: "1px solid #F0F0F0",
    borderRadius: 8,
    padding: 12,
    '& .inner-task-list-container': {
        paddingRight: "14px",
        maxHeight: 576,
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        "&::-webkit-scrollbar": {
            width: "6px"
        },
        "&::-webkit-scrollbar-track": {
            background: "#F5F5F5",
            borderRadius: 10,
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#91AAFF",
            borderRadius: 10,
        },
    },
    "& .main_row": {
        padding: "12px 20px",
        cursor: "pointer",
        border: "1px solid #7192FF",
        borderRadius: "6px",
        marginTop: "10px"
    },
    '& .task_title': {
        fontSize: "18px",
        fontWeight: 400,
        color: "#0F172A"
    },
    "& .task_date": {
        fontSize: "12px",
        fontWeight: 400,
        color: "#8C8C8C",
        display: "flex",
        alignItems: "center",
        gap: "5px"
    },
    "& .last_desc": {
        fontSize: "12px",
        fontWeight: 500,
        color: "#7192FF",
        marginTop: "10px",
        display: "flex",
        alignItems: "center",
        gap: "5px"
    },
    "& .task_amount": {
        backgroundColor: "#355EE7",
        borderRadius: "12px",
        fontSize: "12px",
        color: "#FFFFFF",
        padding: "5px",
        display: "flex",
        alignItems: "center",
        gap: "5px",
        paddingLeft: "15px",
        paddingRight: "15px",
        height: "15px",
        marginRight: "25px"
    },
    "& .row": {
        display: "grid",
        gridTemplateColumns: "47fr 153fr 65fr 67fr 122fr",
        gap: 12,
        alignItems: "center",
        padding: "12px 6px",
        borderBottom: "1px solid #ECF1FF",
        cursor: "pointer",
        "&:last-child": {
            borderBottom: 0,
        },
    },
    "& .id": {
        display: "inline-block",
        fontWeight: 500,
        fontFamily: "DM Sans",
        fontSize: 12,
        lineHeight: "14.4px",
        color: "#8C8C8C",
        flexGrow: 63.5,
    },
    "& .taskName": {
        flexGrow: 190,
        width: "240px",
        textAlign: "start",
        "& > span": {
            display: "inline-block",
            fontWeight: 500,
            fontFamily: "DM Sans",
            fontSize: 12,
            lineHeight: "14.4px",
            color: "#8C8C8C",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "200px",

        },
    },
    "& .amount": {
        display: "flex",
        fontWeight: 500,
        fontFamily: "DM Sans",
        fontSize: 14,
        lineHeight: "18.23px",
        color: "#262626",
        flexGrow: 102,
        gap:"2px",
        alignItems: "center",
    },
    "& .date": {
        display: "inline-block",
        fontWeight: 500,
        fontFamily: "DM Sans",
        fontSize: 12,
        lineHeight: "14.4px",
        color: "#355EE7",
        flexGrow: 65,
    },
    "& .status": {
        display: "flex",
        gap: 6,
        flexGrow: 122,
        alignItems: "center",
    },
})

const MainDiv = styled('div')({
    backgroundColor: "#FAFAFA",
    padding: 24,
    borderRadius: 8,
    gap: 24,
    boxSizing: "border-box",
    border: "1px solid #F0F0F0",
    '& p': {
        margin: 0
    },
    "& .title_info": {
        fontSize: "16px",
        fontWeight: 700,
        color: "#262626",
    },
    "& .info_key": {
        fontSize: "12px",
        fontWeight: 400,
        color: "#8C8C8C",
    },
    "& .info_value": {
        fontSize: "12px",
        fontWeight: 400,
        color: "#0F172A",
    },
    "& .border_total": {
        borderTop: "2px dashed #BFBFBF",
        marginTop: "20px"
    },
    "& .total_key": {
        fontSize: "16px",
        fontWeight: 500,
        color: "#0F172A",
    },
    "& .total_value": {
        fontSize: "16px",
        fontWeight: 500,
        color: "#434343",
    },
    "& .task_key": {
        fontSize: "14px",
        fontWeight: 500,
        color: "#0F172A",
    },
    "& .billing_key": {
        fontSize: "12px",
        fontWeight: 400,
        color: "#8C8C8C",
    },
    "& .billing_value": {
        fontSize: "12px",
        fontWeight: 400,
        color: "#0F172A",
    },
    "& .billing_key_amt": {
        fontSize: "16px",
        fontWeight: 500,
        color: "#0F172A",
    },
    "& .billing_value_amt": {
        fontSize: "16px",
        fontWeight: 500,
        color: "#0F172A",
    },
    "& .task_value": {
        fontSize: "12px",
        fontWeight: 500,
        color: "#8C8C8C",
    },
    "& .transaction_info": {
        fontSize: "14px",
        fontWeight: 700,
        color: "#262626",
        marginTop: "20px"
    },
    
    "& . icon": {
        width: 80,
        height: 80,
        display: "grid",
        placeItems: "center",
        backgroundColor: "#F0F0F0",
        borderRadius: 500,
    },
    "& .noInvoiceContainer": {
        justifyContent: "center",
        alignItems: "center",
        gap: 4,
        '& > p': {
            margin: 0,
            width: 210,
            textAlign: "center",
            fontFamily: "DM Sans",
            fontWeight: 700,
            fontSize: 14,
            lineHeight: "18.23px",
            color: "#8C8C8C"
        },
    },
})

const MainDivNoInvoice = styled('div')({
    backgroundColor: "#F0F0F0",
    padding: 24,
    borderRadius: 8,
    boxSizing: "border-box",
    border: "1px solid #F0F0F0",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    gap: 4,
    '& > p': {
        margin: 0,
        width: 210,
        textAlign: "center",
        fontFamily: "DM Sans",
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "18.23px",
        color: "#8C8C8C"
    },
    "& .icon": {
        width: 80,
        height: 80,
        display: "grid",
        placeItems: "center",
        backgroundColor: "#F0F0F0",
        borderRadius: 500,
    },

})

const StyledBadge = styled(Badge)({
    minWidth: 100,
    display: "inline-block",
    textAlign: "center",
    padding: "8px 0px",
    backgroundColor: "#DCECFF",
    borderRadius: 40,
    fontFamily: "DM Sans",
    fontWeight: 700,
    fontSize: 12,
    lineHeight: "18px",
    color: "#355EE7",
    '&[data-state="paid"]': {
        backgroundColor: "#D1FAE5",
        color: "#059669",
    },
    '& + span': {
        color: "#434343",
        fontFamily: "DM Sans",
        fontWeight: 500,
        fontSize: 12,
        lineHeight: "15.62px",
    },
})

const StyleButton = styled(Button)({
    width: "161px",
    height: "26px",
    fontWeight: 700,
    fontSize: 10,
    borderColor: "#355EE7",
    color: "#355EE7",
    borderRadius: "8px"
})

const Stylediv = styled('div')({
    "& .tab_title": {
        fontFamily: "DM Sans",
        fontWeight: 700,
        fontSize: 12,
        lineheight: "22px",
        color: "#355EE7",
        cursor: "pointer"
    },
    "& .unselect_tab_title": {
        fontFamily: "DM Sans",
        fontWeight: 700,
        fontSize: 12,
        lineheight: "22px",
        color: "#BFBFBF",
        cursor: "pointer"
    }
})


const StyleBadge = styled(Badge)({
    width: 96,
    display: "inline-block",
    borderRadius: 12,
    padding: "4px 0px",
    textAlign: "center",
    fontFamily: "DM Sans",
    fontWeight: 700,
    fontSize: 12,
    lineHeight: "15.62px",
    '&[data-state="digip"]': {
        backgroundColor: "#FEF3C7",
        color: "#D97706",
    },
    '&[data-state="pending"]': {
        backgroundColor: "#DCECFF",
        color: "#355EE7",
    },
    '&[data-state="paid"]': {
        backgroundColor: "#D1FAE5",
        color: "#059669",
    },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        width: '600px',
        height: '300px',
        maxWidth: 'none',
    },
    "& .main_title": {
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: 22,
        lineHeight: "15.62px",
        color: "#000000",
        marginTop: "15px",
        textAlign: "center"
    },
    "& .desc_style": {
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "15.62px",
        color: "#8C8C8C",
        marginTop: "15px",
        textAlign: "center"
    },
    "& .content_modal": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .main_div_modal": {
        border: "1px solid #D1FAE5",
        borderRadius: "8px",
        margin: "10px",
        display: "flex",
        flexDirection: "column",
        padding: "42px"
    }

}));
// Customizable Area End