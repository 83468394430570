// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { DateObject } from "react-multi-date-picker";
import moment from "moment";

interface ApiData {
  contentType?: string,
  method?: string,
  endPoint?: string,
  body?: {},
  type?: string,
  token?: string
}

export interface ITask  {
  id: number;
  task_name: string;
  trademark_name: string;
  amount: number;
  trademark_application_number: string;
  lawfirm: string;
  payment_state: "pending_payment" | "paid" | "failed"
  status: "rejected" | "approved" | "pending"
  client_id: number;
  transaction_id: string;
  paid_to_lawfirm: boolean;
  session_id: string;
  task_id: number;
  created_at: string;
  updated_at: string;
};

export interface ILegalServiceType {
    "id": number,
    "legal_service_name": string,
    "legel_service_description":string | null,
    "created_at": string,
    "updated_at": string
}

export interface ITaskDetails {
  payment: {
      status: string;
      transaction_id: string;
      task_price: number;
      digip_fee_percent: string;
      digip_fee: string;
      amount_received: string;
  };
  task: {
      id: number;
      task_name: string;
      description: string;
      status: string;
      trademark_name: string;
      trademark_application_number: string;
      deadline: string;
      legel_service_type_id: number;
      jurisdiction: string;
      state: string;
      created_at: string;
      account_id: number;
      assigned_lawyer_ids: number[];
      client_id: number;
      parent_task_id: number;
      transaction_id: string;
      assigned_by_digip_admin: boolean;
  };
}


interface CollectTransactionFeesData {
  data: {
    data: {
      id?: string,
      type?: string,
      attributes: {
        id?: number,
        total_fees?: number,
        transaction_fees?: number
      }
    }
  }
}

export interface IPayoutPreview {
  "pending_tasks_count": number,
  "pending_amount": number,
  "paid_tasks_count": number,
  "paid_amount": number,
  "paid_to_digip_tasks_count": number,
  "paid_to_digip_amount": number,
  "total_amount": number
}

export interface IFeePercentData
  {
      "id": number,
      "firm_name": string,
      "fee_percent": string,
      "created_at": string,
      "updated_at": string
  }

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  transactionAmount: string;
  transactionAmountMessage: string;
  transactionPercentage: number;
  actualAmount: number;
  totalAmount: number;
  isLoading: boolean;
  responseData: object;
  percentageData: number;
  percentage: number;
  Sendpercentage: number;
  invoiceTasks: ITask[]
  payoutOverview: IPayoutPreview
  invoiceTaskDetails: ITaskDetails | undefined 
  IsTaskLoading: boolean
  IsPayourOverviewLoading: boolean
  IsTaskDetailsLoading: boolean
  legalServiceTypeList: {
    label: string;
    value: string;
  }[]
  serviceTypeQuery:string
  serviceTypeNameQuery:string
  dateQuery: string
  dateObjectState: DateObject | null | string
  taskNameQuery:string
  selectedService: {
    label: string;

    value: string;
  } | null
  feePercentValue: IFeePercentData | null
}

interface SS {
  id: any;
}

export default class CollectTransactionFeesController extends BlockComponent<
  Props,
  S,
  SS
> {

  collectTransactionFeesAPICallId!: string
  transactionPercentageAPICallId!: string
  invoiceTaskListAPICallId!: string
  payoutPreviewAPICallId!: string
  invoiceTaskDetailsAPICallId!: string
  legalServiceTypeAPICallId!: string
  getFeePercentOfLawfirmAPICall!: string

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      transactionAmount: "",
      transactionAmountMessage: "",
      transactionPercentage: 0,
      actualAmount: 0,
      totalAmount: 0,
      isLoading: false,
      responseData: {},
      percentageData: 0,
      percentage: 0,
      Sendpercentage: 0,
      invoiceTasks: [],
      IsTaskLoading: false,
      payoutOverview: {
        "pending_tasks_count": 0,
        "pending_amount": 0,
        "paid_tasks_count": 0,
        "paid_amount": 0,
        "paid_to_digip_tasks_count": 0,
        "paid_to_digip_amount": 0,
        "total_amount": 0
      },
      IsPayourOverviewLoading: false,
      invoiceTaskDetails: undefined,
      IsTaskDetailsLoading: false,
      legalServiceTypeList: [],
      serviceTypeQuery:"",
      dateQuery: "",
      taskNameQuery:"",
      serviceTypeNameQuery: "",
      selectedService: null,
      dateObjectState: null,
      feePercentValue: null
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
     
      this.getInvoiceTaskListResponse(apiRequestCallId,responseJson)
      this.getPreviewPayoutResponse(apiRequestCallId,responseJson)
      this.getInvoiceTaskDetailsResponse(apiRequestCallId,responseJson)
      this.getLegalServiceTypeResponse(apiRequestCallId,responseJson)
      this.getFeePercentOfLawFirmRes(apiRequestCallId,responseJson)
    }

  }

  async componentDidMount() {
    this.getInvoiceTaskList()
    this.getPayoutPreviewData()
    this.getLegalServiceType()
    this.getFeePercentServiceListForCT()
  }

  apiCall = async (data: ApiData) => {
    const { contentType, method, endPoint, body, type,token } = data
    const header = {
      'Content-Type': contentType,
      'token': token ? token : ""
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  determineMainTokenValue = (
    metaSignUpToken1: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken1: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken1?.data?.id) {
      if (metaLoginToken1?.id && metaSignUpToken1.data.id !== metaLoginToken1.id) {
        return metaLoginToken1;
      }
      return metaSignUpToken1;
    }

    if (metaLoginToken1?.id) {
      return metaLoginToken1;
    }

    return null;
  };

  getInvoiceTaskList = async(type?:string) => {
      this.setState({IsTaskLoading:true})
      const {serviceTypeQuery,dateQuery,taskNameQuery} = this.state
      const signupTokens: string = await getStorageData("userdetails");
      const loginTokens: string = await getStorageData("token");
      const metaSignUpToken = JSON.parse(signupTokens);
      const metaLoginToken = JSON.parse(loginTokens);
  
      const maintoken = this.determineMainTokenValue(metaSignUpToken, metaLoginToken);

      const typeVal = type ? `?type=${type}` : `?type=all`
      const serviceTypeVal = serviceTypeQuery ? `&legel_service=${serviceTypeQuery}` : ""
      const dateVal = dateQuery ? `&date=${dateQuery}` : ""
      const tasknameVal = taskNameQuery ? `&task_name=${taskNameQuery}` : ""

      this.invoiceTaskListAPICallId = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        token: maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.invoiceTaskEndpoint + typeVal + serviceTypeVal + dateVal + tasknameVal,
      });
  }

  getInvoiceTaskListResponse = (apiRequestCallId:string,responseJson:{data:ITask[]}) => {
    if(apiRequestCallId === this.invoiceTaskListAPICallId){
      if(responseJson){
        this.setState({invoiceTasks:responseJson.data,IsTaskLoading:false})
      }
    }
  }

  handleTabChange = (value:string) => {
    this.setState({invoiceTaskDetails:undefined})
    this.getInvoiceTaskList(value)
  }

  getPayoutPreviewData = async() => {
    this.setState({IsPayourOverviewLoading:true})
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainTokenValue(metaSignUpToken, metaLoginToken);

    this.payoutPreviewAPICallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      token: maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.payoutOverviewEndpoint,
    });
  }

  getPreviewPayoutResponse = (apiRequestCallId:string,responseJson:IPayoutPreview) => {
    if(apiRequestCallId === this.payoutPreviewAPICallId){
      if(responseJson){
        this.setState({payoutOverview:responseJson,IsPayourOverviewLoading:false})
      }
    }
  }

  getInvoiceTaskDetails = async(id:string | number) => {
    this.setState({IsTaskDetailsLoading:true})
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainTokenValue(metaSignUpToken, metaLoginToken);

    this.invoiceTaskDetailsAPICallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      token: maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.invoiceTaskDetailsEndpoint + id,
    });
  }

  getInvoiceTaskDetailsResponse = (apiRequestCallId:string,responseJson:ITaskDetails) => {
    if(apiRequestCallId === this.invoiceTaskDetailsAPICallId){
      if(responseJson){
        this.setState({invoiceTaskDetails:responseJson,IsTaskDetailsLoading:false})
      }
    }
  }

  getLegalServiceType = async() => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainTokenValue(metaSignUpToken, metaLoginToken);

    this.legalServiceTypeAPICallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      token: maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.legalServiceTypeListEndpoint,
    });
  }

  getLegalServiceTypeResponse  = (apiRequestCallId:string,responseJson:ILegalServiceType[]) => {
    if(apiRequestCallId === this.legalServiceTypeAPICallId){
      if(responseJson){
        const mappedServices = responseJson.map(service => ({
          label: service.legal_service_name,
          value: String(service.id)
      }));
        this.setState({legalServiceTypeList:mappedServices})
      }
    }
  }

  handleInputChange = (value:string) => {
    this.setState({taskNameQuery:value},()=> this.getInvoiceTaskList())
  }

  onChangeServiceType = (option:{
    label: string;
    value: string;
  })=>{
    this.setState({serviceTypeQuery:option.value,serviceTypeNameQuery:option.label,selectedService:option},()=>{
      this.getInvoiceTaskList()
    })
  }

  onDateChange = (dateNew:DateObject|null|string) => {
    const dateVal =  moment(new Date(dateNew as string)).format("YYYY-MM-DD")
    this.setState({dateQuery:dateVal,dateObjectState:dateNew},()=>{
      this.getInvoiceTaskList()
    })
  }

  handleClearService = () => {
    this.setState({serviceTypeQuery: "",serviceTypeNameQuery:"",selectedService:null},()=>{
      this.getInvoiceTaskList()
    })
  }

  handleClearDate = () => {
    this.setState({dateQuery:"",dateObjectState:null},()=>{
      this.getInvoiceTaskList()
    })  
  }
  
  getFeePercentServiceListForCT = async () => {
    const header1 = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFeePercentEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header1)
    );

    this.getFeePercentOfLawfirmAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFeePercentOfLawFirmRes = async (apiRequestCallId1:string,responseJson1:IFeePercentData[]) => {
    if (apiRequestCallId1 === this.getFeePercentOfLawfirmAPICall){
      if(responseJson1){
        const firmStateNew = await getStorageData("firm_state")
        const feePercVal = responseJson1.find((item)=>item.firm_name === firmStateNew)
        this.setState({ 
          feePercentValue:feePercVal as IFeePercentData
        })
      }
    }
  }
}

// Customizable Area End
