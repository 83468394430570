import React, { useState } from "react";

interface FilterProps {
  filterData: string[];
  taskStates: Array<{name: string, bgColor: string, textColor: string}>;
  taskStatuses: string[];
  onServiceSelect: (service: string) => void;
  onTaskStateSelect: (state: string) => void;
  onTaskStatusSelect: (status: string) => void;
  selectedSerciceType:string;
  selectedTaskState:string;
  selectedTaskStatus:string;
}

const Filter: React.FC<FilterProps> = ({
  filterData,
  taskStates,
  taskStatuses,
  onServiceSelect,
  onTaskStateSelect,
  onTaskStatusSelect,
  selectedSerciceType,
  selectedTaskState,
  selectedTaskStatus
}) => {
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  
  const toggleDropdown = (dropdown: string) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  function formatStatus(status:string) {
    return status
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  
  return (
    <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
      <div style={{ position: "relative", display: "inline-block" }}>
        <button
          style={buttonStyle}
          onClick={() => toggleDropdown("Legal Service Type")}
        >
          {formatStatus(selectedSerciceType) || "Legal Service Type"}
          <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.706145 2.41374L3.29615 5.00374C3.68615 5.39374 4.31615 5.39374 4.70615 5.00374L7.29614 2.41374C7.92615 1.78374 7.47614 0.703735 6.58614 0.703735H1.40615C0.516145 0.703735 0.0761452 1.78374 0.706145 2.41374Z" fill="#262626"/>
</svg>

        </button>
        {openDropdown === "Legal Service Type" && (
          <div style={dropdownStyle}>
            {filterData.map((item, index) => (
              <div
                key={index}
                style={dropdownItemStyle}
                onClick={() => {
                  onServiceSelect(item);
                  setOpenDropdown(null);
                }}
              >
                {formatStatus(item)}
              </div>
            ))}
          </div>
        )}
      </div>
      
      <div style={{ position: "relative", display: "inline-block" }}>
        <button style={buttonStyle} onClick={() => toggleDropdown("Task State")}>
          { formatStatus(selectedTaskState) ||"Task State" }
          <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.706145 2.41374L3.29615 5.00374C3.68615 5.39374 4.31615 5.39374 4.70615 5.00374L7.29614 2.41374C7.92615 1.78374 7.47614 0.703735 6.58614 0.703735H1.40615C0.516145 0.703735 0.0761452 1.78374 0.706145 2.41374Z" fill="#262626"/>
</svg>

        </button>
        {openDropdown === "Task State" && (
          <div style={dropdownStyle}>
            {taskStates.map((item, idx) => (
              <div
                key={idx}
                style={{
                  ...taskItemStyle,
                  backgroundColor: item.bgColor || taskItemStyle.backgroundColor,
                  color: item.textColor || taskItemStyle.color
                }}
                onClick={() => {
                  onTaskStateSelect(item.name);
                  setOpenDropdown(null);
                }}
              >
                {formatStatus(item.name)}
                </div>
            ))}
          </div>
        )}
      </div>
      
      <div style={{ position: "relative", display: "inline-block" }}>
        <button style={buttonStyle} onClick={() => toggleDropdown("Task Status")}>
          { formatStatus(selectedTaskStatus)||"Task Status"}
          <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.706145 2.41374L3.29615 5.00374C3.68615 5.39374 4.31615 5.39374 4.70615 5.00374L7.29614 2.41374C7.92615 1.78374 7.47614 0.703735 6.58614 0.703735H1.40615C0.516145 0.703735 0.0761452 1.78374 0.706145 2.41374Z" fill="#262626"/>
</svg>

        </button>
        {openDropdown === "Task Status" && (
          <div style={dropdownStyle}>
            {taskStatuses.map((item, idx) => (
              <div
                key={idx}
                style={taskItemStyle}
                onClick={() => {
                  onTaskStatusSelect(item);
                  setOpenDropdown(null);
                }}
              >
                {formatStatus(item)}
                </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const buttonStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "220px",
  padding: "10px",
  border: "1px solid #C3D1FF",
  borderRadius: "10px",
  backgroundColor: "white",
  cursor: "pointer",
  fontSize: "16px",
  color: "#8C8C8C",
};

const dropdownStyle: React.CSSProperties = {
  position: "absolute",
  top: "110%",
  left: "0",
  width: "200px",
  background: "white",
  border: "1px solid #F5F5F5",
  borderRadius: "8px",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  zIndex: "10",
  padding: "10px",
  fontFamily: "sans-serif",
};

const dropdownItemStyle: React.CSSProperties = {
  padding: "8px 10px",
  cursor: "pointer",
  color:'#3C3E49',
  fontFamily:'sans-serif'
};

const taskItemStyle: React.CSSProperties = {
  backgroundColor: "#F5F5F5",
  color: "#434343",
  fontWeight: "bold",
  textAlign: "center",
  width: "fit-content",
  padding: "5px 4px",
  borderRadius: "20px",
  marginBottom: "14px",
  fontSize: "14px",
  cursor: "pointer",
};

export default Filter;