import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { FormikProps } from "formik";



// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  mobileOpen: boolean;
  errormsg: string;
  apiImage:File|null;
  roledata: string | undefined;
  editToggel:boolean;
  catalougeData:{
    "data":{
      id:string,
      "attributes":{
        website:string,
          firm:string,
          email:string,
          user_role:string,
          profile_image:string,
      }
    }
  },
  billingDetails: {
      "id": number,
      "account_number": string,
      "sort_code": string,
      "accountn_holder_name": string,
      "account_id": number,
      "created_at": string,
      "updated_at": string
  }
  lawFirmData:boolean,
  changeimage: string,
  open:boolean,
  dataArray: { email: string }[],
  showEmail:boolean,
  openDialog:boolean,
  savechanges:{firm:boolean,website:boolean},
  userMailId:string,
  EmailErrorMsg:boolean,
  popUpShoww:boolean,
  ShowResponsee:{
    error:string,
    success:string,
  },
  LawfirmNav:boolean,
  FieUploadPopUp: boolean,
  LoadedFiles: File[],
  LoadedFilesPreview: any
  uploadProgress: any,
  uploadingFiles: any,
  Files: any,
  uploadMimetype: any,
  uploadResponse:any,
  upLoading:boolean,
  isDeleting:boolean,
  isDeleteID:string,
  isProfileComplete:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  uploadControllers: { [key: string]: AbortController } = {};
  getProductApiCallId: any;
  UpdateLawFirmApiCallId:string = "";
  SendInviteLinkApiCallId:string = "";
  getUserProfileApiCallId:string="";
  PostBulkUploadEndPointCallId:string="";
  DeleteBulkUploadEndPointCallId:string="";
  formikBillingRef:  FormikProps<{ 
    account_number: string; 
    sort_code: string; 
    accoutn_holder_name: string;
  }> | null = null; 
  formikRef:  FormikProps<{ 
    Website: string;
    firmName: string 
  }> | null = null; 
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      arrayHolder: [],
      token: "",
      mobileOpen: false,
      apiImage:null,
      errormsg: "",
      roledata: "",
      editToggel:false,
      catalougeData:{
        "data":{
          id:'',
          "attributes":{
            website:"",
              firm:"",
              email:"",
              user_role:"",
              profile_image:""
          }
        }
      },
      billingDetails: {
        "id": 0,
        "account_number": "",
        "sort_code": "",
        "accountn_holder_name": "",
        "account_id": 0,
        "created_at": "",
        "updated_at": ""
      },
     lawFirmData:false,
     changeimage:'',
     open:false,
      dataArray: [],
     savechanges:{firm:false,website:false},
     showEmail:false,
     openDialog:false,
     userMailId:"",
     EmailErrorMsg:false,
     popUpShoww:false,
     ShowResponsee:{
      error:"",
      success:"", 
      },
      LawfirmNav:false,
      FieUploadPopUp: false,
      LoadedFiles: [],
      LoadedFilesPreview: [],
      uploadProgress: [],
      uploadingFiles: {},
      Files: [],
      uploadMimetype: [],
      uploadResponse:[],
      upLoading:false,
      isDeleting:false,
      isDeleteID:"",
      isProfileComplete: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getUserProfile()

    // Customizable Area End
  }

  // Customizable Area Start
 

  formatFileSize(bytes: number): string {
    if (bytes < 1024) return `${bytes} B`;
    else if (bytes < 1048576) return `${(bytes / 1024).toFixed(2)} KB`;
    else if (bytes < 1073741824) return `${(bytes / 1048576).toFixed(2)} MB`;
    else return `${(bytes / 1073741824).toFixed(2)} GB`;
  }

  handleDelete = (fileName: string) => {
    this.removeFileData(fileName);
  };

  handleFileChange = (event: any) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const maxFileSize = 20971520;
      for (const file of files) {
        if (file.size > maxFileSize) {
          return;
        }
      }
      this.processFiles(files);
    }
  };

  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const maxFileSize = 20971520;
      for (const file of files) {
        if (file.size > maxFileSize) {
          return;
        }
      }
      this.processFiles(files);
    }
  };

  processFiles = (files: any) => {
    const fileArray = [...files];
    const previews = fileArray.map((file) => ({
      file: URL.createObjectURL(file),
      fileName: file.name,
      fileSize: file.size,
    }));

    const uploadProgress = fileArray.map((file) => ({
      fileName: file.name,
      progress: 0,
      isUploading: true,
    }));

    this.setState(
      { LoadedFiles: [...this.state.LoadedFiles, ...fileArray], LoadedFilesPreview: [...this.state.LoadedFilesPreview, ...previews], uploadProgress: [...this.state.uploadProgress, ...uploadProgress] },
      () => {
        this.uploadFiles(fileArray);
      }
    );
  };

  uploadFiles = (files: File[]) => {
    files.forEach((file) => {
      const controller = new AbortController();
      this.uploadControllers[file.name] = controller;
      const updateProgress = (progress: number) => {
        this.setState((prevState) => ({
          uploadProgress: prevState.uploadProgress.map((p: any) =>
            p.fileName === file.name ? { ...p, progress } : p
          ),
        }));
      };
      this.simulateUpload(file, updateProgress, controller);
    });
  };

  simulateUpload = (
    file: File,
    updateProgress: (progress: number) => void,
    controller: AbortController
  ) => {
    let progress = 0;
    const interval = setInterval(() => {
      if (progress < 100) {
        progress += 1;
        updateProgress(progress);
      } else {
        clearInterval(interval);
        this.setState((prevState) => ({
          uploadProgress: prevState.uploadProgress.map((p: any) =>
            p.fileName === file.name ? { ...p, isUploading: false } : p
          ),
        }));
      }
    }, 20);

    controller.signal.addEventListener("abort", () => {
      clearInterval(interval);
      updateProgress(0);
      this.removeFileData(file.name);
    });
  };

  cancelUpload = (fileName: string) => {
    const controller = this.uploadControllers[fileName];
    if (controller) {
      controller.abort();
      delete this.uploadControllers[fileName];
    }
  };

  removeFileData = (fileName: string) => {
    this.setState((prevState) => ({
      ...this.state,
      LoadedFiles: prevState.LoadedFiles.filter((file) => file.name !== fileName),
      LoadedFilesPreview: prevState.LoadedFilesPreview.filter((preview: any) => preview.fileName !== fileName),
      uploadProgress: prevState.uploadProgress.filter((progress: any) => progress.fileName !== fileName),
    }));
  };

  handleUploadphotoes = () => {
    this.setState({ FieUploadPopUp: true, uploadMimetype: "image/*" })
  }

  handleUploadvidoes = () => {
    this.setState({ FieUploadPopUp: true, uploadMimetype: "video/*" })
  }
  handleUplodeClosePopUp = () => {
    this.setState({ FieUploadPopUp: false, LoadedFiles: [], LoadedFilesPreview: [], uploadProgress: [] })
  }
  handleClick = () => {
    this.setState({open:true});
  };

  handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    this.setState({open:false})
  };

  handleClosePopUp = () => {
    this.setState({ openDialog: false });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });
  }
  handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];

    if (files && files[0] && allowedTypes.includes(files[0].type)) {
      this.setState({ changeimage: URL.createObjectURL(files[0]), apiImage: files[0] });
    }
  };

  handleInviteSubmit = (event: { email: string, role: string }) => {
    if (event.role) {
      setStorageData("roleName", JSON.stringify(event.role))
    }
  }
  handleErrorDiv = (a: boolean, b: any, c: string) => {
    return a ? b : c;
  }
  handleDashboard = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "DashBoard");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleAdminLawyer = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AdminLawyerManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleLawFirmManagement = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "lawfirmManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleServicesManagement = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ServicesManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  determineMainToken = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null;
  };
  getUserProfileAsync=(responseJson:any)=>{
    if (responseJson?.errors) {
      this.setState({popUpShoww:true,ShowResponsee:{error:responseJson.errors,success:""}})
    }
    else {
      this.setState({userMailId:responseJson?.user?.data.attributes.email ,catalougeData: responseJson?.user, lawFirmData: true,changeimage:responseJson?.user?.data?.attributes?.profile_image?.url,billingDetails:responseJson?.user?.data.attributes.user_bank_details})
    }
  }

  getUserProfile = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {
      "Content-Type": "application/json",
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserDataEndPoint}${maintoken?.id || maintoken?.data?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({roledata:maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token})
  };


  UpdateUserData = async (values: {
    firmName: string,
    Website: string,
  },billingValue:{account_number: string,
    sort_code: string,
    accoutn_holder_name: string}) => {
    const signupToken: string = await getStorageData("userdetails");
    const loginToken: string = await getStorageData("token");
    const metaSignUpTokens = JSON.parse(signupToken);
    const metaLoginTokens = JSON.parse(loginToken);

    const maintokens = this.determineMainToken(metaSignUpTokens, metaLoginTokens);
    const header = {
      "token": maintokens?.serialized_data?.meta?.token || maintokens?.meta?.token
    };
    const formData = new FormData();
    formData.append("firm", values.firmName);
    formData.append("website", values.Website);
    formData.append("account_number", billingValue.account_number);
    formData.append("sort_code", billingValue.sort_code);
    formData.append("accountn_holder_name", billingValue.accoutn_holder_name);
      if(this.state.apiImage){
        formData.append("image",this.state.apiImage)
    }



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.UpdateLawFirmApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updatLawfirmEndPoint}${this.state.catalougeData.data.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeUpdate
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  inviteLinkSend = async (values: {
    role: string
    email: string,
  }) => {
    if(values.email){
      this.setState((prevState) => ({
        dataArray: [
          ...prevState.dataArray,
          { email: values.email }
        ]
      }));
    }

    const header = {
      "Content-Type": "application/json",
      "Authorization":"pat-na1-12f02bab-f25f-4779-8579-2f9e3a1714d5",
      "Token":this.state.roledata
    };

    const httpBody = {
      "email": values.email,
      "role": values.role,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );



    this.SendInviteLinkApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.inviteLinkEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAdd
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteBulkUploadAttachement = async (id:string) =>{
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);
    const maintokens = this.determineMainToken(metaSignUpToken, metaLoginToken);

    this.setState((prev)=>{return{...prev,isDeleteID:id}})

    const header = {
      token: maintokens?.serialized_data?.meta?.token || maintokens?.meta?.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DeleteBulkUploadEndPointCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PostBulkUploadEndPoint+`/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  uploadBulkAttachements = async ()=>{
    const allNotUploading = this.state.uploadProgress.length > 0 ? this.state.uploadProgress.every((obj:any) => obj.isUploading === false):false;
    if(!allNotUploading){
      return;
    }
    this.setState({upLoading:true})
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);
    const maintokens = this.determineMainToken(metaSignUpToken, metaLoginToken);
    const formdata = new FormData(); 
    const filesArray = Array.from(this.state.LoadedFiles);
    filesArray.forEach((file) => {
        formdata.append("files[]", file, file.name);
    });
    const header = {
      token: maintokens?.serialized_data?.meta?.token || maintokens?.meta?.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostBulkUploadEndPointCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.PostBulkUploadEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAdd
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handlebulkUploadEndPointResponse = async (response: any) => {
    if(response.data){
      this.setState((prev)=>{return {...prev, upLoading:false, uploadResponse:[...prev.uploadResponse,...response.data.attributes.files]}})
    }
    this.handleUplodeClosePopUp()
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );


    if (apiRequestCallId === this.UpdateLawFirmApiCallId) {
      if(responseJson?.message){
        this.setState({ editToggel: !this.state.editToggel,LawfirmNav:true ,popUpShoww:true,ShowResponsee:{error:"",success:"Lawfirm details updated successfully"}})
        setTimeout(() => {
          this.getUserProfile()
          this.handleLawFirmManagement()
        },500)
      }
      else if(responseJson?.errors[0]){
        this.setState({popUpShoww:true,ShowResponsee:{error:responseJson.errors[0].message,success:""}})
      }

    }
    if (apiRequestCallId === this.SendInviteLinkApiCallId) {

      if (responseJson?.errors) {
        this.setState({popUpShoww:true,ShowResponsee:{error:responseJson.errors,success:""}})
      }
      else {
        this.setState({showEmail:true,openDialog:true})
      }
    }
    if (apiRequestCallId === this.getUserProfileApiCallId) {

      this.getUserProfileAsync(responseJson)
    }
    if (apiRequestCallId === this.PostBulkUploadEndPointCallId) {
       this.handlebulkUploadEndPointResponse(responseJson)
    }
    if (apiRequestCallId === this.DeleteBulkUploadEndPointCallId) {
      if(responseJson.message){
        this.setState((prev)=>{return{...prev,uploadResponse:prev.uploadResponse.filter((item:any) => item.id !== prev.isDeleteID), isDeleting:false}})
        this.setState((prev)=>{return{...prev,isDeleteID:""}})
      }
   }
    // Customizable Area End
  }

  handleSubmitForm = async() => {
    if (this.formikRef && this.formikBillingRef) {
      const formikErrors = await this.formikRef.validateForm();
      const billingErrors = await  this.formikBillingRef.validateForm();
      const hasErrors = Object.keys(formikErrors).length > 0 || Object.keys(billingErrors).length > 0;
      if (!hasErrors) {
        this.setState({isProfileComplete:false})
        const formikValues = this.formikRef.values;
        const billingValues = this.formikBillingRef.values;
        this.UpdateUserData(formikValues,billingValues)
      }else{
        this.setState({isProfileComplete:true})
      }
    }
  }

  // Customizable Area End
}
