import React from "react";
import { TaskProfile } from "../types";
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  createTheme,
  ThemeProvider,
  styled,
} from "@material-ui/core";
import { StyledChip } from "../Tasks.web";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import { Audit, emptySearch } from "../assets";

const StyledTypography = styled(Typography)({
  textAlign: "center",
  fontWeight: 500,
  display: "flex",
  flexDirection: "column",
  padding: 0,
  lineHeight: "20.83px",
  gap: 2,
  '& > span:first-child': {
    fontSize: 22,
    color: "#262626",
    fontWeight: 600,
  },
  '& > span:last-child': {
    fontSize: 16,
    fontWeight: 400,
    color: "#8C8C8C"
  },
})

const tableTheme = createTheme({
  typography: {
    fontFamily: "DM Sans",
    h2: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: "23.44px",
      color: "#0F172A",
    },
  },
  overrides: {
    MuiTableCell: {
      head: {
        textWrap: "nowrap",
        fontSize: 12,
        lineHeight: "15.62px",
        color: "#8C8C8C",
        fontFamily: "DM Sans",
      },
    },
    MuiChip: {
      label: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiTableBody: {
      root: {
        '& > tr:last-child': {
          '& > td': {
            borderBottom: 0,
          },
        },
      },
    },
  },
});

type TasksTableContentProps = {
  tasksProfile: TaskProfile[];
  loading: boolean;
  toggleDrawer: (task: { id: string }) => void;
  searchMessage:boolean;
  filterTask:TaskProfile[];
  filterState:boolean;
  handlePopoverOpen?: (event: React.MouseEvent<HTMLElement>,taskId:string) => void
  handlePopoverClose?: () => void
};

const taskState: Record<string, string> = {
  new_task: "NEW",
  pending: "PENDING",
  in_progress: "IN PROGRESS",
  closed: "CLOSED",
};

export default function TasksTableContent({
  tasksProfile,
  loading,
  toggleDrawer,
  searchMessage,
  filterTask,
  filterState,
  handlePopoverOpen,
  handlePopoverClose
}: TasksTableContentProps) {
  const rendertaskRows = () => {
    if (loading) {
      return (
        <TableRow>
          <TableCell colSpan={7} align="center">
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <CircularProgress
                data-test-id="circular-loader"
                style={{ color: "#355EE7", width: 40, height: 40 }}
              />
            </Box>
          </TableCell>
        </TableRow>
      );
    }
  
    if (filterState) {
      if (filterTask.length === 0) {
        return (
          <TableRow>
          <TableCell colSpan={7} align="center">
            <Box
              style={{
                display: "flex",
                flexDirection:'column',
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                gap:"12px",
              }}
            >
                <img src={emptySearch} style={{ width: "90px", height: "90px" }} alt="No results" />
                <span style={{ color: "#F35F5F", fontSize: "24px", fontWeight: 700 }}>
                  There are no matches for this search
                </span>
              </Box>
            </TableCell>
          </TableRow>
        );
      }
      return filterTab();
    }
  
    if (tasksProfile.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={7} align="center">
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
              }}
            >
              <StyledTypography data-test-id="no-current-tasks">
                <span>No current tasks</span>
                <span>When you create a new task, you can find it here</span>
              </StyledTypography>
            </Box>
          </TableCell>
        </TableRow>
      );
    }
  
    return taskData();
  };
  
  const tableRow =(task:any)=>{
     return(  <TableRow
          key={task.id}
          style={{
            borderSpacing: "0px !important",
            borderCollapse: "unset",
          }}
          data-test-id={`task-${task.id}`}
          onClick={() => toggleDrawer({ id: task.id })}
        >
          <TableCell
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "calc(17ch + 1em)",
              textTransform: "capitalize",
              display:"flex",
              alignItems:"center",
              gap:"6px",
              width:"100%",
              paddingTop:'20px',
              paddingBottom:'20px'

            }}
            className="tableFont"
            title={task.attributes.task_name}
          >
           {handlePopoverOpen && <img 
              src={Audit} 
              data-test-id="audit_hover"
              onMouseEnter={(event) => {
                if(handlePopoverOpen){
                  handlePopoverOpen(event,task.id)
                }
              }}
              style={{cursor:"pointer"}}
            />}
            {task.attributes.task_name}
          </TableCell>
  
          <TableCell
            style={{
              width: "15%",
              fontSize: "12px",
              fontWeight: 400,
              fontFamily: "Dm sans",
              color: "#0F172A",
              textTransform: "capitalize",
            }}
          >
            {task.attributes.legel_service_type.name}
          </TableCell>
  
          <TableCell
            title={task.attributes.trademark_name}
            style={{
              width: "15%",
              fontSize: "12px",
              fontWeight: 400,
              fontFamily: "Dm sans",
              color: "#0F172A",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "calc(17ch + 1em)",
              textTransform: "capitalize",
            }}
          >
            {task.attributes.trademark_name}
          </TableCell>
  
          <TableCell
            title={task.attributes.trademark_application_number}
            style={{
              width: "13.6%",
              fontSize: "12px",
              fontWeight: 400,
              fontFamily: "Dm sans",
              color: "#0F172A",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100px",
              textTransform: "capitalize",
            }}
          >
            {task.attributes.trademark_application_number}
          </TableCell>
  
          <TableCell style={{ width: "14.8%" }}>
            <StyledChip
              style={{ width: 102 }}
              data-state={task.attributes.state.toLowerCase()}
              label={taskState[task.attributes.state]}
            />
          </TableCell>
  
          <TableCell style={{ width: "24%" }}>
            <StyledChip
              label={task.attributes.status.replace(/_/g, " ")}
              style={{ width: "174px", textAlign: "start" }}
            />
          </TableCell>
  
          <TableCell>
            <IconButton className={"iconButton"}>
              <ArrowForwardIosOutlinedIcon />
            </IconButton>
          </TableCell>
        </TableRow>)
  }

  const taskData =()=>{
    return tasksProfile.map((task) => (
       tableRow(task)
      ))
    
  }

  const filterTab = ()=>{
    return filterTask.map((task) => (
      tableRow(task)
    ));
  }

  return (
    <ThemeProvider theme={tableTheme}>
      <Box className="userdetailbox" style={{height: 'calc(100dvh - 230px)', overflow: "hidden"}} >
        <Typography variant="h2">All tasks</Typography>
        <TableContainer
          component={Paper}
          elevation={0}
          style={{
            borderRadius: "12px",
            border: "1px solid #F0F0F0",
            marginTop: "25px",
            boxShadow: "none",
            height: "calc(100% - 54px)"
          }}
          className="scrollable-box"
        >
          <Table style={{ borderSpacing: 0 }}  stickyHeader >
            <TableHead style={{ backgroundColor: "#FFFFFF" }}>
              <TableRow>
                <TableCell style={{ width: "11.8%" }}> Task Name</TableCell>
                <TableCell style={{ width: "15%" }}> Legal Service Type </TableCell>
                <TableCell style={{ width: "15%" }}> Trademark Name </TableCell>
                <TableCell style={{ width: "15%" }}> Trademark Number </TableCell>
                <TableCell style={{ width: "15%" }}> Task State </TableCell>
                <TableCell> Task Status </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rendertaskRows()}</TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ThemeProvider>
  );
}
