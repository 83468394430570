import React, { RefObject, useEffect, useRef, useState } from "react";
import {
  makeStyles,
  CircularProgress,
} from "@material-ui/core";

import { useDebounce } from "use-debounce";
import { SelectOption } from "./SearchAndFilter.web";
import useClickOutside from "./useClickOutside.web";

type ComboboxDropdownProps = {
  placeholder: string;
  options: Array<SelectOption>;
  loading: boolean;
  searchValue: string;
  onChange: (value: string) => void;
  onSelect: (option: SelectOption) => void;
}


const useComboboxStyle = makeStyles({
  container: {
    boxSizing: "border-box",
    display: "flex",
    gap: 8,
    alignItems: "center",
    padding: "12px 16px",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    border: "1px solid #C3D1FF",
    '& > input': {
      outline: "none",
      padding: 0,
      width: "100%",
      border: 0,
      fontWeight: 400,
      fontSize: 16,
      lineHeight: "24px",
      fontFamily: "DM Sans",
      color: "#262626",
      '&::placeholder': {
        color: "#8C8C8C"
      },
    },
  },
  searchPopupContainer: {
    position: "fixed",
    zIndex: 2000,
    border: "1px solid #F5F5F5",
    boxShadow: "0px 8px 32px 0px #01175F0F, 0px 4px 8px 0px #01175F08",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
  },
  noResultsOrLoadingContainer: {
    height: 144,
    display: "grid",
    placeItems: "center",
    '& > span': {
      fontFamily: "DM Sans",
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 400,
      color: "#8C8C8C"
    },
  },
  listBox: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    margin: 0,
    maxHeight: 144,
    overflowY: "auto",
    scrollbarColor: "#355EE7 #FFFFFF",
    "&::-webkit-scrollbar": {
      width: 8,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#FFFFFF",
      borderRadius: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#355EE7",
      borderRadius: 8,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#355EE7",
    },
  },
  option: {
    listStyle: "none",
    margin: 0,
    padding: "12px 16px",
    fontFamily: "DM Sans",
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 400,
    color: "#3C3E49",
    cursor: "pointer",
  },
})


const ComboboxDropdown = ({
  options,
  placeholder,
  loading,
  onChange,
  onSelect,
  searchValue
}: ComboboxDropdownProps) => {
  const [searchText, setSearchText] = useState(searchValue)
  const [debouncedSearchText] = useDebounce(searchText, 300)  
  const [dialogRect, setDialogRect] = useState({top: 0, left: 0, width: 0})

  const classes = useComboboxStyle()

  const containerRef = useRef<HTMLDivElement>(null)
  const isOpenRef = useRef(false)
  const openDialgRef = useRef(false)
  const isSelectedRef = useRef(false)

  const parentContainerRef = useClickOutside(() => {
    isOpenRef.current = false
    setDialogRect({top: 0,left: 0,width: 0})
  })

  useEffect(() => {
    if(debouncedSearchText.trim().length > 2 && !isSelectedRef.current) {
      onChange(debouncedSearchText)
      handleOpenDialog()
    }
  }, [debouncedSearchText])

  const handleOpenDialog = () => {
    if(containerRef.current && openDialgRef.current) {
      const rect = containerRef.current.getBoundingClientRect()
      isOpenRef.current = true
      setDialogRect({top: rect.bottom, left: rect.left, width: rect.width})
    } 
  }


  const handleClose = () => {
    isOpenRef.current = false
    openDialgRef.current = false
    setDialogRect({top: 0,left: 0, width: 0})
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    if(value.trim().length <= 2) {
      handleClose()
    }
    isSelectedRef.current= false
    openDialgRef.current = true
    setSearchText(value)
  }

  const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if(event.target.value.trim().length > 2) {
      openDialgRef.current = true
      handleOpenDialog()
    }
  }

  const handleSelectOption = (option: SelectOption) => () => {
    isSelectedRef.current = true
    onSelect(option)
    setSearchText(option.label)
    handleClose()
  }

  return (
    <div ref={parentContainerRef} style={{flex: 1, position: "relative"}}>
      <div ref={containerRef} className={classes.container}>
        <input 
          type="text" 
          role="combobox"
          aria-controls="search-popup"
          aria-autocomplete="list"
          aria-expanded={isOpenRef.current}
          value={searchText}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          placeholder={placeholder}
        />
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.3187 20.0273L15.232 13.9406C16.1766 12.7195 16.6875 11.2266 16.6875 9.65625C16.6875 7.77656 15.9539 6.01406 14.6273 4.68516C13.3008 3.35625 11.5336 2.625 9.65625 2.625C7.77891 2.625 6.01172 3.35859 4.68516 4.68516C3.35625 6.01172 2.625 7.77656 2.625 9.65625C2.625 11.5336 3.35859 13.3008 4.68516 14.6273C6.01172 15.9562 7.77656 16.6875 9.65625 16.6875C11.2266 16.6875 12.7172 16.1766 13.9383 15.2344L20.025 21.3187C20.0428 21.3366 20.064 21.3508 20.0874 21.3604C20.1107 21.3701 20.1357 21.3751 20.1609 21.3751C20.1862 21.3751 20.2112 21.3701 20.2345 21.3604C20.2578 21.3508 20.279 21.3366 20.2969 21.3187L21.3187 20.2992C21.3366 20.2814 21.3508 20.2602 21.3604 20.2369C21.3701 20.2135 21.3751 20.1885 21.3751 20.1633C21.3751 20.138 21.3701 20.113 21.3604 20.0897C21.3508 20.0664 21.3366 20.0452 21.3187 20.0273ZM13.3688 13.3688C12.375 14.3602 11.0578 14.9062 9.65625 14.9062C8.25469 14.9062 6.9375 14.3602 5.94375 13.3688C4.95234 12.375 4.40625 11.0578 4.40625 9.65625C4.40625 8.25469 4.95234 6.93516 5.94375 5.94375C6.9375 4.95234 8.25469 4.40625 9.65625 4.40625C11.0578 4.40625 12.3773 4.95 13.3688 5.94375C14.3602 6.9375 14.9062 8.25469 14.9062 9.65625C14.9062 11.0578 14.3602 12.3773 13.3688 13.3688Z" fill="#8C8C8C"/>
        </svg>
      </div>
      {
        isOpenRef.current && (
          <div role="dialog" className={classes.searchPopupContainer} style={{...dialogRect}} >
          {
            loading && options.length === 0 ? (
              <div className={classes.noResultsOrLoadingContainer} >
                <CircularProgress data-testid="loader" style={{height: 25, width: 25, color: "#355EE7"}} />
              </div>
            ) : null
          }
          {
            !loading && options.length === 0 ? (
              <div className={classes.noResultsOrLoadingContainer} >
                <span>No results found</span>
              </div>
            ) : (
              <ul role="listbox" id="search-popup" className={classes.listBox} >
                {
                  options.map((option) => (
                    <li key={option.value} role="option" className={classes.option} onClick={handleSelectOption(option)} >{option.label}</li>
                  ))
                }
              </ul>
            )
          }
        </div>
        )
      }
    </div>
  )
}

export default ComboboxDropdown
