import React from 'react';
interface ActiveIconProps {
    color: string;
  }
  
  export default function ArchiveMsgIcon({ color }: ActiveIconProps) {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.43761 10C8.43761 10.2486 8.53638 10.4871 8.7122 10.6629C8.88801 10.8387 9.12647 10.9375 9.37511 10.9375C9.62375 10.9375 9.86221 10.8387 10.038 10.6629C10.2138 10.4871 10.3126 10.2486 10.3126 10C10.3126 9.75136 10.2138 9.5129 10.038 9.33709C9.86221 9.16127 9.62375 9.0625 9.37511 9.0625C9.12647 9.0625 8.88801 9.16127 8.7122 9.33709C8.53638 9.5129 8.43761 9.75136 8.43761 10ZM4.53136 10C4.53136 10.2486 4.63013 10.4871 4.80595 10.6629C4.98176 10.8387 5.22022 10.9375 5.46886 10.9375C5.7175 10.9375 5.95596 10.8387 6.13177 10.6629C6.30759 10.4871 6.40636 10.2486 6.40636 10C6.40636 9.75136 6.30759 9.5129 6.13177 9.33709C5.95596 9.16127 5.7175 9.0625 5.46886 9.0625C5.22022 9.0625 4.98176 9.16127 4.80595 9.33709C4.63013 9.5129 4.53136 9.75136 4.53136 10Z" fill={color}/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3462 10.6629C11.3462 10.404 11.556 10.1942 11.8149 10.1942H19.1226C19.3815 10.1942 19.5913 10.404 19.5913 10.6629V12.8646C19.5913 13.1235 19.3815 13.3333 19.1226 13.3333H19.0625V17.3438C19.0625 17.6026 18.8526 17.8125 18.5938 17.8125H12.3438C12.0849 17.8125 11.875 17.6026 11.875 17.3438V13.3333H11.8149C11.556 13.3333 11.3462 13.1235 11.3462 12.8646V10.6629ZM13.0288 13.4375V16.875H17.9087V13.4375H13.0288ZM18.4375 12.3958H12.5V11.25H18.4375V12.3958ZM14.0625 14.5312C14.0625 14.2724 14.2724 14.0625 14.5313 14.0625H16.4063C16.6651 14.0625 16.875 14.2724 16.875 14.5312C16.875 14.7901 16.6651 15 16.4063 15H14.5313C14.2724 15 14.0625 14.7901 14.0625 14.5312Z" fill={color}/>
        <path d="M17.4454 6.60938C17.004 5.56055 16.3712 4.61914 15.5646 3.81055C14.7636 3.00663 13.8127 2.36762 12.7657 1.92969C11.6915 1.47852 10.5509 1.25 9.37511 1.25H9.33605C8.15246 1.25586 7.00597 1.49023 5.92785 1.95117C4.88987 2.3936 3.94789 3.03374 3.15441 3.83594C2.35558 4.64258 1.72863 5.58008 1.29503 6.625C0.845814 7.70703 0.619251 8.85742 0.625111 10.041C0.631738 11.3974 0.952634 12.7338 1.56261 13.9453V16.9141C1.56261 17.1523 1.65727 17.3809 1.82576 17.5494C1.99425 17.7178 2.22277 17.8125 2.46105 17.8125H5.43175C6.64326 18.4225 7.97966 18.7434 9.33605 18.75H9.37706C10.0135 18.75 10.6394 18.683 11.25 18.5505L9.91156 17.2656C8.57794 17.3958 7.1297 17.039 5.96105 16.416L5.79699 16.3281H3.04699V13.5781L2.9591 13.4141C2.40831 12.3809 2.11535 11.2109 2.10949 10.0332C2.10167 8.08594 2.84581 6.25781 4.21105 4.88086C5.57433 3.50391 7.3966 2.74219 9.34386 2.73438H9.37706C10.3536 2.73438 11.3009 2.92383 12.1935 3.29883C13.0646 3.66406 13.8458 4.18945 14.5177 4.86133C15.1876 5.53125 15.715 6.31445 16.0802 7.18555C16.3124 7.73863 16.4735 8.31226 16.5625 8.90104H18.0575C17.9591 8.11264 17.7538 7.34531 17.4454 6.60938Z" fill={color}/>
        </svg>
    )
}