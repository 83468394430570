import React, { RefObject, useEffect, useRef } from "react";


const useClickOutside = (handler: () => void): RefObject<HTMLDivElement> => {
    let domNodeRef = useRef<HTMLDivElement>(null);

    const eventHandler = (e: Event): void => {
        if(!domNodeRef.current?.contains(e.target as Node)) {
            handler()
        }
    }

    useEffect(()=>{
        document.addEventListener("mousedown",eventHandler);

        return () => { 
          document.removeEventListener("mousedown",eventHandler);
        }
    }, [])

    return domNodeRef;
}

export default useClickOutside