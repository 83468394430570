import React from "react";
import { IconButton, makeStyles, Badge, styled, Typography, Popover, CircularProgress } from "@material-ui/core";
import { ITaskDetails } from "../CollectTransactionFeesController";
import moment from "moment";
import { info } from "../assets";

type PayoutDetailProps = {
    invoiceTaskDetails: ITaskDetails | undefined
    IsTaskDetailsLoading: boolean
    digipCommission: string
}

const StyledBadge = styled(Badge)({
    minWidth: 100,
    display: "inline-block",
    textAlign: "center",
    padding: "8px 0px",
    backgroundColor: "#DCECFF",
    borderRadius: 40,
    fontFamily: "DM Sans",
    fontWeight: 700,
    fontSize: 12,
    lineHeight: "18px",
    color: "#355EE7",
    '&[data-state="paid"]': {
        backgroundColor: "#D1FAE5",
        color: "#059669",
    },
    '& + span': {
        color: "#434343",
        fontFamily: "DM Sans",
        fontWeight: 500,
        fontSize: 12,
        lineHeight: "15.62px",
    },
})

const useStyles = makeStyles({
    detailContainer: {
        display: "flex",
        flexDirection: "column",
        padding: 24,
        backgroundColor: "#F0F0F0",
        border: "1px solid #F0F0F0",
        borderRadius: 8,
        flex: 1,
        position: "relative",
        gap: 20,
    },
    noInvoiceContainer: {
        justifyContent: "center",
        alignItems: "center",
        gap: 4,
        '& > p': {
            margin: 0,
            width: 210,
            textAlign: "center",
            fontFamily: "DM Sans",
            fontWeight: 700,
            fontSize: 14,
            lineHeight: "18.23px",
            color: "#8C8C8C"
        },
    },
    icon: {
        width: 80,
        height: 80,
        display: "grid",
        placeItems: "center",
        backgroundColor: "#F0F0F0",
        borderRadius: 500,
    },
    divider: {
        border: "1px solid #F0F0F0",    
    },
    iconContainer: {
        position: "absolute",
        top: 6,
        right: 6,
        display: "flex",
        gap: 6,
    },
    amountReceived: {
        backgroundColor: "#ECF1FF",
        padding: "4px 8px",
        borderRadius: 50,
        fontWeight: 500,
        color: "#434343",
    },
    taskInfo: {
        fontWeight: 500,
        color: "#0F172A"
    },
    seeMoreBtn: {
        all: "unset",
        cursor: "pointer",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "16px",
        color: "#355EE7",
        display: "flex",
        gap: 6,
        marginLeft: "auto",
        '& > svg': {
            fontSize: "1rem",
        },
    },
})

const StylePopover = styled(Popover)({
 '& .MuiPaper-root': {
    borderRadius: '12px',
  },
})

export default function PayoutDetail({ invoiceTaskDetails,IsTaskDetailsLoading,digipCommission }: PayoutDetailProps) {
    const classes = useStyles()

    const renderDetailsStatus = (status:string) => {
        let statusVal
        let statusColor
        let statusBgColor
        switch(status){
          case 'pending_payment':
            statusVal = "DUE PAYMENT"
            statusBgColor= "#DCECFF"
            statusColor = "#355EE7"
            break
          case 'paid_to_digip':
            statusVal = "PAID TO DIGIP"
            statusBgColor= "#FEF3C7"
            statusColor = "#D97706"
            break
          case 'payment_done':
            statusVal = "PAID"
            statusBgColor= "#D1FAE5"
            statusColor = "#059669"
            break
        }
        return {statusVal,statusColor,statusBgColor}
      }


      const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

      const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
      };

      const handleClose = () => {
          setAnchorEl(null);
      };

      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;

  const getPopover = () => {
      return (
      <div>
          <StylePopover
          data-test-id="close_popover" 
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
          >
            <div style={{display:"flex",flexDirection:"column",padding:"10px"}}>
            <IconButton style={{padding: 0,display:"flex",justifyContent:"end"}} >
              <img src={info} />
            </IconButton>
            <Typography style={{ padding:2,width:"300px",fontSize:"16px" }}>This amount paid has a deduction of {digipCommission}% commission for Digip.</Typography>
            </div>
          </StylePopover>
        </div>
      )
  }

  if(IsTaskDetailsLoading){
    return (
        <div style={{textAlign:"center", padding:"30px"}}>
            <CircularProgress style={{ color: "#355EE7", width: 40, height: 40 }}  />
        </div>
    )
  }
 

    if(!invoiceTaskDetails) {
        return (
            <div className={`${classes.detailContainer} ${classes.noInvoiceContainer}`} >
                <div className={classes.icon} >
                    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.4982 10.2539V8.7539C23.4982 8.6164 23.3857 8.5039 23.2482 8.5039H11.2482C11.1107 8.5039 10.9982 8.6164 10.9982 8.7539V10.2539C10.9982 10.3914 11.1107 10.5039 11.2482 10.5039H23.2482C23.3857 10.5039 23.4982 10.3914 23.4982 10.2539ZM11.2482 13.0039C11.1107 13.0039 10.9982 13.1164 10.9982 13.2539V14.7539C10.9982 14.8914 11.1107 15.0039 11.2482 15.0039H16.9982C17.1357 15.0039 17.2482 14.8914 17.2482 14.7539V13.2539C17.2482 13.1164 17.1357 13.0039 16.9982 13.0039H11.2482Z" fill="#8C8C8C"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M23.749 18.0039C20.0209 18.0039 16.999 21.0258 16.999 24.7539C16.999 28.482 20.0209 31.5039 23.749 31.5039C27.4771 31.5039 30.499 28.482 30.499 24.7539C30.499 21.0258 27.4771 18.0039 23.749 18.0039ZM23.749 29.5039C25.0178 29.5039 26.2115 29.0102 27.1084 28.1133C28.0053 27.2164 28.499 26.0227 28.499 24.7539C28.499 23.4852 28.0053 22.2914 27.1084 21.3945C26.2115 20.4977 25.0178 20.0039 23.749 20.0039C22.4803 20.0039 21.2865 20.4977 20.3896 21.3945C19.4928 22.2914 18.999 23.4852 18.999 24.7539C18.999 26.0227 19.4928 27.2164 20.3896 28.1133C21.2865 29.0102 22.4803 29.5039 23.749 29.5039Z" fill="#8C8C8C"/>
                        <path d="M23.4149 28.9114V21.0004H23.9999V28.9114H23.4149ZM23.7839 28.2364C23.3339 28.2364 22.9259 28.1614 22.5599 28.0114C22.1999 27.8554 21.9149 27.6274 21.7049 27.3274C21.4949 27.0274 21.3809 26.6584 21.3629 26.2204H22.8029C22.8089 26.3944 22.8539 26.5474 22.9379 26.6794C23.0219 26.8114 23.1359 26.9164 23.2799 26.9944C23.4239 27.0664 23.5919 27.1024 23.7839 27.1024C23.9459 27.1024 24.0869 27.0784 24.2069 27.0304C24.3329 26.9764 24.4319 26.9014 24.5039 26.8054C24.5759 26.7034 24.6119 26.5774 24.6119 26.4274C24.6119 26.2714 24.5699 26.1394 24.4859 26.0314C24.4019 25.9174 24.2879 25.8214 24.1439 25.7434C23.9999 25.6594 23.8319 25.5844 23.6399 25.5184C23.4539 25.4464 23.2529 25.3774 23.0369 25.3114C22.5389 25.1494 22.1549 24.9304 21.8849 24.6544C21.6209 24.3784 21.4889 24.0094 21.4889 23.5474C21.4889 23.1634 21.5819 22.8364 21.7679 22.5664C21.9599 22.2904 22.2209 22.0804 22.5509 21.9364C22.8809 21.7924 23.2559 21.7204 23.6759 21.7204C24.1079 21.7204 24.4859 21.7954 24.8099 21.9454C25.1399 22.0894 25.4009 22.3024 25.5929 22.5844C25.7909 22.8604 25.8959 23.1904 25.9079 23.5744H24.4589C24.4529 23.4424 24.4139 23.3224 24.3419 23.2144C24.2759 23.1064 24.1829 23.0194 24.0629 22.9534C23.9489 22.8874 23.8139 22.8544 23.6579 22.8544C23.5199 22.8484 23.3939 22.8694 23.2799 22.9174C23.1659 22.9594 23.0759 23.0284 23.0099 23.1244C22.9439 23.2144 22.9109 23.3284 22.9109 23.4664C22.9109 23.5984 22.9439 23.7154 23.0099 23.8174C23.0819 23.9134 23.1779 23.9974 23.2979 24.0694C23.4239 24.1354 23.5679 24.1984 23.7299 24.2584C23.8979 24.3184 24.0809 24.3784 24.2789 24.4384C24.5969 24.5464 24.8879 24.6754 25.1519 24.8254C25.4219 24.9694 25.6379 25.1614 25.7999 25.4014C25.9679 25.6354 26.0519 25.9474 26.0519 26.3374C26.0519 26.6794 25.9619 26.9944 25.7819 27.2824C25.6079 27.5704 25.3529 27.8014 25.0169 27.9754C24.6869 28.1494 24.2759 28.2364 23.7839 28.2364Z" fill="#8C8C8C"/>
                        <path d="M8.99866 27.0039V5.12889H24.2488C24.801 5.12889 25.2488 5.57661 25.2488 6.1289V16.1289C26.1473 16.3588 26.7102 16.5327 27.4988 17.0039V5.87897C27.4988 4.22212 26.1556 2.87897 24.4988 2.87896L5.99817 2.87891C3.78906 2.8789 1.99821 4.6697 1.99815 6.87881L1.99805 11.0039C1.99803 11.5562 2.44575 12.0039 2.99805 12.0039H4.49848H6.74866V9.5039H4.49848V6.96627C4.49848 5.80823 5.69041 5.0336 6.74866 5.5039L-nan -nanL6.74866 5.5039V6.5039V9.5039V12.0039V30.505C6.74866 30.6553 6.9084 30.7519 7.04157 30.6821L8.56201 29.8845C8.62019 29.8539 8.68965 29.8539 8.74783 29.8845L10.4583 30.7818C10.5219 30.8151 10.5985 30.8118 10.659 30.7731L12.0159 29.9047C12.0816 29.8627 12.1658 29.8627 12.2315 29.9047L13.5784 30.7667C13.6441 30.8088 13.7283 30.8088 13.794 30.7667L15.1468 29.901C15.2095 29.8609 15.2892 29.8589 15.3538 29.8958L16.999 30.8357C16.1635 29.8953 15.8181 28.3468 15.499 27.1301L15.3437 27.0518C15.2843 27.0219 15.214 27.0234 15.156 27.0559L13.7839 27.8242C13.7232 27.8582 13.6492 27.8582 13.5885 27.8242L12.2214 27.0586C12.1607 27.0246 12.0867 27.0246 12.026 27.0586L10.6589 27.8242C10.5982 27.8582 10.5242 27.8582 10.4635 27.8242L8.99866 27.0039Z" fill="#8C8C8C"/>
                    </svg>
                </div>
                <p>Select an invoice to see all its details here. </p>
            </div>
        )
    }

    return (
        <div className={classes.detailContainer} >
            <div className={classes.iconContainer} >
                <IconButton style={{padding: 0}} data-test-id="open_popover" onClick={handleClick} >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM10 17.2656C5.98828 17.2656 2.73438 14.0117 2.73438 10C2.73438 5.98828 5.98828 2.73438 10 2.73438C14.0117 2.73438 17.2656 5.98828 17.2656 10C17.2656 14.0117 14.0117 17.2656 10 17.2656Z" fill="#BFBFBF"/>
                        <path d="M9.0625 6.5625C9.0625 6.81114 9.16127 7.0496 9.33709 7.22541C9.5129 7.40123 9.75136 7.5 10 7.5C10.2486 7.5 10.4871 7.40123 10.6629 7.22541C10.8387 7.0496 10.9375 6.81114 10.9375 6.5625C10.9375 6.31386 10.8387 6.0754 10.6629 5.89959C10.4871 5.72377 10.2486 5.625 10 5.625C9.75136 5.625 9.5129 5.72377 9.33709 5.89959C9.16127 6.0754 9.0625 6.31386 9.0625 6.5625ZM10.4688 8.75H9.53125C9.44531 8.75 9.375 8.82031 9.375 8.90625V14.2188C9.375 14.3047 9.44531 14.375 9.53125 14.375H10.4688C10.5547 14.375 10.625 14.3047 10.625 14.2188V8.90625C10.625 8.82031 10.5547 8.75 10.4688 8.75Z" fill="#BFBFBF"/>
                    </svg>
                </IconButton>
            </div>
            <div className="flex space-between items-center" >
                <StyledBadge 
                style={{backgroundColor:renderDetailsStatus(invoiceTaskDetails?.payment.status).statusBgColor,color:renderDetailsStatus(invoiceTaskDetails?.payment.status).statusColor}} 
                    data-state="paid">{renderDetailsStatus(invoiceTaskDetails?.payment.status).statusVal}</StyledBadge>

              {invoiceTaskDetails?.payment.status === "payment_done" &&  <span>{moment(invoiceTaskDetails?.task.created_at).format("DD/MM/YYYY")}</span>}
            </div>
            <div className={classes.divider} />
            <div className="flex flex-column" style={{gap: 12}} >
                <div className="flex" style={{gap: 12}} >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.6251 9.9375V8.8125C17.6251 8.70937 17.5407 8.625 17.4376 8.625H8.4376C8.33448 8.625 8.2501 8.70937 8.2501 8.8125V9.9375C8.2501 10.0406 8.33448 10.125 8.4376 10.125H17.4376C17.5407 10.125 17.6251 10.0406 17.6251 9.9375ZM8.4376 12C8.33448 12 8.2501 12.0844 8.2501 12.1875V13.3125C8.2501 13.4156 8.33448 13.5 8.4376 13.5H12.7501C12.8532 13.5 12.9376 13.4156 12.9376 13.3125V12.1875C12.9376 12.0844 12.8532 12 12.7501 12H8.4376Z" fill="#9AB1FF"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M20.6255 22.0354V4.0313C20.6255 2.78866 19.6182 1.7813 18.3755 1.78129L4.50009 1.78125C2.84326 1.78124 1.50012 3.12435 1.50008 4.78117L1.5 7.87497C1.49999 8.28919 1.83578 8.62499 2.25 8.62499H3.37532H5.06296V6.74999H3.37532V4.84678C3.37532 3.97825 4.26927 3.39727 5.06296 3.74999V4.49999V6.74999V8.62499V22.5008C5.06296 22.6136 5.18276 22.686 5.28264 22.6336L6.42297 22.0354C6.46661 22.0125 6.5187 22.0125 6.56234 22.0354L7.8452 22.7084C7.8929 22.7334 7.95036 22.7309 7.99574 22.7019L9.01339 22.0506C9.06268 22.0191 9.12581 22.0191 9.1751 22.0506L10.1853 22.6971C10.2346 22.7287 10.2977 22.7287 10.347 22.6971L11.4705 22.0478C11.5175 22.0177 11.5773 22.0162 11.6257 22.0439L12.6778 22.7032C12.723 22.7315 12.7798 22.7337 12.827 22.709L14.0231 22.0832C14.0697 22.0588 14.1256 22.0606 14.1705 22.0879L15.1832 22.7031C15.2297 22.7314 15.2879 22.7322 15.3352 22.7054L16.4229 22.0881C16.4708 22.0609 16.5299 22.0621 16.5766 22.0914L17.5515 22.7023C17.5973 22.7311 17.6552 22.7329 17.7027 22.707L18.8745 22.0699C18.9147 22.0481 18.9625 22.0458 19.0046 22.0636L20.4171 22.6607C20.516 22.7026 20.6255 22.63 20.6255 22.5226V22.0354ZM6.75046 3.46874V19.875L7.84906 20.4902C7.89459 20.5157 7.95011 20.5157 7.99564 20.4902L9.02095 19.916C9.06649 19.8905 9.122 19.8905 9.16753 19.916L10.1928 20.4902C10.2384 20.5157 10.2939 20.5157 10.3394 20.4902L11.4773 19.914C11.5208 19.8896 11.5736 19.8885 11.6181 19.9109L11.7346 19.9696L12.6879 20.458C12.7277 20.4784 12.7745 20.48 12.8156 20.4623L14.0342 19.9384C14.0748 19.921 14.121 19.9223 14.1605 19.942L15.194 20.458C15.2348 20.4784 15.2826 20.4791 15.324 20.46L16.4268 19.9502C16.4729 19.9289 16.5267 19.9324 16.5697 19.9595L17.5513 20.5781C17.5973 20.6071 17.6553 20.6089 17.703 20.583L18.8597 19.9535C18.908 19.9273 18.938 19.8767 18.938 19.8218V4.21874C18.938 3.80453 18.6022 3.46874 18.188 3.46874H6.75046Z" fill="#9AB1FF"/>
                        <path d="M17.6251 6.75V5.625C17.6251 5.52187 17.5407 5.4375 17.4376 5.4375H8.4376C8.33448 5.4375 8.2501 5.52187 8.2501 5.625V6.75C8.2501 6.85313 8.33448 6.9375 8.4376 6.9375H17.4376C17.5407 6.9375 17.6251 6.85313 17.6251 6.75Z" fill="#9AB1FF"/>
                    </svg>
                    <Typography variant="h4" component="h4" >
                        Transaction ID: <span style={{color: "#355EE7"}} >{invoiceTaskDetails?.payment.transaction_id}</span>
                    </Typography>
                </div>
                <div className="flex space-between" >
                    <Typography variant="body2" component="span" >Task price:</Typography>
                    <Typography variant="body2" component="span" style={{color: "#434343"}} >&euro; {invoiceTaskDetails?.payment.task_price.toFixed(2)}</Typography>
                </div>
                <div className="flex space-between" >
                    <Typography variant="body2" component="span" >Digip fee ({digipCommission}%):</Typography>
                    <Typography variant="body2" component="span" style={{color: "#434343"}} >&euro; {Number(invoiceTaskDetails?.payment.digip_fee).toFixed(2)}</Typography>
                </div>
                <div className="flex space-between" >
                    <Typography variant="body2" component="span" >Amount received:</Typography>
                    <Typography variant="body2" component="span" className={classes.amountReceived} >&euro; {Number(invoiceTaskDetails?.payment.amount_received).toFixed(2)}</Typography>
                </div>
            </div>
            <div className={classes.divider} />
            <div className="flex flex-column" style={{gap: 12}} >
            <div className="flex" style={{gap: 12}} >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.0297 6.76406L14.9859 1.72031C14.8453 1.57969 14.6555 1.5 14.4562 1.5H4.5C4.08516 1.5 3.75 1.83516 3.75 2.25V21.75C3.75 22.1648 4.08516 22.5 4.5 22.5H19.5C19.9148 22.5 20.25 22.1648 20.25 21.75V7.29609C20.25 7.09687 20.1703 6.90469 20.0297 6.76406ZM18.5203 7.64062H14.1094V3.22969L18.5203 7.64062ZM18.5625 20.8125H5.4375V3.1875H12.5156V8.25C12.5156 8.51107 12.6193 8.76145 12.8039 8.94606C12.9885 9.13066 13.2389 9.23438 13.5 9.23438H18.5625V20.8125ZM11.8125 14.4844H7.5C7.39687 14.4844 7.3125 14.5688 7.3125 14.6719V15.7969C7.3125 15.9 7.39687 15.9844 7.5 15.9844H11.8125C11.9156 15.9844 12 15.9 12 15.7969V14.6719C12 14.5688 11.9156 14.4844 11.8125 14.4844ZM7.3125 11.4844V12.6094C7.3125 12.7125 7.39687 12.7969 7.5 12.7969H16.5C16.6031 12.7969 16.6875 12.7125 16.6875 12.6094V11.4844C16.6875 11.3813 16.6031 11.2969 16.5 11.2969H7.5C7.39687 11.2969 7.3125 11.3813 7.3125 11.4844Z" fill="#9AB1FF"/>
                </svg>
                <Typography variant="h4" component="h4" >
                    Task Info 
                </Typography>
            </div>
                <div className="flex space-between" >
                    <Typography variant="body2" component="span" >Task name</Typography>
                    <Typography variant="body2" component="span" style={{color: "#434343"}} >{invoiceTaskDetails?.task.task_name}</Typography>
                </div>
                <div className="flex space-between" >
                    <Typography variant="body2" component="span" >Date created</Typography>
                    <Typography variant="body2" component="span" className={classes.taskInfo} >{moment(invoiceTaskDetails?.task.created_at).format("DD/MM/YYYY")}</Typography>
                </div>
            </div>
            {getPopover()}

        </div>
    )
}