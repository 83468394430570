import React, { useState } from "react";
import {
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { CalendarTodayOutlined, ChevronLeft, ChevronRight } from "@material-ui/icons";

import { Calendar, DateObject } from "react-multi-date-picker";
import useClickOutside from "./useClickOutside.web";


type CalendarDropdownProps = {
  onDateChange: (dateObject: DateObject) => void;
  currentDateObject: DateObject | null;
  placeholder: string;
  align?: "left" | "right";
}


const useCalendarStyle = makeStyles({
  container: {
    outline: "none",
    width: "100%",
    cursor: "pointer",
    background: "#FFFFFF",
    border: "1px solid #C3D1FF",
    borderRadius: "8px",
    padding: "12px 16px",
    display: "flex",
    gap: "8px",
    justifyContent: "space-between",
    '& > span': {
      fontFamily: "DM Sans",
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 400,
      color: "#262626",
      '&.placeholder': {
        color: "#8C8C8C"
      },
    },
  },
  calendarContainer: {
    position: "fixed",
    zIndex: 2000,
    '& .rmdp-border': {
      border: "1px solid #E2E8F0"
    },
    '& .rmdp-wrapper': {
      borderRadius: 8,
      boxShadow: "0px 8px 32px 0px #01175F0F, 0px 4px 8px 0px #01175F08"
    },
    '& .rmdp-calendar': {
      padding: 24,
    },
    '& .rmdp-header': {
      margin: "0px 0px 16px",
      padding: 0,
    },
    '& .rmdp-header-values': {
      fontFamily: "DM Sans",
      fontWeight: 700,
      fontSize: 14,
      lineHeight: "22px",
      color: "#434343",
    },
    '& .rmdp-day-picker': {
      padding: 0,
      '& > div': {
        display: "flex",
        flexDirection: "column",
        gap: 6,
      },
    },
    '& .rmdp-week': {
      gap: 6
    },
    '& .rmdp-week-day, & .rmdp-day > span': {
      fontFamily: "DM Sans",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "22px"
    },
    '& .rmdp-week-day, & .rmdp-day': {
      width: 40,
      height: 24,
      alignItems: "center",
      color: "#BFBFBF",
    },
    '& .rmdp-day': {
      width: 40,
      height: 40,
      display: "grid",
      placeItems: "center",
      color: "#434343",
      '& > span': {
        width: "100%",
        height: "100%",
        inset: 0,
        boxSizing: "border-box"
      },
    },
    '& .rmdp-deactive': {
      color: "#D9D9D9"
    },
    '& .rmdp-day.rmdp-today span, & .rmdp-day.rmdp-selected span:not(.highlight), & .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover': {
      backgroundColor: "#DCECFF",
      border: "1px solid #7192FF",
      color: "#355EE7"
    },
  },
})

const CalendarDropdown = ({
  onDateChange,
  placeholder,
  align = "left",
  currentDateObject,
}: CalendarDropdownProps) => {
  const [calendarAnchorEl, setCalendarAnchorEl] = useState<HTMLElement | null>(null)
  const classes = useCalendarStyle()
  const parentContainerRef = useClickOutside(() => setCalendarAnchorEl(null))

  const renderButton = (direction: "left" | "right", handleClick: React.MouseEventHandler<HTMLButtonElement>) => {
    return (
        <IconButton style={{padding: 0}} onClick={handleClick}>
            {direction === "left" ? (
                <ChevronLeft htmlColor="#355EE7" />
            ) : (
                <ChevronRight htmlColor="#355EE7" />
            )}
        </IconButton>
    )
  }

  const handleCalendarIconButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCalendarAnchorEl(event.currentTarget.parentElement)
  }

  const handleDateChange = (dateObject: DateObject | null) => {
    if(!dateObject) {
      return;
    }
    onDateChange(dateObject)
    setCalendarAnchorEl(null)
  }

  let top = 0;
  let left = 0;
  let transform = "translateX(0%)"

  if(calendarAnchorEl) {
    const rect = calendarAnchorEl.getBoundingClientRect()
    top = rect.bottom
    left = align === "left" ? rect.left : rect.right
    transform = align === "left" ? "translateX(0%)" : "translateX(-100%)"
  }

  const isOpen = Boolean(calendarAnchorEl)

  return (
    <div ref={parentContainerRef} style={{flex: 1}}>
      <button data-testid="calendar-btn" className={classes.container} onClick={handleCalendarIconButtonClick} >
        <span className={currentDateObject ? "" : "placeholder"} >{currentDateObject ? currentDateObject.format("DD/MM/YYYY") : placeholder}</span>
        <CalendarTodayOutlined htmlColor="#262626" />
      </button>
      {
        isOpen ? (
          <div className={classes.calendarContainer} style={{top, left, transform}} >
            <Calendar 
                range={false}
                value={currentDateObject ? currentDateObject.format() : ""}
                showOtherDays={true}
                shadow={false}
                highlightToday={false}
                hideWeekDays={false}
                monthYearSeparator={" "}
                weekStartDayIndex={1}
                renderButton={renderButton}
                rangeHover={false}
                disableMonthPicker={true}
                disableYearPicker={true}
                weekPicker={false}
                onChange={handleDateChange}
            />
          </div>
        ) : null
      }
    </div>
  )
}

export default CalendarDropdown
