import React from "react";
import { makeStyles, styled, Badge, IconButton } from "@material-ui/core";
import { ArrowForwardIosRounded } from "@material-ui/icons";
import {  CSSProperties } from "@material-ui/styles";
import { ITask } from "../CollectTransactionFeesController";
import moment from "moment";

interface IParam {
  tasks: ITask,
  onClickTask: (id: string|number) => void
}

const StyleBadge = styled(Badge)({
  width: 96,
  display: "inline-block",
  borderRadius: 12,
  padding: "4px 0px",
  textAlign: "center",
  fontFamily: "DM Sans",
  fontWeight: 700,
  fontSize: 12,
  lineHeight: "15.62px",
  '&[data-state="digip"]': {
    backgroundColor: "#FEF3C7",
    color: "#D97706",
  },
  '&[data-state="pending"]': {
    backgroundColor: "#DCECFF",
    color: "#355EE7",
  },
  '&[data-state="paid"]': {
    backgroundColor: "#D1FAE5",
    color: "#059669",
  },
});

const spanStyle: CSSProperties = {
  display: "inline-block",
  fontWeight: 500,
  fontFamily: "DM Sans",
  fontSize: 12,
  lineHeight: "14.4px",
  color: "#8C8C8C",
};

const useStyles = makeStyles({
  row: {
    display: "grid",
    gridTemplateColumns: "47fr 153fr 65fr 67fr 122fr",
    gap: 12,
    alignItems: "center",
    padding: "12px 6px",
    borderBottom: "1px solid #ECF1FF",
    cursor: "pointer",
    "&:last-child": {
      borderBottom: 0,
    },
  },
  id: {
    ...spanStyle,
    flexGrow: 63.5,
  },
  taskName: {
    flexGrow: 190,
     width: "240px",
    "& > span": {
      ...spanStyle,
      fontSize: 14,
      lineHeight: "16.8px",
      color: "#262626",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "200px",
     
    },
  },
  amount: {
    ...spanStyle,
    fontSize: 14,
    lineHeight: "18.23px",
    color: "#262626",
    flexGrow: 102,
    display: "flex",
    gap:"2px",
    alignItems: "center",
  },
  date: {
    ...spanStyle,
    flexGrow: 65,
  },
  status: {
    display: "flex",
    gap: 6,
    flexGrow: 122,
    alignItems: "center",
  },
});

export default function Task(params:IParam) {
  const classes = useStyles();
  const {tasks} =  params

  const handleClickTask = (id:string | number) => {
    params.onClickTask(id)
  }

  const renderStatus = (status:string) => {
    let statusVal
    let statusColor
    let statusBgColor
    switch(status){
      case 'pending_payment':
        statusVal = "Pending"
        statusBgColor= "#DCECFF"
        statusColor = "#355EE7"
        break
      case 'paid_to_digip':
        statusVal = "Paid To Digip"
        statusBgColor= "#FEF3C7"
        statusColor = "#D97706"
        break
      case 'payment_done':
        statusVal = "Paid"
        statusBgColor= "#D1FAE5"
        statusColor = "#059669"
        break
    }
    return {statusVal,statusColor,statusBgColor}
  }

  return (
    <>
    <div className={classes.row} data-test-id="row-child" onClick={()=>handleClickTask(tasks.task_id)}>
      <div className={classes.id}>{tasks.task_id}</div>
      <div className={classes.taskName}>
        <span>{tasks.task_name}</span>
      </div>
      <div className={classes.amount}><span>&euro; </span> <span>{tasks.amount.toFixed(2)}</span></div>
      <div className={classes.date}>{moment(tasks.created_at).format("DD/MM/YYYY")}</div>
      <div className={classes.status}>
        <StyleBadge style={{backgroundColor:renderStatus(tasks.payment_state).statusBgColor,color:renderStatus(tasks.payment_state).statusColor}} data-state="paid">{renderStatus(tasks.payment_state).statusVal}</StyleBadge>
        <IconButton style={{ padding: 0 }}>
          <ArrowForwardIosRounded
            htmlColor="#355EE7"
            style={{ fontSize: "1.25rem" }}
          />
        </IconButton>
      </div>
    </div>
    </>
  );
}
