import React, { useState } from "react";
import SearchAndFilter, {
  SelectOption,
} from "../../../advancedsearch/src/components/SearchAndFilter.web";
import { OptionProps, components } from "react-select";
import { DateObject } from "react-multi-date-picker";
import ComboboxDropdown from "../../../advancedsearch/src/components/ComboboxDropdown.web";
import SelectDropdown from "../../../advancedsearch/src/components/SelectDropdown.web";
import CalendarDropdown from "../../../advancedsearch/src/components/CalendarDropdown.web";

export type DocumentationSearchArgs = {
  typeOption: SelectOption | null;
  fileOrFolderName: string; 
  ownerName: string;
  dateUploaded: DateObject | null;
}

type DocumentationSearchAndFilterProps = {
  onOwnerSearch: (value: string) => void;
  onDocumentationSearch: (params: DocumentationSearchArgs) => void;
  ownerNameLoading: boolean;
  ownerNameOptions: Array<SelectOption>;
  onFilterPopoverOpen: () => void;
}


const fileFolderTypeOptions: Array<SelectOption> = [
  {
    label: "FOLDER",
    value: "folder",
  },
  {
    label: "FILE",
    value: "file",
  },
];

const CustomOption = (props: OptionProps<SelectOption>) => {
  return (
    <components.Option {...props}>
      {props.data.value === "file" ? (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3531 4.50938L9.99063 1.14688C9.89688 1.05313 9.77031 1 9.6375 1H3C2.72344 1 2.5 1.22344 2.5 1.5V14.5C2.5 14.7766 2.72344 15 3 15H13C13.2766 15 13.5 14.7766 13.5 14.5V4.86406C13.5 4.73125 13.4469 4.60313 13.3531 4.50938ZM12.3469 5.09375H9.40625V2.15313L12.3469 5.09375ZM12.375 13.875H3.625V2.125H8.34375V5.5C8.34375 5.67405 8.41289 5.84097 8.53596 5.96404C8.65903 6.08711 8.82595 6.15625 9 6.15625H12.375V13.875Z"
            fill="#BFBFBF"
          />
        </svg>
      ) : (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.75 4.6625H8.14062L6.30781 2.90937C6.28449 2.88754 6.25382 2.87527 6.22188 2.875H2.25C1.97344 2.875 1.75 3.09844 1.75 3.375V12.625C1.75 12.9016 1.97344 13.125 2.25 13.125H13.75C14.0266 13.125 14.25 12.9016 14.25 12.625V5.1625C14.25 4.88594 14.0266 4.6625 13.75 4.6625ZM13.125 12H2.875V4H5.82031L7.68906 5.7875H13.125V12Z"
            fill="#BFBFBF"
          />
        </svg>
      )}
      <span style={{ fontWeight: 600, fontSize: 10, color: "#262626" }}>
        {props.data.label}
      </span>
    </components.Option>
  );
};

export default function DocumentationSearchAndFilter({
  onOwnerSearch,
  ownerNameLoading,
  ownerNameOptions,
  onDocumentationSearch,
  onFilterPopoverOpen,
}: DocumentationSearchAndFilterProps) {
  const [documentationSearchState, setDocumentationSearchState] = useState<DocumentationSearchArgs>({ownerName: "", fileOrFolderName: "", dateUploaded: null, typeOption: null})
  
  const handleUpdateDocumentationSearchState = (stateKey: keyof DocumentationSearchArgs, value: DocumentationSearchArgs[keyof DocumentationSearchArgs]) => {
    const newDocumentationSearchState: DocumentationSearchArgs = {...documentationSearchState, [stateKey]: value}
    setDocumentationSearchState(newDocumentationSearchState)
    onDocumentationSearch(newDocumentationSearchState)
  }
  
  return (
    <SearchAndFilter placeholder="Search by name" onFilterPopoverOpen={onFilterPopoverOpen} onInputChange={(value) => handleUpdateDocumentationSearchState("fileOrFolderName", value)}>
      <SelectDropdown
        options={fileFolderTypeOptions}
        placeholder="Type"
        selectedOption={documentationSearchState.typeOption}
        onSelect={(option) => handleUpdateDocumentationSearchState("typeOption", option)}
        Option={CustomOption}
      />
      <ComboboxDropdown 
        searchValue={documentationSearchState.ownerName}
        placeholder="Owner"
        onSelect={(option) => handleUpdateDocumentationSearchState("ownerName", option.label)}
        onChange={onOwnerSearch}
        loading={ownerNameLoading}
        options={ownerNameOptions}
      />
      <CalendarDropdown
        placeholder="Date uploaded"
        onDateChange={(date) => handleUpdateDocumentationSearchState("dateUploaded", date)}
        currentDateObject={documentationSearchState.dateUploaded}
      />
    </SearchAndFilter>
  );
}
