import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
interface ServiceLegals {
  legel_service_type:string
}
export interface LegalServiceType {
  legal_service_name: string; 
}
export interface UserDatatype {
  id: string;
  attributes: {
    associated_lawyer_details:{
      data:{
        attributes:{
          profile_image: {url:File|null},
          first_name: string,
          firm: string,
          legel_service_type: ServiceLegals[],
          jurisdiction: string,
          years_of_experience: string,
          lawyer_status: string
        }
      }
    }
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
 
  mobileOpen: boolean;
  opendialog:boolean;
  openOption:boolean;
  closeOption:boolean;
  roledata: string | undefined;
  userdata: UserDatatype[]
  openOptionIndex: number,
  closebox:boolean;
  noUserData:string;
  deletePopUp: boolean;
  deleteUserData: {
    id: string;
    attributes: {
      user_name: string,
      firm_name: string,
    };
  };
  openDialogAlert:boolean;
  showResponse:{
    error:string,
    success:string
  },
  navigationlawyer:string
  
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AdminLawyerManagementController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserProfileApiCallId:string="";
  DeleteUserLawyerApiCallId:string="";

  
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
     
      mobileOpen: false,
      opendialog:false,
      openOption:false,
      closeOption:false,
      roledata: "",
      userdata:[],
      openOptionIndex: -1,
      closebox:false,
      noUserData:'',
      deletePopUp:false,
      deleteUserData: {
        id: "",
        attributes: {
          user_name: "",
          firm_name: "",
        }
      },
      openDialogAlert:false,
      showResponse:{
        error:"",
        success:""
      },
      navigationlawyer:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getUserData()
    // Customizable Area End
  }

  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getUserProfileApiCallId) {
      if(responseJson?.data){
        this.setState({userdata:responseJson.data})
      }
      else if(responseJson?.message){
        this.setState({noUserData:responseJson.message})
      }
    }
    if (apiRequestCallId === this.DeleteUserLawyerApiCallId) {
      if(responseJson?.error){
        this.setState({openDialogAlert:true,showResponse:{error:responseJson.error,success:""}})
      }
      else if(responseJson?.message){
        this.setState({deletePopUp:false,openDialogAlert:true,showResponse:{error:"",success:responseJson.message}})
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }
  handleCloseDelete=()=>{
    this.setState({ deletePopUp: false });
  }
  handleOpenDelete=(data:UserDatatype)=>{
    this.setState({ deletePopUp: true,deleteUserData:{id:data.id,attributes:
      {user_name:data.attributes.associated_lawyer_details?.data.attributes.first_name,
      firm_name:data.attributes.associated_lawyer_details?.data.attributes.firm}} 
    });
  }
  handleEditOpen=(id:string)=>{
    setStorageData("edituser",id)
    const Editlawyer: Message = new Message(getName(MessageEnum.NavigationMessage));
    Editlawyer.addData(getName(MessageEnum.NavigationTargetMessage), "Editlawyer");
    Editlawyer.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(Editlawyer);
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });
  }
  
  handleClosePopUp=()=>{
    this.setState({ openOption: false });
  }
  handleClosePopUpALert=()=>{
    this.setState({ openDialogAlert: false });
    this.getUserData();
    this.handleAdminLawyerManagement();
  }
  handleopenDialogBox=()=>{
    this.setState({ opendialog: true });
  }
  handleBackNavigation = () => {
    this.props.navigation.goBack()
  }
  handleBack = async() => {
    let user_role = null;
    if (this.state.navigationlawyer=="lawyer") {
      user_role="lawyer";
    }
    let navigationTarget = 'DashBoard';
  
    if (user_role === 'lawyer') {
      navigationTarget = 'LawyerDashboard';
    }
    const message1: Message = new Message(getName(MessageEnum.NavigationMessage));
    message1.addData(getName(MessageEnum.NavigationTargetMessage),
    navigationTarget);
    message1.addData(getName(MessageEnum.NavigationPropsMessage),this.props);

    this.send(message1);
  }


  handleAdminLawyerManagement = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AdminLawyerManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleLawfirmManagment = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "lawfirmManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleServicesManagement = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ServicesManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleOpenOption = (index:any) => {
    this.setState(prevState => ({
      openOptionIndex: prevState.openOptionIndex === index ? null : index 
    }));
    this.setState({closebox:true})
  }
  handleCloseBox=()=>{
    if(this.state.closebox){
      this.setState({openOptionIndex:-1,closebox:false})
    }
  }
  
  handleCloseOption=()=>{
    if(this.state.closeOption){
        this.setState({openOption:false,closeOption:false})
    }
  }
  determineMainToken = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null;
  };
  handleDeleteUser=(id:string)=>{
    const header: { "Content-Type": string; token: string | undefined } = {
      "Content-Type": "application/json",
      "token": this.state.roledata,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.DeleteUserLawyerApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.PUTAPIDeleteEndPoint}${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getUserData = async () => {
    const signupTokens: string = await getStorageData("userdetails");
    const loginTokens: string = await getStorageData("token");
    const metaSignUpToken = JSON.parse(signupTokens);
    const metaLoginToken = JSON.parse(loginTokens);

    const maintoken = this.determineMainToken(metaSignUpToken, metaLoginToken);

    const header: { "Content-Type": string; token: string | undefined } = {
      "Content-Type": "application/json",
      "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({roledata:maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token})
  };

  // Customizable Area End
}
