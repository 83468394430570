import React  from "react";
import { ArrowDropDownRounded } from "@material-ui/icons";

import Select, {
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  IndicatorSeparatorProps,
  OptionProps,
  SingleValue,
  StylesConfig,
  components,
} from "react-select";
import { SelectOption } from "./SearchAndFilter.web";

type SelectProps = {
  placeholder: string;
  options: Array<SelectOption>;
  selectedOption: SelectOption | null;
  onSelect: (option: SelectOption) => void;
  menuStyle?: CSSObjectWithLabel;
  Option?: null | ((props: OptionProps<SelectOption>) => JSX.Element);
  DropdownIndicator?: (props: DropdownIndicatorProps<SelectOption, false>) => JSX.Element;
};

const getReactSelectStyles = (menuStyle?: CSSObjectWithLabel) => {
  const customStyles: StylesConfig<SelectOption, false> = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      border: "1px solid #C3D1FF",
      borderRadius: "8px",
      padding: "12px 16px",
      display: "flex",
      gap: "8px",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #C3D1FF",
      },
    }),

    singleValue: (base) => ({
      ...base,
      marginRight: "auto",
      ...commonFontStyle,
      color: "#262626",
      margin: 0,
    }),

    valueContainer: (base) => ({
      ...base,
      padding: 0,
      "& .react-select__input-container": {
        padding: 0,
        margin: 0,
      },
    }),

    placeholder: (base) => ({
      ...base,
      ...commonFontStyle,
      color: "#8C8C8C",
      margin: 0,
    }),

    input: (base) => ({
      ...base,
      ...commonFontStyle,
    }),

    dropdownIndicator: (base) => ({
      ...base,
      padding: "0",
    }),

    menu: (base) => ({
      ...base,
      margin: 0,
      border: "1px solid #F5F5F5",
      boxShadow: "0px 8px 32px 0px #01175F0F, 0px 4px 8px 0px #01175F08",
      borderRadius: 8,
      backgroundColor: "#FFFFFF",
      padding: "6px 0px",
      ...menuStyle,
    }),

    menuList: (base) => ({
      ...base,
      padding: 0,
      scrollbarWidth: "thin",
      scrollbarColor: "#355EE7 #FFFFFF",
      "&::-webkit-scrollbar": {
        width: 8,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#FFFFFF",
        borderRadius: 8,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#355EE7",
        borderRadius: 8,
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#355EE7",
      },
    }),

    option: (base) => ({
      ...base,
      ...commonFontStyle,
      display: "flex",
      gap: 8,
      alignItems: "center",
      color: "#3C3E49",
      cursor: "pointer",
      "&:hover, &:active, &.react-select__option--is-focused": {
        backgroundColor: "#FFFFFF",
      },
      '&[aria-selected="true"]': {
        backgroundColor: "#FFFFFF",
      },
    }),

    menuPortal: (base) => ({ ...base, zIndex: 1500 }),
    noOptionsMessage: (base) => ({...base,...commonFontStyle, opacity: 0.8})
  };

  return customStyles
}

const commonFontStyle: CSSObjectWithLabel = {
  color: "#0F172A",
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "24px",
  fontFamily: "DM Sans",
};


const IndicatorSeparator = ({
  innerProps,
}: IndicatorSeparatorProps<SelectOption, false>) => {
  return <span {...innerProps} />;
};

const CustomDropdownIndicator = (
  props: DropdownIndicatorProps<SelectOption, false>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownRounded htmlColor="#8C8C8C" />
    </components.DropdownIndicator>
  );
};

const CustomOption = (props: OptionProps<SelectOption>) => {
  return (
      <components.Option {...props} />
  );
};

const SelectDropdown = ({
  options,
  onSelect,
  selectedOption,
  placeholder,
  menuStyle,
  Option = CustomOption,
  DropdownIndicator = CustomDropdownIndicator
}: SelectProps) => {

  const handleChangeOption = (newOption: SingleValue<SelectOption>) => {
    if(newOption) {
      onSelect(newOption)
    }
  }

  return (
    <div style={{ position: "relative", flex: 1 }}>
      <Select
        placeholder={placeholder}
        options={options}
        isClearable={false}
        menuPosition="fixed"
        components={{ IndicatorSeparator, DropdownIndicator, Option: Option }}
        classNamePrefix="react-select"
        styles={getReactSelectStyles(menuStyle)}
        value={selectedOption}
        onChange={handleChangeOption}
      />
    </div>
  );
};

export default SelectDropdown
