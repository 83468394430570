import { IBlock } from "../../../framework/src/IBlock";
import { Platform,PermissionsAndroid  } from "react-native";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment, { Moment } from "moment";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import Papa from "papaparse";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Value } from "react-calendar/dist/cjs/shared/types";
import { setStorageData ,getStorageData } from "../../../framework/src/Utilities";

interface TaskData {
  user_name: string;
  profile_image: string | { url: string };
  state: string | null;
  status: string;
  title: string;
  created_at: string;
}

export interface TaskTimeline {
  Under_revsion?: string;
  Task_posted?: TaskData;
  Pending_client_approval?: TaskData;
  Task_assigned?: TaskData;
  Task_finished?: TaskData;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  anchorEl?: HTMLElement | null
  handleAuditPopoverClose: () => void
  taskId?: string
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  startDate: null | string;
  endDate: null | string;
  displayedDate: Moment | number;
  selectedStartDate?: null | string;
  selectedEndDate?: null | string;
  token: string;
  showCalender: boolean;
  showTable: boolean;
  auditTrailData: AuditTrailDataItem[];
  isCalender: boolean;
  auditTrailHeader: string[];
  isTable: boolean,
  pageNumber: number,
  openModal: boolean,
  isShowMore: boolean;
  auditData: TaskTimeline | null
  isAuditLoading: boolean

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export interface AuditTrailDataItem {
  // Customizable Area Start
  id: string;
  type: string;
  attributes: {
    action: string;
    account_id: null | string | number;
    auditable_type: string;
    auditable_id: null | string | number;
    new_data: { name: string };
    previous_data: { name: string };
    created_at: string;
    updated_at: string;
    account_holder_name: string;
    
  };
  // Customizable Area End
}
export default class AuditTrailController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAuditDataId = "";
  auditDataAPICallId:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area Start
    // Customizable Area End
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
    
      showTable: false,
      isCalender: false,
      pageNumber: 1,
      openModal: false,
      isShowMore: false,
      auditTrailData: [
        {
          id: "4",
          type: "audit",
          attributes: {
            action: "Testing",
            account_holder_name: 'abc',
            account_id: 1,
            auditable_type: "BxBlockCatalogue::Catalogue",
            auditable_id: 1,
            new_data: {
              name: "Fruite",
            },
            previous_data: {
              name: "Apple",
            },
            created_at: "2023-05-01T08:09:59.697Z",
            updated_at: "2023-05-01T08:09:59.697Z",
          },
        },
      ],
      auditTrailHeader: ["Action", "User", "Table", "Date & Time"],
      startDate: null,
      endDate: null,
      displayedDate: moment(),
      selectedStartDate: null,
      selectedEndDate: null,

      showCalender: false,
      isTable: false,
      auditData: null,
      isAuditLoading: false
      // Customizable Area End

    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    const response = await getStorageData("loginData")
      if (response) {
        this.setState({ token: response }, () => {
          this.getAuditDetail();
        })
      }

    this.getAuditData()
  }

  showPagination (length : number){
    if(length >= 10){
      this.setState({ isShowMore: true});
    }else{
      this.setState({ isShowMore: false});
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getAuditDataId && responseJson) {

        this.showPagination(responseJson.data.length)

        if (this.state.pageNumber === 1) {
         
          this.setState({ auditTrailData: responseJson.data });
        } else {
          if (responseJson.data.length > 0) {
            this.setState({
              auditTrailData: [...this.state.auditTrailData, ...responseJson.data]
            });
          }

        }

      }
      if (message.messageId === errorReponse) {
        this.parseApiErrorResponse(errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
      this.getAuditDataResponse(apiRequestCallId,responseJson)
    }
    // Customizable Area End
  }

  onDateChanges = (date?: Moment, type?: string) => {
    if (type === "END_DATE") {
      this.setState({
        ...this.state,
        selectedEndDate: moment(date).format("DD/MM/YYYY"),
        pageNumber: 1,
        isCalender: false,
      });
      this.getAuditDetail(
        this.state.selectedStartDate,
        moment(date).format("DD/MM/YYYY")
      );
    } else {
      this.setState({
        ...this.state,
        pageNumber: 1,
        selectedStartDate: moment(date).format("DD/MM/YYYY"),
        selectedEndDate: null,
      });
    }
  };

  //genrate csv file for mobile
  createCSV = async () => {
    const permissionGranted = await this.saveFileWithPermission()
    if (permissionGranted) {
      this.handleClose()
      const RNFS = require("react-native-fs");

      const dirPath =
        this.isPlatformAndroid()
          ? RNFS.DownloadDirectoryPath
          : RNFS.DocumentDirectoryPath;
      const filePath = `${dirPath}/data_${moment().format('YYYYMMDD_HHmmss')}.csv`;
      let userDetail = this.state.auditTrailData.reduce((values: string[], detail) => {
        interface ItemType { 'User'?: string,Action?: string, Table?: string, 'New Value'?: string, 'Old Value'?: string, 'Date & Time'?: string,  }
        let item: ItemType = {};
        item["Action"] = detail.attributes.action;
        item["New Value"] = detail.attributes.new_data.name;
        item["Old Value"] = detail.attributes.previous_data.name;
        item["Table"] = detail.attributes.auditable_type;
        item["User"] = detail.attributes.account_holder_name;
        item["Date & Time"] = detail.attributes.created_at;
      
        values.push(item as string);
        return values;
      }, []);
      // Check if directory exists, create it if not

      // Convert data to CSV format using papaparse
      
      const csvFormat = Papa.unparse(userDetail);
      // Write CSV data to file
      await RNFS.writeFile(filePath, csvFormat, "utf8");
      this.showAlert("", "File Downloaded Successfully.");
    } else {
      this.showAlert("", "Permission denied: Cannot save file.");
    }



  };

  //function to get audittrail data
  getAuditDetail = (startDates?: (string | null), endDates?: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token
    };
    let endPoint = "?page=" + this.state.pageNumber + "&per_page=10";
    if (startDates && endDates) {
      endPoint = endPoint + `&start_date=${startDates}&end_date=${endDates}`
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAuditData}${endPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    this.getAuditDataId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClear = () => {
    this.setState({ startDate: null, endDate: null, pageNumber: 1 });
    this.getAuditDetail();

  };
  // Customizable Area End

  // Customizable Area Start
  showTable = () => {
    this.setState({ showTable: !this.state.showTable });
  };

  handleDateClick = (date: Value) => {
    if (Array.isArray(date) && date.length > 0) {
      this.setState({
        startDate: moment(date[0]).format("DD-MM-YYYY"),
        endDate: moment(date[1]).format("DD-MM-YYYY"),
        pageNumber: 1
      });
      if (date[1]) {
        this.setState({ showCalender: !this.state.showCalender, pageNumber: 1 }, () => {
          this.getAuditDetail(
            moment(date[0]).format("DD-MM-YYYY"),
            moment(date[1]).format("DD-MM-YYYY")
          );
        });

      }
    }

  };


  saveFileWithPermission = async () => {
    if (Platform.OS == 'android') {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
        {
          title: 'Storage Permission',
          message: 'App needs access to your storage to save files.',
          buttonNeutral: 'Ask Me Later',
          buttonNegative: 'Cancel',
          buttonPositive: 'OK',
        }
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  handleClickOpen = () => {
    this.setState({ openModal: true })
  };

  handleClose = () => {
    this.setState({ openModal: false })
  };


  convertArrayToCSV = (detail: AuditTrailDataItem[]): string => {
    const csvRows: string[] = [];

    // Generate the header row
    const headers: string[] = [
      "Action",
      "New Value",
      "Old Value",
      "Table",
      "User",
      "Date & Time",
    ];

    csvRows.push(headers.join(","));

    // Generate the data rows
    detail.forEach((item) => {
      const {
        attributes: {
          action,
          auditable_type,
          new_data,
          previous_data,
          created_at,
          account_holder_name,
        },
      } = item;

      const newDataStr = new_data.name;
      const previousDataStr = previous_data.name;

      const rowDetail = [
        action,
        newDataStr,
        previousDataStr,
        auditable_type,
        account_holder_name,
        created_at,
      ].map((value) => `${value.toString()}`).join(",");

      csvRows.push(rowDetail);
    });

    const csvData: string = csvRows.join("\n");

    return csvData;
  };



  downloadCSV = (): void => {
    this.handleClose()
    const blobDetail: Blob = new Blob([this.convertArrayToCSV(this.state.auditTrailData)], { type: "text/csv;charset=utf-8;" });
    // For other browsers
    const link: HTMLAnchorElement = document.createElement("a");
    if (link.download !== undefined) {
      const urlLink: string = URL.createObjectURL(blobDetail);
      link.setAttribute("href", urlLink);
      link.setAttribute("download", `data_${moment().format('YYYYMMDD_HHmmss')}.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }
  };

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;
  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed = () => {
    let response = new Message(getName(MessageEnum.AccoutLoginSuccess));
    response.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(response);
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  openDate = () => {
    this.setState({ isCalender: !this.state.isCalender })
  }
  showInTable = () => {
    this.setState({ isTable: !this.state.isTable })
  }

  clearDate = () => {
    this.addState();
    this.setState({
      selectedStartDate: null,
      selectedEndDate: null,
    });
    this.getAuditDetail();
  }

  addState = () => {
    this.setState({
      startDate: "2017-10-07T00:00:00.000Z",
      endDate: "2017-12-07T00:00:00.000Z",
      auditTrailData: [],
    });
  }

  showCalenders = () => {
    this.setState({ showCalender: !this.state.showCalender })
  }

  showMore = () => {
    this.setState({ pageNumber: this.state.pageNumber + 1 }, () => {
      this.getAuditDetail();
    })
  }
  navigateToDetailPage = (auditID: number) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), "AuditTrailDetail");
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { auditID });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  navigateToWebDetailPage = async (auditID: number) => {
   await  setStorageData("auditID", auditID.toString());
   this.navigateToDetailPage(auditID)
  }

  getAuditData = async() => {
    if(!this.props.taskId){
      return
    }
    this.setState({isAuditLoading: true})
    const ctoken: string = await getStorageData("clientTokes");
    const header = {
      "token": ctoken,
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.auditDataAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.auditDataApiEndpoint}` + this.props.taskId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAuditDataResponse = (apiRequestCallId: string,responseJson: TaskTimeline) => {
    if(apiRequestCallId === this.auditDataAPICallId){
      if(responseJson){
        this.setState({auditData: responseJson,isAuditLoading:false})
      }
    }
  }

  // Customizable Area End
}
//Already up to date.
