import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";

interface IParam {
  onTabChange: (state: string) => void
}

const useStyles = makeStyles({
  group: {
    border: "1px solid #C3D1FF",
    padding: "4px",
    borderRadius: 12,
    display: "flex",
    gap: 12,
  },
  stateBtn: {
    all: "unset",
    cursor: "pointer",
    borderRadius: 8,
    boxSizing: "border-box",
    padding: 6,
    fontFamily: "DM Sans",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "22px",
    color: "#BFBFBF",
    '&[aria-selected="true"]': {
      color: "#FFFFFF",
      fontWeight: 700,
      backgroundColor: "#355EE7",
    },
  },
});

export default function PayoutStateTabGroup(params:IParam) {
  const classes = useStyles();
  const [selectedState, setSelectedState] = useState<
    "all" | "pending" | "digip" | "paid"
  >("all");

  const handleChangeState = (state: typeof selectedState) => () => {
    setSelectedState(state)
    params.onTabChange(state)
  };

  return (
    <div className={classes.group}>
      <button
        className={classes.stateBtn}
        onClick={handleChangeState("all")}
        aria-selected={selectedState === "all"}
      >
        All
      </button>
      <button
        className={classes.stateBtn}
        onClick={handleChangeState("pending")}
        aria-selected={selectedState === "pending"}
        data-test-id="pending_task_click"
      >
        Pending
      </button>
      <button
        className={classes.stateBtn}
        onClick={handleChangeState("digip")}
        aria-selected={selectedState === "digip"}
      >
        Digip
      </button>
      <button
        className={classes.stateBtn}
        onClick={handleChangeState("paid")}
        aria-selected={selectedState === "paid"}
      >
        Paid
      </button>
    </div>
  );
}
