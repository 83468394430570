import React, { useEffect, useRef, useState } from "react";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
  IconButton,
  Popover,
  PopoverOrigin,
} from "@material-ui/core";
import { SearchRounded } from "@material-ui/icons";
import { useDebounce } from "use-debounce";
import { DateObject } from "react-multi-date-picker";

import CalendarDropdown from "../../../advancedsearch/src/components/CalendarDropdown.web";
import SelectDropdown from "../../../advancedsearch/src/components/SelectDropdown.web";
import { SelectOption } from "../../../advancedsearch/src/components/SearchAndFilter.web";

const useStyles = makeStyles({
  searchAndFilterContainer: {
    display: "flex",
    gap: 6,
    flex: 1,
    boxSizing: "border-box",
    height: 44,
    "& *": {
      boxSizing: "border-box",
    },
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    flex: 1,
    borderRadius: 8,
    position: "relative",
    backgroundColor: "#FFFFFF",
    padding: "0px 8px",
  },
  srOnly: {
    position: "absolute",
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: "hidden",
    clip: "rect(0, 0, 0, 0)",
    whiteSpace: "nowrap",
    borderWidth: 0,
  },
  searchInput: {
    outline: "none",
    border: 0,
    padding: 0,
    fontFamily: "DM Sans",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px",
    color: "#434343",
    width: "100%",
    "&::placeholder": {
      color: "#BFBFBF",
    },
  },
  openFilterPopupBtn: {
    borderRadius: 12,
    padding: "6px 9px",
    backgroundColor: "#ECF1FF",
    "&:hover": {
      backgroundColor: "#ECF1FF",
    },
  },
  closeBtn: {
    padding: 0,
    position: "absolute",
    top: 8,
    right: 8,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  filterContainer: {
    display: "flex",
    gap: 12,
  },
  selectContainer: {
    position: "relative",
    "& .react-select__menu": {
      position: "absolute",
    },
  },
});

const searchAndFilterTheme = createTheme({
  overrides: {
    MuiPopover: {
      paper: {
        width: 500,
        maxWidth: 500,
        boxSizing: "border-box",
        border: "1px solid #F5F5F5",
        backgroundColor: "#FFFFFF",
        padding: 24,
      },
    },
    MuiPaper: {
      elevation8: {
        boxShadow: "0px 8px 32px 0px #01175F0F, 0px 4px 8px 0px #01175F08",
      },
      rounded: {
        borderRadius: 8,
      },
      root: {
        display: "flex",
        flexDirection: "column",
        gap: 12,
        marginTop: 4,
        "& > h3": {
          margin: 0,
          fontFamily: "DM Sans",
          fontWeight: 500,
          fontSize: 14,
          lineHeight: "22px",
          color: "#355EE7",
        },
      },
    },
  },
});

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "right",
};
const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const serviceOptions: Array<SelectOption> = [
  {
    label: "Trademark Registration",
    value: "Trademark Registration",
  },
  {
    label: "Trademark Monitoring",
    value: "Trademark Monitoring",
  },
  {
    label: "IP Litigation",
    value: "IP Litigation",
  },
  {
    label: "Licensing & Contracts",
    value: "Licensing & Contracts",
  },
  {
    label: "Cease & Desist Service",
    value: "Cease & Desist Service",
  },
];

type IDateValue = DateObject | null | string;

interface IParam {
  onInputChange: (value: string) => void
  legalServiceTypeList: {
    label: string;
    value: string;
  }[]
  onServiceTypeChange: (option: {
    label: string;
    value: string;
  }) => void
  onDateChange:(date: IDateValue) => void
  serviceQuery: {
    label: string;
    value: string;
  } | null
  dateObjectState: IDateValue
  dateObjectQuery: string
}

export default function PayoutSearchAndFilter({dateObjectQuery,dateObjectState,serviceQuery,onInputChange,legalServiceTypeList,onServiceTypeChange,onDateChange}:IParam) {
  const [currentDateObject, setCurrentDateObject] = useState<IDateValue>(
    null
  );
  const [service, setService] = useState<SelectOption | null>(null);
  const classes = useStyles();
  const [filterAnchorEL, setFilterAnchorEL] = useState<HTMLElement | null>(
    null
  );
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const isMountedRef = useRef(false);

  useEffect(() => {
    if (isMountedRef.current) {
        onInputChange(debouncedSearchText)
    }
    return () => {
      isMountedRef.current = true;
    };
  }, [debouncedSearchText]);

  useEffect(()=>{
    setService(serviceQuery)
  },[serviceQuery])


  useEffect(()=>{
    setCurrentDateObject(dateObjectState)
  },[dateObjectQuery])

  const handleOpenFilterPopup = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setFilterAnchorEL(event.currentTarget.parentElement);
  };

  const handleCloseFilterPopup = () => setFilterAnchorEL(null);

  const handleServiceChange = (option:{
    label: string;
    value: string;
  }) => {
    setService(option)
    onServiceTypeChange(option)
  }


  return (
    <ThemeProvider theme={searchAndFilterTheme}>
      <div className={classes.searchAndFilterContainer}>
        <div   style={{  border: "1px solid #C3D1FF",
            padding: "9px",
            borderRadius: 12,}} className={classes.searchContainer}>
          <SearchRounded htmlColor="#BFBFBF" />
          <label className={classes.srOnly} htmlFor="query">
            Search by lawyer, client or task name
          </label>
          <input
            type="text"
            className={classes.searchInput}
            placeholder="Search by lawyer, client or task name"
            id="query"
            name="query"
            data-test-id="search_task"
            value={searchText}
            onChange={(event) => {
              setSearchText(event.target.value)
            }}
          />
        </div>
        <IconButton
          className={classes.openFilterPopupBtn}
          onClick={handleOpenFilterPopup}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.1893 3.00781H2.81038C2.33186 3.00781 2.03304 3.5293 2.27327 3.94531L6.81624 11.668V16.3672C6.81624 16.7129 7.09358 16.9922 7.43733 16.9922H12.5623C12.9061 16.9922 13.1834 16.7129 13.1834 16.3672V11.668L17.7283 3.94531C17.9666 3.5293 17.6678 3.00781 17.1893 3.00781ZM11.785 15.5859H8.21468V12.5391H11.7869V15.5859H11.785ZM11.9725 10.9648L11.7869 11.2891H8.21272L8.02718 10.9648L4.15413 4.41406H15.8455L11.9725 10.9648Z"
              fill="#7192FF"
            />
          </svg>
          <span className={classes.srOnly}>Open filter popup</span>
        </IconButton>
      </div>
      <Popover
        open={Boolean(filterAnchorEL)}
        anchorEl={filterAnchorEL}
        onClose={handleCloseFilterPopup}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={{ id: "filter-popover" }}
      >
        <h3>FILTERS</h3>
        <div className={classes.filterContainer}>
          <SelectDropdown
            placeholder="Service Type"
            options={legalServiceTypeList}
            selectedOption={service}
            onSelect={(option) => handleServiceChange(option)}
          />
          <CalendarDropdown
            placeholder="Date"
            currentDateObject={currentDateObject as DateObject}
            onDateChange={(date:IDateValue) =>{ 
              setCurrentDateObject(date)
              onDateChange(date as DateObject)
            }}

          />
        </div>
      </Popover>
    </ThemeProvider>
  );
}
