export const group = require("../assets/group.png");
export const user = require("../assets/user.png");
export const date = require("../assets/date.png");
export const step1 = require("../assets/step1.png");
export const step2 = require("../assets/step2.png");
export const step3 = require("../assets/step3.png");
export const download = require("../assets/download.png");
export const digipIcon = require("../assets/digipIcon.png");
export const pending = require("../assets/pending.png");
export const pendingFill = require("../assets/pendingFill.png");
export const history = require("../assets/history.png");
export const historyFill = require("../assets/historyFill.png");
export const transactionId = require("../assets/transaction.png");
export const successIcon = require("../assets/success.png");
export const rejectIcon = require("../assets/reject.png");
export const transaction1 = require("../assets/transaction1.png");
export const transaction2 = require("../assets/transaction2.png");