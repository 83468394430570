// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

interface ApiData {
  contentType?: string,
  method?: string,
  endPoint?: string,
  body?: {},
  type?: string,
  token?: string
}

interface IPaymentInfo {
  task_id: number;
  status: string;
  transaction_id: string;
  invoice_number: string;
  issue_date: string;
  task_created_at: string
  provider: string;
  task_price: number;
  task_name: string;
  trademark_name: string;
  trademark_application_number: string;
  "invoice_url": string
}

interface IPendingTaskInfo {
  payment: {
      status: string;
      transaction_id: string;
      task_price: number;
      digip_fee_percent: string;
      digip_fee: string;
      amount_received: string;
  };
  task: {
      id: number;
      task_name: string;
      description: string;
      status: string;
      trademark_name: string;
      trademark_application_number: string;
      deadline: string;
      legel_service_type_id: number;
      jurisdiction: string;
      state: string;
      created_at: string;
      account_id: number;
      assigned_lawyer_ids: number[];
      client_id: number;
      parent_task_id: number;
      transaction_id: string;
      assigned_by_digip_admin: boolean;
  };
}

interface IPaymentTask {
    "task_id": number,
    "task_name": string,
    "date": string,
    "amount": number,
    "lawfirm": string,
    payment_id: number
}

interface ITaskError {
  "errors":[{"token":string}]
}

interface IHistoryTask {
    "task_id": number,
    "transaction_id": string,
    "date": string,
    "task_name": string,
    "amount": number,
    "status": string,
    payment_id: number
}

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  IsTaskLoading: boolean
  paymentTasks: IPaymentTask[]
  historyTasks: IHistoryTask[]
  taskId: string
  paymentId: string
  selectedTab: string
  currentStep: number 
  paymentStatus:string | null
  taskInfo: IPaymentInfo | null
  taskInfoLoading:boolean
  createPaymentLoading:boolean
  pendingTaskItem: IPaymentTask | null
  pendingTaskInfo: IPendingTaskInfo | null
  pendingTaskInfoLoading: boolean
  // Customizable Area End
}
interface SS {}

export default class PaymentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
    paymentTaskListAPICallId!: string
    historyTaskListAPICallId!: string
    paymentTaskInfoAPICallId!: string
    stripePaymentAPICallId!: string
    pendingTaskInfoAPICallId!:  string
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      IsTaskLoading: false,
      paymentTasks: [],
      taskId: "",
      selectedTab: "pending",
      historyTasks: [],
      currentStep: 0,
      paymentId: "",
      paymentStatus: "",
      taskInfo: null,
      taskInfoLoading:false,
      createPaymentLoading:false,
      pendingTaskItem: null,
      pendingTaskInfo: null,
      pendingTaskInfoLoading: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const queryParams = new URLSearchParams(window.location.search);
    const paymentStatus = queryParams.get("payment");
    const taskId = queryParams.get("taskId");
    const paymentId = queryParams.get("paymentId");
    this.setState({
      paymentStatus:paymentStatus,
      taskId:taskId ? taskId : "",
      paymentId:paymentId ? paymentId : "",
      selectedTab: paymentStatus ? "history" : "pending"
    },()=>{
      if(paymentStatus){
        this.getPaymentTaskList("history")
        this.getPaymentTaskInfo()
      }
    })
     this.getPaymentTaskList("pending")

    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
     
      this.getPaymentTaskListResponse(apiRequestCallId,responseJson)
      this.getHistoryTaskListResponse(apiRequestCallId,responseJson)
      this.getTaskInfoResponse(apiRequestCallId,responseJson)
      this.createStripePayResponse(apiRequestCallId,responseJson)
      this.getPendingTaskInfoResponse(apiRequestCallId,responseJson)

    }
    
    
    // Customizable Area End
  }

  apiCall = async (data: ApiData) => {
    const { contentType, method, endPoint, body, type,token } = data
    const header = {
      'Content-Type': contentType,
      'token': token ? token : ""
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  

  getPaymentTaskList = async(type:string) => {
    this.setState({IsTaskLoading:true})
    const clinetLoginTokens: string = await getStorageData("clientTokes");
    if(type === "pending"){
      this.paymentTaskListAPICallId = await this.apiCall({
        contentType: configJSON.apiContentType,
        token: clinetLoginTokens,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.paymentTaskListEndpoint + type
      });
    }else{
      this.historyTaskListAPICallId = await this.apiCall({
        contentType: configJSON.apiContentType,
        token: clinetLoginTokens,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.paymentTaskListEndpoint + type
      });
    }
  }

  getPaymentTaskListResponse = (apiRequestCallId:string,responseJson:IPaymentTask[] | ITaskError ) => {
    if(apiRequestCallId === this.paymentTaskListAPICallId){
      if("errors" in responseJson){
        toast.error(responseJson.errors[0].token)
        this.setState({paymentTasks:[] as IPaymentTask[],IsTaskLoading:false})
      }else if(responseJson){
        this.setState({paymentTasks:responseJson,IsTaskLoading:false})
      }
    }
  }

  getHistoryTaskListResponse = (apiRequestCallId:string,responseJson:IHistoryTask[] | ITaskError) => {
    if(apiRequestCallId === this.historyTaskListAPICallId){
      if("errors" in responseJson){
        toast.error(responseJson.errors[0].token)
        this.setState({historyTasks:[] as IHistoryTask[],IsTaskLoading:false})
      }else if(responseJson){
        this.setState({historyTasks:responseJson,IsTaskLoading:false})
      }

    }
  }

  handleClickTaskRow = (taskIdVal:string,paymentId:string) => {
    this.setState({taskId: taskIdVal,paymentId:paymentId},()=>{
      this.getPaymentTaskInfo()
    })
  }


  handleClickPendingTaskRow = (taskItem:IPaymentTask) => {
    this.setState({currentStep:0, pendingTaskItem:taskItem,taskId:String(taskItem.task_id),paymentId: String(taskItem.payment_id)},()=>{
      this.getPendingTaskInfo()
    })
  }

  getPendingTaskInfo = async() => {
    this.setState({pendingTaskInfoLoading: true})
    const clinetLoginTokens: string = await getStorageData("clientTokes");
      this.pendingTaskInfoAPICallId = await this.apiCall({
        contentType: configJSON.apiContentType,
        token: clinetLoginTokens,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.pendingTaskInfoEndpoint + this.state.taskId
      });
  }

  getPendingTaskInfoResponse = (apiRequestCallId:string,responseJson:IPendingTaskInfo) => {
    if(apiRequestCallId === this.pendingTaskInfoAPICallId){
      if(responseJson){
        this.setState({pendingTaskInfo:responseJson,pendingTaskInfoLoading:false})
      }
    }
  }

  handleChangeTab = (tabName:string) => {
    this.setState({taskId:"",paymentId:"", selectedTab:tabName},() => {
      this.getPaymentTaskList(tabName)
    })
  
  }
  
  handleContinue = () => {
    this.setState({currentStep:1})
  }

  getPaymentTaskInfo = async() => {
    this.setState({taskInfoLoading: true})
    const clinetLoginTokens: string = await getStorageData("clientTokes");
      this.paymentTaskInfoAPICallId = await this.apiCall({
        contentType: configJSON.apiContentType,
        token: clinetLoginTokens,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.paymentTaskInfoEndpoint + this.state.paymentId
      });
  }

  getTaskInfoResponse = (apiRequestCallId:string,responseJson:IPaymentInfo) => {
    if(apiRequestCallId === this.paymentTaskInfoAPICallId){
      if(responseJson){
        this.setState({taskInfo:responseJson,taskInfoLoading:false})
      }
    }
  }

  handleRedirectStripPay = async() => {
    this.setState({createPaymentLoading: true})
    const {pendingTaskInfo} = this.state
    const clinetLoginTokens: string = await getStorageData("clientTokes");
      const bodyVal = {
        "amount": pendingTaskInfo?.payment.task_price,
        "task_id": this.state.taskId
      }
    this.stripePaymentAPICallId = await this.apiCall({
      body: bodyVal,
      contentType: configJSON.apiContentType,
      token: clinetLoginTokens,
      method: configJSON.confirmPaymentMethod,
      endPoint: configJSON.stripeCreatePaymentEndpoint
    });
  }

  createStripePayResponse = (apiRequestCallId:string,responseJson:{id:string,url:string}) => {
    if(apiRequestCallId === this.stripePaymentAPICallId){
      if(responseJson){
        this.setState({createPaymentLoading:false})
        window.location.href = responseJson.url
      }
    }
  }

  handleClose = () => {
    this.setState({paymentStatus:null})
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Payment");   
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  // Customizable Area End
}