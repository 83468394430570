// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.textApiContentType = "text/plain";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CollectTransactionFees";
exports.labelBodyText = "CollectTransactionFees Body";
exports.collectTransactionFeesEndPoint = "/bx_block_collecttransactionfees/transaction_fees";
exports.transactionFeesEndPoint = "/bx_block_collecttransactionfees/percentage_index";
exports.actualAmountEndPoint = "/account_block/calulate_transaction_fees";
exports.formDataMethod = "multipart/form-data";
exports.btnExampleTitle = "CLICK ME";
exports.invoiceTaskEndpoint = "/bx_block_payment_admin/payment_admin/lawfirm_invoice_tasks"
exports.payoutOverviewEndpoint = "/bx_block_payment_admin/payment_admin/payout_overview"
exports.invoiceTaskDetailsEndpoint = "/bx_block_payment_admin/payment_admin/show?task_id="
exports.legalServiceTypeListEndpoint = "/bx_block_productdescription/service_managements/legal_services_list"
exports.getFeePercentEndPoint = "/bx_block_collecttransactionfees/transaction_fees/fee_percent_of_lawfirm"

// Customizable Area End
