import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { user2,group,userClient1,userClient2,userClient3,tableUser,Services} from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export interface LegalServiceType {
  id: number,
  name: string
}

export interface TaskProfile { isSelected: boolean;
  id: string;
  attributes: {  task_name: string;
    legel_service_type: any; trademark_name: string;
    trademark_application_number: string; state: string;
    status: string;
    id: string;   account_id: number;
    name: string;
    created_at: Date;
    updated_at: Date;
    tasks: ITask[];
    deadline:string;
    message_count:number
  };
}
export type Status = {
  id: number; name: "to_do" | "in_progress" | "complete";
};

export type Priority = {
  id: number; name: "low" | "medium" | "high";
};

export interface IAccount {
  isSelected: boolean;
  id: string;
  type: "account";
  attributes: { first_name: string;
    last_name: string;};
}



export interface ServicesDataType{
  id: string,
  legal_service_name: string, legel_service_description: string|null,
  created_at: string,
  updated_at: string
}

export interface IGroupAccount {
  id: number;
  type: "account"; first_name: string;
  last_name: string;
}

export interface IGroup {
  id: string; type: "group";
  attributes: {
    name: string;
    accounts: IGroupAccount[];
  };
}

export interface ITask {
  isSelected: boolean; id: string;
  attributes: {
    id: number;
    account_id: number;
    title: string;
    description: string;
    status: Status["name"];
    priority: Priority["name"];
    created_at: Date;
    updated_at: Date;
    assigned_to: IAccount | IGroup;
  };
}


interface Data{
  name: string,
  value: number 
}
interface TableData{
  id: string,
  lawfirm: string,
  task: string,
  amount: string,
  status: string,
  background: string,
  color: string,
  img:string
}
export interface CountryDatatype {
  id: string;
  type: string;
  attributes: {
    name: string;
  };
}

export interface LegalServices{
  id: string,
  legal_service_name: string,
  legel_service_description: string|null,
  created_at: string,
  updated_at: string
}

export interface DataLegal{
  value:string,
  label:string,
  id:string,
}

export interface ServicesDataType{
  id: string,
  legal_service_name: string,
  legel_service_description: string|null,
  created_at: string,
  updated_at: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  Data:Data,
  MainData:Data[],
  COLORS:[string,string,string,string],
  TableData:TableData
  rows:TableData[]
  isArrowUp:boolean
  taskProfile:TaskProfile[],
  loading:boolean
  drawerOpen:boolean
  taskViewId:string
  selectedLawyers: string
  taskView:{
      id: number;
      attributes: {
        task_name: string;
        legel_service_type: LegalServiceType;
        trademark_name: string;
        trademark_application_number: string;
        state: string;
        status: string;
        jurisdiction:string,
        deadline:string,
        description:string
        created_at:string,
        request: {
  
          data: {
            id: string,
            type: string,
            attributes: {
              price: string,
            }
          }
        }
      };
    },
  isTaskPosted:boolean
  isInprogress:boolean
  isArrowUp2:boolean
  isArrowUp3:boolean
  countryData:any,
    legalServiceType:{
      value:string,
      label:string,
      id:string,
    }[],
    filteredData:any,
    filteredDataLegalService:any,
    countryDataInputValue:any,
    countryDataInputValueStack:any,
    legalServiceDataInputValue:string,
    ratingDataInputValue:string,
    priceDataInputValue:string,
    openFilterPopUp:boolean,
    Error:any,
    intalSlideBarValue:any,
    finalSlideBarValue:any,
    SliderBarValue:any,
    servicesData:any,
    optionsShow:any,
    ratingOptions:any
    ratingInputValue:number
    legalServiceDataInputValueStack:any,
    filterResult:any
    recentMessages: [
     { task_name: string;
  user_name: string;
  profile: string;
  last_message: string;}
    ],
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ClientDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getServiceApiCallId:string = "";
  getCountryApiCallId:string="";
  getAdvanceSearchApiCallId:string="";
  getTaskListsApiCallId:string = "";
  getTasksApiCallId:string ="";
  assignLawyerCallId:string="";
  getTaskOverviewCallID:string="";
  getRecentMessageCallID:string=""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      legalServiceType:[],
      countryData:[],
      Data:{
        name:"",
        value:0
      },
      MainData:[],
      COLORS:["","","",""],
      TableData:{
        id: '',
        lawfirm: '',
        task: '',
        amount: '',
        status: '',
        background: '',
        color: '',
        img:''
      },
      rows:[],
      isArrowUp:false,
      isArrowUp2:false,
      isArrowUp3:false,
      filteredData:[],
      filteredDataLegalService:[],
      countryDataInputValue:'',
      countryDataInputValueStack:[],
      legalServiceDataInputValue:'',
      legalServiceDataInputValueStack:[],
      ratingDataInputValue:'',
      priceDataInputValue:'',
      openFilterPopUp:false,
      intalSlideBarValue:10,
      finalSlideBarValue:20,
      SliderBarValue:0,
      Error:{
        isError:'Fill all mandatory fileds to continue',
        legalServices:'',
        jurisdiction:'',
        rating:'',
        price:'',
    },
    servicesData:[],
    optionsShow:[],
    ratingOptions:[{id:1,rating:1},{id:2,rating:2},{id:3,rating:4},{id:4,rating:4},{id:5,rating:5}],
    ratingInputValue:0,
    filterResult:{},
     taskProfile:[],
     loading:true,
     drawerOpen:false,
     taskViewId:'',
     selectedLawyers:'',
     isTaskPosted:false,
     taskView:{
      id:1,
      attributes: {
        task_name: "",
        legel_service_type: {
          id:1,
          name:""
        },
        trademark_name: "",
        trademark_application_number: "",
        state: "",
        status: "",
        jurisdiction:"" ,
        deadline:"",
        description:"",
        created_at:"",
        request: {

          data: {
            id: "",
            type: "",
            attributes: {
              price: "",
            }
          }
        }
          }
      },
      isInprogress:false,
      recentMessages: [
        {
            task_name: "",
            user_name: "",
            profile: "",
            last_message: ""
        }
    ],
    }

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleTaskApiCallID = (apiRequestCallId:any, responseJson:any)=>{
    
      if (apiRequestCallId === this.getTaskListsApiCallId) {
        if (responseJson?.data?.length > 0) {
          this.setState({ taskProfile: responseJson.data, loading: false });
        } else {
          this.setState({ taskProfile: [] });
        }
      }
    
  }

  handleResponceTask =(apiRequestCallId:any, responseJson:any)=>{
    if (apiRequestCallId === this.getTasksApiCallId) {
      if (responseJson?.data) {
        const status = responseJson.data.attributes.status;
        const state = responseJson.data.attributes.state;
        this.setState({ 
          taskView: responseJson.data,
          isTaskPosted: status === "pending_client_approval",
          isInprogress:state ==="in_progress"
        });
      } else {
        this.setState({ isTaskPosted: false });
      }
    }
  }

  handleDocumentFolderNavigation = (taskId:string|number) =>{
    sessionStorage.setItem("taskID", `${taskId}`)
    const navMsg = new Message(getName(MessageEnum.NavigationMessage));
    navMsg.addData(getName(MessageEnum.NavigationTargetMessage),
      "Documentation");
      navMsg.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(navMsg);
  }

  handleTaskOverviewResponce =(apiRequestCallId:any, responseJson:any)=>{
    if (apiRequestCallId === this.getTaskOverviewCallID) {
      const completedTask = responseJson.data.total_task_count - 
      (responseJson.data.active_task_count + 
       responseJson.data.overdue_task_count + 
       responseJson.data.rejected_task_count);

      this.setState({
        MainData: [
          { name: "Comleted", value: completedTask },
          { name: "Active", value: responseJson.data.active_task_count },
          { name: "Overdue", value: responseJson.data.overdue_task_count },
          { name: "Rejected", value: responseJson.data.rejected_task_count },
        ],
        
      })
    }
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived", message);
     if (apiRequestCallId === this.getCountryApiCallId) {
      this.setState({countryData:[...responseJson?.data]})
      
     }
     if (apiRequestCallId === this.getServiceApiCallId) {
      this.setState({servicesData:responseJson})
      this.reciveLegalServiceData(responseJson)
      
     }
     this.handleTaskOverviewResponce(apiRequestCallId, responseJson)

     
     if (apiRequestCallId === this.getRecentMessageCallID) {
      this.setState({
        recentMessages:responseJson
      })
     }
     
     if (apiRequestCallId === this.getAdvanceSearchApiCallId) {
        this.handleAdvanceSearchResponse(responseJson)
     }

     this.handleTaskApiCallID(apiRequestCallId,responseJson)
    if (apiRequestCallId === this.assignLawyerCallId) {
      if (responseJson) {
        this.setState({ selectedLawyers: responseJson.data });
      }
    }
    this.handleResponceTask(apiRequestCallId, responseJson)
    }
    
    // Customizable Area End
  }

  // Customizable Area Start

  handleAdvanceSearchResponse = async (responseJson:any)=>{
    if (responseJson) {
      this.setState({filterResult:responseJson ? responseJson : {}})
      if(Object.keys(responseJson).length > 0){
        this.handleNavigationProject1();
      }
    }
    else {
      this.handleClosePopUp()
    }
  }

  reciveLegalServiceData=async (responseJson:any)=>{
    if(responseJson){
      let user: DataLegal[] = await Promise.all(
        responseJson.map(async (e:ServicesDataType) => ({
          id: e.id,
          label: e.legal_service_name,
          value: e.legal_service_name,
        }))
      );
        this.setState({optionsShow:user})
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.setState({
      MainData: [
        { name: "Comleted", value: 0 },
        { name: "Active", value: 0},
        { name: "Overdue", value: 0 },
        { name: "Rejected", value: 0 },
      ],
      COLORS: ["#355EE7", "#C3D1FF", "#FFD7B9", "#FEE2E2"],
      rows: [
        {
          id: '#08978',
          lawfirm: 'Basant Osama',
          task: 'Family Law Task',
          amount: '$500',
          status: 'pending',
          background: '#F5F5F5',
          color: '#8C8C8C',
          img: user2
        },
        {
          id: '#08979',
          lawfirm: 'Ali Hassan',
          task: 'Family Law Task',
          amount: '$500',
          status: 'paid',
          background: '#D1FAE5',
          color: '#059669',
          img: tableUser
        },
        {
          id: '#08980',
          lawfirm: 'Basant Osama',
          task: 'Family Law Task',
          amount: '$500',
          status: 'overdue',
          background: '#FEE2E2',
          color: '#F87171',
          img: user2
        },
      ],
    })
    this.handlesliderbar();
      this.clearStorageBeforeExtractingToken();
    this.getCountryList();
    this.getServicesList();
    this.getTaskLists();
    this.getTaskOverView();
    this.getRecentMessage();
  }
  clearStorageBeforeExtractingToken = () => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
  
    if (token) {
      localStorage.clear();
      setStorageData('clientTokes', token);
    }
  };
  toggleDrawer = (task: { id: string }) => {
    const taskId = String(task.id);
    sessionStorage.setItem("TaskID" , taskId)
    this.setState(prevState => ({  drawerOpen: !prevState.drawerOpen, taskViewId: taskId}));
    this.getTasks()
    this.assignLawyer()
  };

  handleRedirectLiveChat = async() => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LiveChat");   
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  toggleDrawerClose = () => {
    this.setState({ drawerOpen: false }); 
  };

    getTasks = async () => {
      const ctoken: string = await getStorageData("clientTokes");
  
      const header = {
        "token": ctoken,
      "Content-Type": "application/json",
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getTasksApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_projecttemplates/project_templates/${this.state.taskViewId}`
      );
   
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    assignLawyer = async () => {
      const ctoken: string = await getStorageData("clientTokes");
      const header = {
        "token": ctoken,
      "Content-Type": "application/json",
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.assignLawyerCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_joblisting/jobs/task_assigned_lawyers?project_template_id=${this.state.taskViewId}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getTaskOverView = () => {
      const ctoken: any =localStorage.getItem('clientTokes');
  
      const header = {
        "token": ctoken,
      "Content-Type": "application/json",
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getTaskOverviewCallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_dashboard/clients_dashboard/task_overview`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getRecentMessage = () => {
      const ctoken: any =localStorage.getItem('clientTokes');
  
      const header = {
        "token": ctoken,
      "Content-Type": "application/json",
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getRecentMessageCallID = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_dashboard/clients_dashboard/client_recent_messages`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

  getTaskLists = () => {
    const ctoken: any =localStorage.getItem('clientTokes');

    const header = {
      "token": ctoken,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tasks/task_lists/client_task_lists`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  callFilter = async ()=>{
    if (this.state.legalServiceDataInputValueStack.length === 0) {
      this.setState((prev) => {
        return {
          ...prev,
          Error: {
            ...prev.Error,
            legalServices: "Select legal service type",
          }
        };
      })
    }
    if (this.state.countryDataInputValueStack.length === 0) {
      this.setState((prev) => {
        return {
          ...prev,
          Error: {
            ...prev.Error,
            jurisdiction: "Select jurisdiction to Apply Filter",
          }
        };
      })
    }
    if (Number(this.state.ratingInputValue) === 0) {
      this.setState((prev) => {
        return {
          ...prev,
          Error: {
            ...prev.Error,
            rating: 'Select Rating for apply filter'
          }
        };
      })
    }

    if (this.state.intalSlideBarValue == 0) { this.setState((prev) => 
      {
        return {
          ...prev,
          Error: { ...prev.Error,
            price: 'Select price to apply filter'
          }
        };
      })
    }
  }
  handleApplyFilter = async () => {
  
    await this.callFilter();

    !this.state.Error.legalServices &&
    !this.state.Error.jurisdiction &&
    !this.state.Error.rating &&
    !this.state.Error.price &&
    this.getAdvanceSearchFilteredValue();
  }

  handleNavigationProject1 = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "AdvancedSearch");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
      advanceSearchFilterData: {msg:this.state.filterResult},
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  
    this.send(message);
  }
  
  handlesliderbar = () => {
    this.setState({ SliderBarValue: this.state.intalSlideBarValue })
  }
  handleChangesliderbar = (event: any) => {
    this.setState((prev) => {
      return {
        SliderBarValue: event.target.value, intalSlideBarValue: event.target.value, Error: { ...prev.Error, price: '' }
      }
    })
  }

  handleClosePopUp = () =>{
    this.setState({ openFilterPopUp: !this.state.openFilterPopUp,isArrowUp:false,isArrowUp2:false,isArrowUp3:false,
      countryDataInputValue:'', legalServiceDataInputValue:'',
      ratingDataInputValue:'',
      priceDataInputValue:'',
      Error: {
        isError:'Fill All Mandtory fileds to Contiune',
        jurisdiction: "",
        legalServices: "",
        rating: "",
        price: "",
    },
    countryDataInputValueStack:[]

     });
  }

  handleSelectedCountry = (value:any) =>{
    const filteredData = this.state.countryData.filter((item: any) =>
      item.attributes.name.toLowerCase().includes(value.attributes.name.toLowerCase())
    );
    
    this.setState((prev) => {
      const newCountryDataInputValueStack = prev.countryDataInputValueStack.includes(value)
        ? prev.countryDataInputValueStack 
        : [...prev.countryDataInputValueStack, value]; 
    
      return {
        ...prev,
        filteredData,
        countryDataInputValue: value.attributes.name,
        countryDataInputValueStack: newCountryDataInputValueStack,
        isArrowUp2: false,
        Error: {
          ...prev.Error,
          jurisdiction: "",
        },
      };
    });

  }
  handleSelectedLegalService = (value: any) => {
    const filteredDataLegalService = this.state.optionsShow.filter((item: any) =>
      item.label.toLowerCase().includes(value.label.toLowerCase())
    );

    this.setState((prev) => {
      const existsInStack = prev.legalServiceDataInputValueStack.some(
        (item: any) => item.label === value.label
      );

      const newStack = existsInStack
        ? prev.legalServiceDataInputValueStack
        : [...prev.legalServiceDataInputValueStack, value];

      return {
        ...prev,
        filteredDataLegalService,
        legalServiceDataInputValue: value.label,
        legalServiceDataInputValueStack: newStack,
        isArrowUp: false,
        Error: {
          ...prev.Error,
          legalServices: "",
        },
      };
    });
  }
  handleSelectedrating = (value: string) => {
    this.setState((prev: any) => {
      return {
        ...prev,
        ratingInputValue: value,
        isArrowUp3: false,
        Error: {
          ...prev.Error,
          rating: "",
        }
      }
    });

  }

  handleRatingCancel = () => {
    this.setState({ ratingInputValue: 0 });
  }

  handleCancelSelectedCountry = (value: any) => {

    const res = this.state.countryDataInputValueStack.filter((item: any) => item.attributes.emoji_flag !== value);
    this.setState({ countryDataInputValueStack: [...res] })
  }

  handleCancelLegalServices = (value: any) => {

    const res = this.state.legalServiceDataInputValueStack.filter((item: any) => item.label !== value);
    this.setState({ legalServiceDataInputValueStack: [...res] })
  }

  handleInputChange = (event: any) => {
    const inputValue = event.target.value;
    const filteredData = this.state.countryData.filter((item:any) =>
      item.attributes.name.toLowerCase().includes(inputValue.toLowerCase())
    );

    this.setState({ filteredData, countryDataInputValue: inputValue });
  };

  handleInputChangeLegalService = (event: any) => {
    const inputValue = event.target.value;
    const filteredDataLegalService = this.state.optionsShow.filter((item: any) =>
      item.label.toLowerCase().includes(inputValue.toLowerCase())
    );

    this.setState({ filteredDataLegalService, legalServiceDataInputValue: inputValue });
  };

  toggleArr = () => {
    this.setState({ isArrowUp: !this.state.isArrowUp,isArrowUp2:false,isArrowUp3:false })
  }
  toggleArr2 = () => {
    this.setState({ isArrowUp2: !this.state.isArrowUp2,isArrowUp:false,isArrowUp3:false })
  }
  toggleArr3 = () => {
    this.setState({ isArrowUp3: !this.state.isArrowUp3,isArrowUp:false,isArrowUp2:false })
  }
  handleFinalValue=(event:any) => {
    this.setState({ finalSlideBarValue: event.target.value });
  }

   extractTokenAndSave = () => {
    localStorage.clear();
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');   
          setStorageData('clientTokes', token);
  };

  handleNavigation = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "TaskList");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }
  handleNavigationProject = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "projectTemplates");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }

  getCountryList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCountryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/get_countries"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getServicesList= async () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServiceApiCallId = requestMessage.messageId;

    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_productdescription/service_managements/legal_services_list"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAdvanceSearchFilteredValue = async () =>{
    const loginTokens: string = await getStorageData("clientTokes");
    
    const header = {
      "Content-Type": "application/json",
      "token":loginTokens+""
    };

    const legalId = this.state.legalServiceDataInputValueStack.map((x:any)=>x.id).join(",");
    const jurisdictionlId = this.state.countryDataInputValueStack.map((x:any)=>x.attributes.name).join(",")

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAdvanceSearchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/search/filter?legel_service_type_ids[]=${legalId}&jurisdictions[]=${jurisdictionlId}&price=${this.state.SliderBarValue}&rating=${this.state.ratingInputValue}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  // Customizable Area End
}
