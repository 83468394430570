import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { IPayoutPreview } from "../CollectTransactionFeesController";

const useStyles = makeStyles({
    payoutContainer: {
        backgroundColor: "#FFFFFF",
        padding: 24,
        borderRadius: 8,
        gap: 24,
        boxSizing: "border-box",
        border: "1px solid #F0F0F0",
        '& p': {
            margin: 0
        },
    },
    title: {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "20.83px",
        color: "#262626"
    },
    amount: {
        fontFamily: "DM Sans",
        fontWeight: 500,
        color: "#262626",
        fontSize: 26,
        lineHeight: "31.2px",
    },
    progress: {
        height: 6,
        backgroundColor: "#DCECFF",
        borderRadius: 10,
        position: "relative",
        marginTop: 12,
    },
    progressbar: {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 2,
        height: "100%",
        backgroundColor: "#355EE7",
        borderRadius: 10,
    },
    badge: {
        width: 62,
        textAlign: "center",
        padding: "2px 0px",
        backgroundColor: "#355EE7",
        borderRadius: 40,
        fontFamily: "DM Sans",
        fontWeight: 500,
        fontSize: 10,
        lineHeight: "12px",
    },
    badgeColor: {
        '&[data-state="paid"]': {
            backgroundColor: "#355EE7",
            color: "#FFFFFF",
        },
        '&[data-state="digip"]': {
            backgroundColor: "#FEF3C7",
            color: "#D97706"
        },
        '&[data-state="pending"]': {
            backgroundColor: "#DCECFF",
            color: "#355EE7"
        },
    },
    circle: {
        width: 10,
        height: 10,
        borderRadius: 30
    },
    task: {
        margin: 0,
        fontFamily: "DM Sans",
        fontWeight: 400,
        color: "#434343",
        fontSize: 12,
        lineHeight: "15.62px",
    },
})

interface IParam {
    payoutOverviewDetails: IPayoutPreview
}
  

export default function PayoutOverview(param:IParam) {
    const classes = useStyles()
    const {payoutOverviewDetails} = param
    const maxAmount = 100000;
    const totalAmount = payoutOverviewDetails.total_amount;
    const progressPercentage = Math.min((totalAmount / maxAmount) * 100, 100);
    return (
        <div className={`${classes.payoutContainer} flex flex-column`} >
            <div className="flex items-center" style={{gap: 12}} >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188ZM14.7586 6.92344C14.3859 6.84141 13.9523 6.79688 13.4625 6.79688C10.9617 6.79688 9.26953 8.10234 8.80781 10.3102H8.0625C7.95937 10.3102 7.875 10.3945 7.875 10.4977V11.1352C7.875 11.2383 7.95937 11.3227 8.0625 11.3227H8.68125C8.67422 11.4187 8.67422 11.5195 8.67422 11.6227V12.4875H8.0625C7.95937 12.4875 7.875 12.5719 7.875 12.675V13.3125C7.875 13.4156 7.95937 13.5 8.0625 13.5H8.77031C9.17344 15.825 10.8891 17.2031 13.4625 17.2031C13.9523 17.2031 14.3859 17.1633 14.7586 17.0836C14.8453 17.0648 14.9086 16.9898 14.9086 16.9008V15.8977C14.9086 15.7805 14.8008 15.6914 14.6859 15.7148C14.3414 15.7805 13.9383 15.8109 13.4719 15.8109C11.8664 15.8109 10.7883 14.9531 10.4297 13.5H13.4906C13.5937 13.5 13.6781 13.4156 13.6781 13.3125V12.675C13.6781 12.5719 13.5937 12.4875 13.4906 12.4875H10.2938V11.6437C10.2938 11.5336 10.2937 11.4234 10.3008 11.3203H13.4859C13.5891 11.3203 13.6734 11.2359 13.6734 11.1328V10.4953C13.6734 10.3922 13.5891 10.3078 13.4859 10.3078H10.4789C10.882 8.97422 11.9391 8.18906 13.4695 8.18906C13.9359 8.18906 14.3391 8.22422 14.6813 8.29219C14.7084 8.29773 14.7365 8.29718 14.7634 8.29058C14.7903 8.28398 14.8155 8.27148 14.837 8.254C14.8585 8.23651 14.8759 8.21447 14.8879 8.18946C14.8998 8.16446 14.9061 8.1371 14.9062 8.10938V7.10625C14.9062 7.01719 14.8453 6.94219 14.7586 6.92344Z" fill="#9AB1FF"/>
                </svg>
                <Typography variant="h3" component="h3" className={classes.title} >Payout Overview</Typography>
            </div>
            <div className="flex flex-column">
                <Typography variant="h3" component="p" style={{fontSize: 12,lineHeight: "14.4px"}} >Total amount</Typography>
                <p className={classes.amount} >
                     &euro; {payoutOverviewDetails.total_amount.toFixed(2)} EUR
                </p>
                <div role="progressbar" className={classes.progress} >
                    <div className={classes.progressbar}
                    style={{ width: `${progressPercentage}%` }}
                     />
                </div>
            </div>
            <div className="flex flex-column" style={{gap: 6}} >
                <div className="flex items-center" style={{gap: 8}} >
                    <div className={`${classes.circle} ${classes.badgeColor}`} data-state="paid" />
                    <p className={classes.task} >&euro; {payoutOverviewDetails.paid_amount.toFixed(2)}</p>
                    <div className={`${classes.badge} ${classes.badgeColor}`} data-state="paid" >Paid</div>
                    <p className={classes.task} style={{marginLeft: "auto", color: "#8C8C8C"}} >
                        {payoutOverviewDetails.paid_tasks_count}{" "}
                        {payoutOverviewDetails.paid_tasks_count <= 1 ? "task" : "tasks"}
                    </p>
                </div>
                <div className="flex items-center" style={{gap: 8}} >
                    <div className={`${classes.circle} ${classes.badgeColor}`} data-state="digip" />
                    <p className={classes.task} >&euro; {payoutOverviewDetails.paid_to_digip_amount.toFixed(2)}</p>
                    <div className={`${classes.badge} ${classes.badgeColor}`} data-state="digip" >Digip</div>
                    <p className={classes.task} style={{marginLeft: "auto", color: "#8C8C8C"}} >
                        {payoutOverviewDetails.paid_to_digip_tasks_count}{" "}
                        {payoutOverviewDetails.paid_to_digip_tasks_count <= 1 ? "task" : "tasks"}
                    </p>
                </div>
                <div className="flex items-center" style={{gap: 8}} >
                    <div className={`${classes.circle} ${classes.badgeColor}`} data-state="pending" />
                    <p className={classes.task} >&euro; {payoutOverviewDetails.pending_amount.toFixed(2)}</p>
                    <div className={`${classes.badge} ${classes.badgeColor}`} data-state="pending" >pending</div>
                    <p className={classes.task} style={{marginLeft: "auto", color: "#8C8C8C"}} >

                        {payoutOverviewDetails.pending_tasks_count}{" "}
                        {payoutOverviewDetails.pending_tasks_count <= 1 ? "task" : "tasks"}
                    </p>
                </div>
            </div>
        </div>
    )
}

