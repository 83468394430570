Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.patchApiMethod = "PATCH";
exports.deleteApiMethod = "DELETE";
exports.todoListApi = "bx_block_tasks/task_to_dos?project_template_id="
exports.serviseListApi ="bx_block_productdescription/service_managements/legal_services_list"
exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksListApiEndPoint = "bx_block_tasks/task_lists/lawyer_task_lists";
exports.todoListApilist = "bx_block_tasks/task_to_dos?project_template_id=";
exports.tasksApiEndPoint = "bx_block_projecttemplates/project_templates";
exports.tasksApiLawfirmEndPoint = "bx_block_tasks/task_lists/lawfirm_task_lists";
exports.clientListApiEndPoint = "bx_block_tasks/task_lists/client_task_lists";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";
exports.associatedLawyersApi = "bx_block_tasks/tasks/lawfirm_lawyers_filter"
exports.assignLawyerApiEndPoint = "bx_block_joblisting/jobs/task_assigned_lawyers?project_template_id="
exports.finishTaskApiEndPoint="bx_block_projecttemplates/project_templates/finish_task?id="
exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.patchApiAllocator = "bx_block_tasks/tasks/assign_or_remove_lawyers_in_task?id="
exports.searchTaskApi = "bx_block_tasks/task_lists/client_task_lists?search=true&"
exports.updateTask = "bx_block_projecttemplates/project_templates"
exports.selectTaskPriority = "Select task prority";
exports.selectTaskStatus = "Select task status";
exports.selectAccount = "Select account";
exports.selectGroup = "Select group";
exports.selectTasks = "Select tasks";
exports.textTitle = "Title";
exports.textDescription = "Description";
exports.textAdd = "Add";
exports.textTasks = "Tasks";
exports.textTaskList = "Task Lists";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textSave = "Save";
exports.textClose = "Close";
exports.textAccounts = "Accounts";
exports.textGroups = "Groups";
exports.textAddTask = "Add Task";
exports.textAddTaskList = "Add Task List";
exports.textShowTaskList = "Show Task List";
exports.textShowTask = "Show Task";
exports.textId = "Id";
exports.textIds = "tools";
exports.textPriority = "Priority";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textOr = "OR";
exports.putApiMethodPut="PUT";
exports.postApiMethodPost="POST";
// Customizable Area End
