Object.defineProperty(exports, "__esModule", {
    value: true,
  });
  
  // Customizable Area Start
  exports.apiContentType = "application/json";
  exports.confirmPaymentMethod = "POST";
  exports.stripePaymentIntent = "stripe_integration/payment_intents"
  
  exports.stripePublishableKey = ""
  exports.stripeMerchantDisplayName = "Example.com"
  exports.stripeMerchantIdentifier = "stripeMerchantIdentifier" //used for apple pay
  exports.urlScheme = "rnbuildingblockmasterapp" //app deep linking url
  exports.validationApiMethodType = "GET"
  exports.paymentTaskListEndpoint = "/bx_block_invoice/client_invoices/client_invoice_billings?type="
  exports.paymentTaskInfoEndpoint = "/bx_block_invoice/client_invoices/get_client_invoice?payment_id="
  exports.stripeCreatePaymentEndpoint = "/bx_block_stripe_integration/payments"
  exports.pendingTaskInfoEndpoint = "/bx_block_payment_admin/payment_admin/show?task_id="
  exports.taskCreatedDate = "Task Created Date"

  // Customizable Area End
  