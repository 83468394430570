import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ITask, ITaskList, TaskProfile } from "./types";
import { getStorageData } from "../../../framework/src/Utilities";
export interface LegalServiceType {
  id: number,
  name: string
}
export interface ApiResponse {
  attributes: {
    associated_lawyer_details: {
      data: {
        id:number,
        attributes: {
          first_name: string;
          last_name: string;
          website: string;
          firm: string;
          jurisdiction: string;
          full_phone_number: string;
          country_code: number;
          email: string;
          user_role: string;
          accomplishments: string;
          lawyer_status: string;
          legel_service_type: [{
            id: string,
            legal_service_name: string,
            legel_service_description: string|null,
            created_at: string,
            updated_at: string
          }];
          years_of_experience: number;
          profile_image: {
            url?: string | null; 
          } | null;
        };
      }
    };
    legal_service_count: number;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  taskhandle:boolean;

  // Customizable Area End
}

export interface S {
  id: number;
  // Customizable Area Start
  name: string;
  editMode: boolean;
  token: string;
  taskLists: ITaskList[];
  taskName: string,
  trademarkName: string,
  trademarkAppNumber: string,
  taskProfile:TaskProfile[],
  filterTask:TaskProfile[],
  filterStateOption:{
    name:string,
    bgColor:string,
    textColor:string
  }[],
  filterStatusOption:string[],
  filterServiceOption:string[],
  selectedTaskStatus:string,
  searchMessage:boolean,
  filterState:boolean,
  openFilter:boolean,
  selectedTaskState:string,
  selectedTaskService:string,
  taskView:{
    id: number;
    attributes: {
      task_name: string;
      legel_service_type: LegalServiceType;
      trademark_name: string;
      trademark_application_number: string;
      state: string;
      status: string;
      jurisdiction:string,
      deadline:string,
      description:string
      created_at:string,
      request: {

        data: {
          id: string,
          type: string,
          attributes: {
            price: string,
          }
        }
      }
    };
  },
  assignLawyer:{
    id: number;
    attributes: {
      task_name: string;
      legel_service_type: LegalServiceType;
      trademark_name: string;
      trademark_application_number: string;
      state: string;
      status: string;
      jurisdiction:string,
      deadline:string,
      description:string
      created_at:string
    };
    },
  tasksData: ITask[];
  selectedTasks: ITask[];
  isVisibleModal: boolean;
  dropdownTasks: boolean;
  mobileOpen: boolean;
  open: boolean;
  openDialog: boolean
  drawerOpen: boolean,
  taskViewId:string,
  isExpanded:boolean,
  acceptTask:boolean,
  rejectTask:boolean,
  lawyerDialog:boolean,
  selectedLawyers: ApiResponse[];
  loading:boolean,
  isTaskPosted:boolean,
  isInprogress:boolean,
  taskLoad:boolean,
  rejectedOffer:boolean,
  isLibraryHover: boolean,
  openTaskForm:boolean,
  isSaveDraft:boolean,
  responseShow:{
    success:string,
    error:string},
  anchorEl: HTMLElement | null,
  auditTaskId: string,
  userdata: string | null
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaskListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getTaskListsApiCallId = "";
  postTaskListApiCallId = "";
  putTaskListApiCallId = "";
  deleteTaskListApiCallId = "";
  getTasksApiCallId = "";
  assignLawyerCallId = "";
  getServiceApiCallId = "";
  apiCreateProjectCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.SessionResponseMessage),
        getName(MessageEnum.NavigationChatMessage),
  
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: 0,
      name: "",
      editMode: false,
      token: "",
      taskLists: [],
      taskName: "",
      trademarkName: "",
      trademarkAppNumber: "",
      tasksData: [],
      selectedTasks: [],

      isVisibleModal: false,
      dropdownTasks: false,
      mobileOpen: false,
      open: false,
      openDialog: false,
      drawerOpen: false,
      taskProfile:[],
      filterTask:[],
      searchMessage:false,
      filterState:false,
      openFilter:false,
      filterStateOption:[
        { name: "new_task", bgColor: "#DFFFE1", textColor: "#008000" },
        { name: "in_progress", bgColor: "#FFF4C2", textColor: "#D89A00" },
        { name: "pending", bgColor: "#DCE8FF", textColor: "#0055FF" },
        { name: "closed", bgColor: "#FFE3E3", textColor: "#D30000" },
      ],
      filterStatusOption:['complete','assigned','digip_assessment','no_legal_case','draft','pending_client_approval','task_posted'],
      filterServiceOption:[],
      selectedTaskState: "", 
      selectedTaskStatus: "",
      selectedTaskService:"",
      taskView:{
        id:1,
        attributes: {
          task_name: "",
          legel_service_type: {
            id:1,
            name:""
          },
          trademark_name: "",
          trademark_application_number: "",
          state: "",
          status: "",
          jurisdiction:"" ,
          deadline:"",
          description:"",
          created_at:"",
          request: {

            data: {
              id: "",
              type: "",
              attributes: {
                price: "",
              }
            }
          }
            }
        },
        assignLawyer:{
          id:1,
          attributes: {
            task_name: "",
            legel_service_type: {
              id:1,
              name:""
            },
            trademark_name: "",
            trademark_application_number: "",
            state: "",
            status: "",
            jurisdiction:"" ,
            deadline:"",
            description:"",
            created_at:""
              }
          },
      taskViewId:"",
      isExpanded:false,
      acceptTask:false,
      rejectTask:false,
      selectedLawyers: [],
      lawyerDialog:false,
      loading:true,
      isTaskPosted:false,
      isInprogress:false,
      taskLoad:false,
      rejectedOffer:false,
      isLibraryHover: false,
      openTaskForm:false,
      isSaveDraft:false,
      responseShow:{
        success:"",
        error:""},
      anchorEl: null,
      auditTaskId: "",
      userdata:""
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getTaskLists();
    this.getServicesList();
    let userName = localStorage.getItem("username")
    
    this.setState({
     userdata:userName
    })
  }
  
  
 taskDataLoad = ()=>{
  this.setState({taskLoad:false})
 }
 
  getTaskLists = async() => {
    const ctoken: string = await getStorageData("clientTokes");

    const header = {
      "token": ctoken,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.clientListApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  
    handleEditProject = () => {
      const message: Message = new Message(getName(MessageEnum.NavigationProjectTemplatesMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationProjectTemplatesWithId), this.state.taskViewId);
  
      this.send(message);

    }
    handleAcceptTask = () => {
      this.setState((prevState) => ({
        acceptTask: !prevState.acceptTask,
        drawerOpen: false
      }));
    };  
    handleRejectTask = () => {
      this.setState((prevState) => ({
        rejectTask: !prevState.rejectTask,
        drawerOpen: false
      }));
    };  
    handleLawyerDialog = () => {
      this.setState((prevState) => ({
        lawyerDialog: !prevState.lawyerDialog,
        drawerOpen: false
      }));
    }; 

    handleCloseAccept = () => {
      this.setState({ acceptTask: false});
  }
  handleUpdateCalls = () => {
    this.getTaskLists();
  } 

  handleChange = (event:any) => {
    this.setState({ 
      taskName: event.target.value 
    }, () => {
      this.handleSearchAndFilter();
    });
  };
  handleKeyDown = (event:any) => {
    if (event.key === 'Enter') {
      this.handleSearchAndFilter();
    }
  };
  
  handleTaskStateChange = (stateValue: string) => {
    this.setState({ 
      selectedTaskState: stateValue
    }, () => {
      this.handleSearchAndFilter();
    });
  };
  
  handleCatalogNavigate=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "ClientLawyerCatalogue");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
  
    this.send(message);
  }
  handleTaskStatusChange = (statusValue: string) => {
    this.setState({ 
      selectedTaskStatus: statusValue 
    }, () => {
      this.handleSearchAndFilter();
    });
  };

  handleRedirectPayment = async() => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Payment");   
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };
  
  handleTaskServiceChange = (serviceValue: string) => {
    this.setState({
      selectedTaskService: serviceValue
    }, () => {
      this.handleSearchAndFilter(); 
    });
  }
  
  searchAttribute =(task:any,taskName:string)=>{
    const taskNameAttr = task.attributes.task_name?.toLowerCase() || "";
    const trademarkName = task.attributes.trademark_name?.toLowerCase() || "";
    const applicationNumber = task.attributes.trademark_application_number?.toLowerCase() || "";
    const searchQuery = taskName?.toLowerCase() || "";
    const matchesSearch = searchQuery === "" || 
    taskNameAttr.includes(searchQuery) ||
    trademarkName.includes(searchQuery) ||
    applicationNumber.includes(searchQuery);
    return {matchesSearch,searchQuery}
  
  }
  
  handleSearchAndFilter = () => {
    const { taskName, selectedTaskState, selectedTaskStatus, selectedTaskService, taskProfile } = this.state;

    const filteredResults = taskProfile.filter(task => {
        if (!task || !task.attributes) return false;

        const { matchesSearch, searchQuery } = this.searchAttribute(task, taskName);
        const matchesAttributes = this.filterByAttributes(task, selectedTaskState, selectedTaskStatus, selectedTaskService);

        if (searchQuery && (selectedTaskState || selectedTaskStatus || selectedTaskService)) {
            return matchesSearch && matchesAttributes;
        } else if (searchQuery) {
            return matchesSearch;
        } else if (selectedTaskState || selectedTaskStatus || selectedTaskService) {
            return matchesAttributes;
        }

        return true;
    });

    this.setState({
        filterState: Boolean(taskName) || Boolean(selectedTaskState) || Boolean(selectedTaskStatus) || Boolean(selectedTaskService),
        filterTask: filteredResults,
        searchMessage: true
    });
};
  filterByAttributes = (task:any, selectedState:any, selectedStatus:any, selectedService:any) => {
    const stateOption = task.attributes.state?.toLowerCase() || "";
    const statusOption = task.attributes.status?.toLowerCase() || "";
    const serviceOption = task.attributes.legel_service_type?.name?.toLowerCase() || "";

    const matchesState = !selectedState || stateOption === selectedState.toLowerCase();
    const matchesStatus = !selectedStatus || statusOption === selectedStatus.toLowerCase();
    const matchService = !selectedService || serviceOption === selectedService.toLowerCase();

    return matchesState && matchesStatus && matchService;
};
  handleOpenFilter =()=>{
     this.setState({
      openFilter:true
    })
  }
  handleRejectClose = () => {
    this.setState({
      rejectTask: false 
    });
  }
  handleCloseLawyerDialog = () => {
   this.setState({
    lawyerDialog:false
  })
  }; 

  handleNavigationProject = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "projectTemplates");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }
  handleDocumentFolderNavigation = (taskId:string|number) =>{
    sessionStorage.setItem("taskID", `${taskId}`)
    const navMsg = new Message(getName(MessageEnum.NavigationMessage));
    navMsg.addData(getName(MessageEnum.NavigationTargetMessage),
      "Documentation");
      navMsg.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);
    this.send(navMsg);
  }

  handleNavigateDashboard = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage),
      "ClientDashboard");
    message.addData(getName(MessageEnum.NavigationPropsMessage),
      this.props);

    this.send(message);
  }

  handleCallId=(apiRequestCallId:any)=>{
    if (apiRequestCallId === this.apiCreateProjectCallId) 
      { this.setState({ openTaskForm:false})} 
  }
  
  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
  
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
    if (apiRequestCallId === this.getTaskListsApiCallId) {
      if (responseJson?.data?.length > 0) {
        this.setState({ taskProfile: responseJson.data, loading: false });
      } else {
        this.setState({ taskProfile: [] });
      }
    }

     this.handleCallId(apiRequestCallId)
  
    if (apiRequestCallId === this.getTasksApiCallId) {
      if (responseJson?.data) {
        const status = responseJson.data.attributes.status;
        const state = responseJson.data.attributes.state;
        this.setState({ 
          taskView: responseJson.data,
          isTaskPosted: status === "pending_client_approval",
          isInprogress:state ==="in_progress"
        });
      } else {
        this.setState({ isTaskPosted: false });
      }
    }
  
    if (apiRequestCallId === this.assignLawyerCallId) {
      if (responseJson) {
        this.setState({ selectedLawyers: responseJson.data });
      }
    }

    if (apiRequestCallId === this.getServiceApiCallId) {
      if (Array.isArray(responseJson)) {
        const legalServiceNames = responseJson.map(service => service.legal_service_name || "");
        this.setState({
          filterServiceOption: legalServiceNames
        });
      }

    }
  };
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  handleSeeMoreClick = () => {
    this.setState((prevState) => ({ isExpanded: !prevState.isExpanded,   }));
  };

  submitData = async (formValues:any, isSavedraft:boolean) => { const ctokes = await getStorageData("clientTokes");

    const header = {...(ctokes && { "token": ctokes })
    };

    const formData = new FormData();
    formData.append("description", formValues.description); formData.append("additional_info",'true')

    if (isSavedraft) { formData.append("save_as_draft", "true"); }

    const requestMessage = new Message
    (getName(MessageEnum.RestAPIRequestMessage));

    let endpoint = 
    `${configJSON.updateTask}`;
    
    if (!this.state.isSaveDraft)
     { endpoint = `${configJSON.updateTask}/${this.state.taskViewId}`;
    }
    
    requestMessage.addData
    (getName(MessageEnum.RestAPIResponceEndPointMessage),
     endpoint); this.apiCreateProjectCallId = requestMessage.messageId;

    requestMessage.addData
    (getName(MessageEnum.RestAPIRequestBodyMessage),
     formData);

    requestMessage.addData
    (getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header));

    const method = this.state.isSaveDraft ? 
    configJSON.postApiMethodPost : 
    configJSON.putApiMethodPut;
    
    requestMessage.addData
    (getName(MessageEnum.RestAPIRequestMethodMessage), 
    method);

    runEngine.sendMessage
    (requestMessage.id,
    requestMessage);
};


  handleSaveDraft = async (values:any) => { await this.submitData(values, true);
  };

  hideModal = () => {
    this.setState({ 
      isVisibleModal: !this.state.isVisibleModal 
    });
  };

  showAddModal = () => {  this.setState({  name: "",
      dropdownTasks: false,  isVisibleModal: !this.state.isVisibleModal,
      editMode: false,
    });
  };

  handleInputName = (name: string) => {
    this.setState({ name });
  };

  expandTasksView = () => {
    this.setState({ dropdownTasks: !this.state.dropdownTasks });
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: true });
  }

  handleclosesidebar = () => {
    this.setState({ mobileOpen: false });

  }

  handleClickOpen = () => {
    this.setState({ open: true })

  };

  handleClose = () => {
    this.setState({ open: false });

  };

  toggleDrawer = (task:{id:string}) => {const taskId = String(task.id); 
    sessionStorage.setItem("TaskID"
      ,taskId)
  this.setState(prevState => ({ drawerOpen: !prevState.drawerOpen, taskViewId: taskId, 
    }));
    this.getTasks()
    this.assignLawyer() };

  handleAdditionanlFunction=() => {const taskId = sessionStorage.getItem("TaskID");
    
    this.setState(prevState => ({ drawerOpen: !prevState.drawerOpen, taskViewId: taskId||"", 
 openTaskForm:true, }));
   
  };
  toggleDrawerClose = () => {
    this.setState({ drawerOpen: false ,isExpanded:false}); 
  };
  handleNavigationChat = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationChatMessage));
    message.addData(getName(MessageEnum.NavigationChatMessage), true);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationChatMessageId), this.state.taskViewId);
       console.log(this.state.taskViewId,"meriID");
       
    this.send(message);
  };




  handleSettingIn = async () => {
    const ctoken: string = await getStorageData("clientTokes");

    const message = new Message(getName(MessageEnum.NavigationMessage));

    if (ctoken) {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "ClientSettings");
    } else {
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Settings");
    }

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleCloseDelete = () => {
    this.setState({ openDialog: false });
  };
  showEditModal = () => {
    this.setState({
      isVisibleModal: !this.state.isVisibleModal,
      editMode: true,
    });
  };

  handleEditSelect = (item: ITaskList) => {
    this.setState({
      id: item.id,
      name: item.attributes.name,
      selectedTasks: item.attributes.tasks,
      dropdownTasks: false,
    });
    this.showEditModal();
  };

  handleTasksSelect = (dataId: string) => {
    let newData = this.state.tasksData.map((task: ITask) => {
      if (task.id === dataId) {
        return { ...task, isSelected: !task.isSelected };
      }
      return task;
    });
    this.setState({ tasksData: newData });
  };
  handleLawerDialog = () => {
    this.setState((prevState) => ({
      lawyerDialog: !prevState.lawyerDialog,
      drawerOpen: false
    }));
  };


  addTaskList = () => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      let taskIds: string[] = [];
      this.state.tasksData.map((task: ITask) => {
        if (task.isSelected) {
          taskIds.push(task.id);
        }
      });
      const httpBody = {
        name: this.state.name,
        task_ids: taskIds,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postTaskListApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.clientListApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  getServicesList= async () => {
       const header = {"Content-Type": "application/json"};
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
          );
this.getServiceApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.serviseListApi}`
    );
    
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );
         requestMessage.addData(
             getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethod
    );
 runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Function to edit task list and send it to API
  editTaskList = (tasklistId: number) => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      let taskIds: string[] = [];
      this.state.tasksData.map((task: ITask) => {
        if (task.isSelected) {
          taskIds.push(task.id);
        }
      });
      const httpBody = {
        name: this.state.name,
        task_ids: taskIds,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.putTaskListApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.clientListApiEndPoint + "/" + `${tasklistId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  // Function to delete task list and send it to API
  deleteTaskList = (tasklistId: number) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteTaskListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.clientListApiEndPoint + "/" + `${tasklistId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTasks = async () => {
    const ctoken: string = await getStorageData("clientTokes");

    const header = {
      "token": ctoken,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTasksApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksApiEndPoint}/${this.state.taskViewId}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  assignLawyer = async () => {
    const ctoken: string = await getStorageData("clientTokes");
    const header = {
      "token": ctoken,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.assignLawyerCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.assignLawyerApiEndPoint}${this.state.taskViewId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setIsHovered = (isHover:boolean) => {
    this.setState({isLibraryHover:isHover})
  }

  handleDocumentFolderMenu = async() => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Documentation");   
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
      sessionStorage.setItem("Document"
        , "ture")
  };

  handleAuditPopoverOpen = (event: React.MouseEvent<HTMLElement>,taskId:string) => {
    this.setState({anchorEl: event.currentTarget,auditTaskId:taskId})
  };

  handleAuditPopoverClose = () => {
    this.setState({anchorEl: null})
  };


  // Customizable Area End
}
