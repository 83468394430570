import React from "react";
import {
  createTheme,
  ThemeProvider,
  Dialog,
  IconButton,
  DialogActions,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

type FinishTaskPopupProps = {
  open: boolean;
  onClose: () => void;
  areToDosPending: boolean;
  onFinishTask: () => void;
};

const finishTaskTheme = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#00000033",
      },
    },
    MuiDialog: {
      paperWidthSm: {
        width: 600,
        maxWidth: 600,
      },
    },
    MuiPaper: {
      root: {
        padding: 12,
        "& .inner-container": {
          boxSizing: "border-box",
          padding: 32,
          border: "1px solid #FEE2E2",
          borderRadius: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          gap: 24,
          "& > p": {
            margin: 0,
            textAlign: "center",
            fontFamily: "DM Sans",
            fontSize: 18,
            lineHeight: "23.44px",
            color: "#000000",
            fontWeight: 400,
          },
        },
      },
      elevation24: {
        boxShadow:
          "0px 8px 32px 0px #01175F0F, 0px 4px 8px 0px #01175F08, 0px 25px 50px 0px #01175F17",
      },
      rounded: {
        borderRadius: 12,
      },
    },
    MuiIconButton: {
      root: {
        position: "absolute",
        top: 0,
        right: 0,
      },
    },
    MuiDialogActions: {
      root: {
        marginTop: 12,
        padding: 0,
        gap: 12,
        width: "100%",
        "& button": {
          all: "unset",
          boxSizing: "border-box",
          cursor: "pointer",
          flex: 1,
          textAlign: "center",
          border: "1px solid #8C8C8C",
          borderRadius: 8,
          padding: "10px 16px",
          fontFamily: "DM Sans",
          fontWeight: 600,
          fontSize: 16,
          lineHeight: "24px",
          color: "#8C8C8C",
          "&.finish-task": {
            border: 0,
            backgroundColor: "#F35F5F",
            color: "#FFFFFF",
            fontWeight: 500,
            '&[data-has-todos-pending="false"]': {
              backgroundColor: "#355EE7",
            },
          },
        },
      },
      spacing: {
        "& > :not(:first-child)": {
          marginLeft: 0,
        },
      },
    },
  },
});

const PendingTaskIcon = () => (
  <svg
    width="90"
    height="91"
    viewBox="0 0 90 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="90" height="90" rx="45" fill="#FEE2E2" />
    <g filter="url(#filter0_d_23297_115419)">
      <path
        d="M52.6685 38.9785H50.7449C50.3265 38.9785 49.9286 39.1795 49.6826 39.524L43.2349 48.4654L40.3146 44.4131C40.0685 44.0727 39.6747 43.8676 39.2523 43.8676H37.3286C37.062 43.8676 36.9062 44.1711 37.062 44.3885L42.1726 51.476C42.2933 51.6445 42.4525 51.7818 42.6369 51.8765C42.8212 51.9712 43.0256 52.0206 43.2328 52.0206C43.4401 52.0206 43.6444 51.9712 43.8288 51.8765C44.0132 51.7818 44.1724 51.6445 44.2931 51.476L52.931 39.4994C53.091 39.282 52.9351 38.9785 52.6685 38.9785Z"
        fill="#DC2626"
      />
      <path
        d="M26.625 45.5C26.625 35.3527 34.8527 27.125 45 27.125C55.1473 27.125 63.375 35.3527 63.375 45.5H60.2578C60.2578 37.0754 53.4246 30.2422 45 30.2422C36.5754 30.2422 29.7422 37.0754 29.7422 45.5C29.7422 53.9246 36.5754 60.7578 45 60.7578C47.3461 60.7578 49.5688 60.2279 51.5548 59.2812V62.6712C49.5188 63.4489 47.3091 63.875 45 63.875C34.8527 63.875 26.625 55.6473 26.625 45.5Z"
        fill="#DC2626"
      />
      <path
        d="M53.277 54.0521C53.0555 54.0521 52.875 53.8839 52.875 53.6624C52.875 51.3081 54.9299 49.4091 57.4482 49.4378C59.8805 49.4706 61.8656 51.3286 61.8984 53.605C61.9271 55.4466 60.6803 57.0708 58.851 57.6615C58.6172 57.7353 58.4531 57.9609 58.4531 58.207V58.7074C58.4531 58.9329 58.2686 59.1175 58.043 59.1175H56.7305C56.5049 59.1175 56.3203 58.9329 56.3203 58.7074V57.3949C56.3285 56.5827 56.952 55.865 57.7436 55.7501C58.9043 55.5984 59.7656 54.7001 59.7656 53.6624C59.7656 52.5099 58.7074 51.5501 57.3867 51.5501C56.066 51.5501 55.0078 52.5099 55.0078 53.6665C55.0078 53.8798 54.8273 54.0521 54.6059 54.0521H53.277Z"
        fill="#DC2626"
      />
      <path
        d="M56.4586 62.5886C56.2125 62.3424 56.0742 62.0086 56.0742 61.6605C56.0742 61.3124 56.2125 60.9785 56.4586 60.7324C56.7048 60.4863 57.0386 60.348 57.3867 60.348C57.7348 60.348 58.0687 60.4863 58.3148 60.7324C58.5609 60.9785 58.6992 61.3124 58.6992 61.6605C58.6992 62.0086 58.5609 62.3424 58.3148 62.5886C58.0687 62.8347 57.7348 62.973 57.3867 62.973C57.0386 62.973 56.7048 62.8347 56.4586 62.5886Z"
        fill="#DC2626"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_23297_115419"
        x="16"
        y="18.5"
        width="58"
        height="58"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.0901961 0 0 0 0 0.372549 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_23297_115419"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_23297_115419"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const FinishedTaskIcon = () => (
  <svg
    width="90"
    height="90"
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="90" height="90" rx="45" fill="#D1FAE5" />
    <g filter="url(#filter0_d_23297_94840)">
      <path
        d="M52.2188 36.7969V34.8281C52.2188 34.6477 52.0711 34.5 51.8906 34.5H36.1406C35.9602 34.5 35.8125 34.6477 35.8125 34.8281V36.7969C35.8125 36.9773 35.9602 37.125 36.1406 37.125H51.8906C52.0711 37.125 52.2188 36.9773 52.2188 36.7969ZM36.1406 40.4062C35.9602 40.4062 35.8125 40.5539 35.8125 40.7344V42.7031C35.8125 42.8836 35.9602 43.0312 36.1406 43.0312H43.6875C43.868 43.0312 44.0156 42.8836 44.0156 42.7031V40.7344C44.0156 40.5539 43.868 40.4062 43.6875 40.4062H36.1406ZM51.5625 45.1641C46.6693 45.1641 42.7031 49.1303 42.7031 54.0234C42.7031 58.9166 46.6693 62.8828 51.5625 62.8828C56.4557 62.8828 60.4219 58.9166 60.4219 54.0234C60.4219 49.1303 56.4557 45.1641 51.5625 45.1641ZM55.9717 58.4326C54.7945 59.6098 53.2277 60.2578 51.5625 60.2578C49.8973 60.2578 48.3305 59.6098 47.1533 58.4326C45.9762 57.2555 45.3281 55.6887 45.3281 54.0234C45.3281 52.3582 45.9762 50.7914 47.1533 49.6143C48.3305 48.4371 49.8973 47.7891 51.5625 47.7891C53.2277 47.7891 54.7945 48.4371 55.9717 49.6143C57.1488 50.7914 57.7969 52.3582 57.7969 54.0234C57.7969 55.6887 57.1488 57.2555 55.9717 58.4326ZM55.2129 50.9062H53.3959C53.2893 50.9062 53.1908 50.9555 53.1293 51.0416L50.5248 54.6428L49.5773 53.3344C49.547 53.2921 49.5069 53.2578 49.4605 53.2342C49.4141 53.2106 49.3628 53.1986 49.3107 53.199H47.502C47.2354 53.199 47.0795 53.5025 47.2354 53.7199L50.2623 57.9076C50.3936 58.0881 50.6602 58.0881 50.7914 57.9076L55.4754 51.4271C55.6354 51.2098 55.4795 50.9062 55.2129 50.9062ZM42.0469 58.9453H32.5312V30.0703H55.5V44.1797C55.5 44.3602 55.6477 44.5078 55.8281 44.5078H58.125C58.3055 44.5078 58.4531 44.3602 58.4531 44.1797V28.4297C58.4531 27.7037 57.8666 27.1172 57.1406 27.1172H30.8906C30.1646 27.1172 29.5781 27.7037 29.5781 28.4297V60.5859C29.5781 61.3119 30.1646 61.8984 30.8906 61.8984H42.0469C42.2273 61.8984 42.375 61.7508 42.375 61.5703V59.2734C42.375 59.093 42.2273 58.9453 42.0469 58.9453Z"
        fill="#059669"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_23297_94840"
        x="16"
        y="18"
        width="58"
        height="58"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.00392157 0 0 0 0 0.0901961 0 0 0 0 0.372549 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_23297_94840"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_23297_94840"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default function FinishTaskPopup({
  open,
  areToDosPending,
  onClose,
  onFinishTask,
}: FinishTaskPopupProps) {
  const description = areToDosPending
    ? "Are you sure you want to finish this task? there are still some to-dos to be done and this action will close the case and notify the client."
    : "Are you sure you want to finish this task? This action will close the case and notify the client.";

  return (
    <ThemeProvider theme={finishTaskTheme}>
      <Dialog open={open} onClose={onClose}>
        <div className="inner-container">
          <IconButton onClick={onClose} style={{ padding: 8 }}>
            <Close htmlColor="#8C8C8C" />
          </IconButton>
          {areToDosPending ? <PendingTaskIcon /> : <FinishedTaskIcon />}
          <p>{description}</p>
          <DialogActions>
            <button id="cancel" onClick={onClose}>Cancel</button>
            <button
              className="finish-task"
              data-has-todos-pending={areToDosPending}
              onClick={onFinishTask}
              id="finish-task-btn"
            >
              Finish Task
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </ThemeProvider>
  );
}
