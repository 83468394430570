import React from 'react';
interface CloseIconProps {
    color: string;
  }
  
  export default function Closeicon({ color }: CloseIconProps) {
    return(
        <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.78125 4C3.69531 4 3.625 4.07031 3.625 4.15625V5.09375C3.625 5.17969 3.69531 5.25 3.78125 5.25H11.2812C11.3672 5.25 11.4375 5.17969 11.4375 5.09375V4.15625C11.4375 4.07031 11.3672 4 11.2812 4H3.78125ZM7.53125 7.90625V6.96875C7.53125 6.88281 7.46094 6.8125 7.375 6.8125H3.78125C3.69531 6.8125 3.625 6.88281 3.625 6.96875V7.90625C3.625 7.99219 3.69531 8.0625 3.78125 8.0625H7.375C7.46094 8.0625 7.53125 7.99219 7.53125 7.90625ZM6.59375 15.6406H2.0625V1.89062H13V8.60938C13 8.69531 13.0703 8.76562 13.1562 8.76562H14.25C14.3359 8.76562 14.4062 8.69531 14.4062 8.60938V1.10938C14.4062 0.763672 14.127 0.484375 13.7812 0.484375H1.28125C0.935547 0.484375 0.65625 0.763672 0.65625 1.10938V16.4219C0.65625 16.7676 0.935547 17.0469 1.28125 17.0469H6.59375C6.67969 17.0469 6.75 16.9766 6.75 16.8906V15.7969C6.75 15.7109 6.67969 15.6406 6.59375 15.6406Z" fill={color}/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9922 12.2259C11.0655 12.2991 11.1842 12.2991 11.2574 12.2259L12.984 10.4993C13.0572 10.4261 13.1759 10.4261 13.2492 10.4993L14.0005 11.2506C14.0737 11.3238 14.0737 11.4426 14.0005 11.5158L12.2739 13.2424C12.2006 13.3156 12.2006 13.4343 12.2739 13.5075L14.0005 15.2341C14.0737 15.3074 14.0737 15.4261 14.0005 15.4993L13.2492 16.2506C13.1759 16.3238 13.0572 16.3238 12.984 16.2506L11.2574 14.524C11.1842 14.4508 11.0655 14.4508 10.9922 14.524L9.26563 16.2506C9.1924 16.3238 9.07368 16.3238 9.00046 16.2506L8.24916 15.4993C8.17594 15.4261 8.17594 15.3074 8.24916 15.2341L9.97576 13.5075C10.049 13.4343 10.049 13.3156 9.97576 13.2424L8.24916 11.5158C8.17594 11.4426 8.17594 11.3238 8.24916 11.2506L9.00046 10.4993C9.07368 10.4261 9.1924 10.4261 9.26563 10.4993L10.9922 12.2259Z" fill={color}/>
        </svg>
        
    )

}