// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const config = require("../../../framework/src/config");

import {
  getStorageData,
  setStorageData
} from '../../../framework/src/Utilities';
export interface Props {
  navigation: any;
  id: string;
  projectId:any;
  acceptDialog: boolean;
  rejectDialog:boolean;
  taskId:string;
  handleClose:any;
  rejectClose:any;
  closeLawyer:any;
  lawyerDialog:boolean;
  handleNavigate:any;
  handleUpdateCall: () => void;
}

export interface JobListDataType {
    attributes: {
      address: string, 
      addresses: [], 
      company_headquarter: string, 
      company_name: string, 
      company_page_id: number, 
      company_photo: string,
        company_photo_url: string, 
        country: string, 
        created_at: string, 
        employment_type: null, 
        employment_type_id: number,
        industry_id: number,
        industry_name: string, 
        job_description: string, 
        job_function: string, 
        job_title: string, 
        job_video: string, 
        job_video_url: string, 
        location: string, 
        other_skills: Array<string>, 
        preffered_location: Array<string>, 
        profile_id: number, 
        question_answer_id: Array<string>, 
        question_answers: Array<object>, 
        remote_job: boolean, 
        salary: string, 
        seniority_level: string, 
        skill_id: Array<number>, 
        skill_name: Array<string>, 
        sub_emplotyment_type: null, 
        total_inteview_rounds: number,
        image_url: string,
        followers_count: number
    }, 
      id: string, 
      type: string
}
export interface JobListIOSDataType {
  item: {
    attributes: {
      address: string, 
      addresses: [], 
      company_headquarter: string, 
      company_name: string, 
      company_page_id: number, 
        other_skills: Array<string>, 
        preffered_location: Array<string>, 
        profile_id: number, 
        question_answer_id: Array<string>, 
        question_answers: Array<object>, 
        remote_job: boolean, 
        salary: string, 
        seniority_level: string, 
        company_photo: string,
        company_photo_url: string, 
        country: string, 
        created_at: string, 
        employment_type: null, 
        employment_type_id: number,
        industry_id: number,
        industry_name: string, 
        job_description: string, 
        job_function: string, 
        job_title: string, 
        job_video: string, 
        job_video_url: string, 
        location: string, 
        skill_id: Array<number>, 
        skill_name: Array<string>, 
        sub_emplotyment_type: null, 
        total_inteview_rounds: number,
        image_url: string,
        followers_count: number
    }, 
      id: string, 
      type: string
  }
}
interface OptionType {
  value: string;
  label: string;
  id: number;
}
interface OptionTypeReason {
  value:string,
  label:string,
  id:number,
}
export interface DataLegal{
  value:string,
  label:string,
  id:number,
}
export interface LegalServices{
  id: string,
  legal_service_name: string,
  legel_service_description: string|null,
  created_at: string,
  updated_at: string
}

export interface ServicesDataType{
  id: string,
  legal_service_name: string,
  legel_service_description: string|null,
  created_at: string,
  updated_at: string
}
export interface ServicesReason{
  id: string,
  reason_content: string,
  created_at: string,
  updated_at: string
}
interface ServiceData {
  id: string;
  legal_service_name: string;
}
interface ServiceDataReason {
  id: string;
  reason_content: string;
}
interface LegalServiceType {
  id: number;
  legal_service_name: string;
  legel_service_description: string | null;
  created_at: string;
  updated_at: string;
}
export interface ApiResponse {
  attributes: {
    associated_lawyer_details: {
      data: {
        id:number,
        attributes: {
          first_name: string;
          last_name: string;
          website: string;
          firm: string;
          jurisdiction: string;
          full_phone_number: string;
          country_code: number;
          email: string;
          user_role: string;
          accomplishments: string;
          lawyer_status: string;
          legel_service_type: [{
            id: string,
            legal_service_name: string,
            legel_service_description: string|null,
            created_at: string,
            updated_at: string
          }];
          years_of_experience: number;
          profile_image: {
            url?: string | null; 
          } | null;
        };
      }
    };
    legal_service_count: number;
  };
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  first: boolean;
  searchText: string;
  jobArray: JobListDataType[];
  recent: Array<string>;
  lastSearch: string;
  loading: boolean
  followIndex: number,
  token: string,
  openDialog:boolean,
  openPop:boolean,
  rejectDialog:boolean,
  formData2:{
    rejection_description: string,
    task_reject_reason_id: number|null,
   };
   hasError: boolean,
   optionsShow: OptionType[],
   optionsReason: OptionTypeReason[],
   selectedOption: string,
   servicesData: ServiceData[];
   serviceReason:ServiceDataReason[],
   acceptTask:boolean;
   rejectTask:boolean;
   taskViewId:string;
   lawyerDialog:boolean;
   responseShow:{
    success:string,
    errors:string},
    rejectOption:string,
    selectedLawyers:ApiResponse[],
    lawyerId:number,
    selectedLawyerId :number[],
    removeDialog:boolean,
    removedLawyerId:number[],
    removedLawyerNames:string[],
    lawyerName:string,
    rejectedOffer:boolean,
    hasDesc:boolean
}

interface SS {
  id: string;
}

export const configJSON = require("./config");

export default class JobListingController extends BlockComponent<Props, S, SS> {
  searchJobApiCallId: string = ""
  searchCompanyApiCallId: string = "";
  searchPeopleApiCallId: string = "";
  followCompanyApiCallId: string = "";
  //willFocusSubscription: object;
  addConnectionApiCallId: string = "";
  lastVisitedJob: string = "";
  lastVisitedCompany: string = "";
  lastVisitedPeople: string = "";
  getReasonApiCallId:string="";
  postAcceptOfferCallId ="";
  postRejectCallId:string="";
  getTaskListsApiCallId:string="";
  assignLawyerCallId="";
  patchLawyerCallId="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      txtInputValue: '',
      loading: false,
      txtSavedValue: 'A',
      enableField: false,
      first: true,
      searchText: '',
      jobArray: [],
      recent: [],
      lastSearch: '',
      followIndex: -1,
      token:'',
      openDialog:false,
      openPop:false,
      rejectDialog:false,
      formData2:{
        rejection_description: "",
        task_reject_reason_id:null
    },
      hasError: false,
      optionsShow: [
        {
          value: "",
          label: "string",
          id: 1,
        },
      ],
      optionsReason: [
        {
          value: "",
          label: "string",
          id: 1,
        }
      ],
      selectedOption: "",
      servicesData: [],
      serviceReason:[],
      acceptTask:false,
      rejectTask:false,
      taskViewId:'',
      lawyerDialog:false,
      responseShow:{
        success:"",
        errors:""},
        selectedLawyerId:[],
        rejectOption:"",
        selectedLawyers:[],
        removeDialog:false,
        lawyerId:1,
        removedLawyerId:[],
        removedLawyerNames:[],
        lawyerName:"",
        rejectedOffer:false,
        hasDesc:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  getJobSearchSuccessCallBack = (responseJson: JobListDataType[], apiRequestCallId:string) => {
    if (apiRequestCallId === this.searchJobApiCallId) {
      try {
          this.lastVisitedJob = this.state.searchText?.trim();
          this.setState({ jobArray: responseJson || [], loading: false });
      }
      catch (error) {
        alert(error)
      }
    }
  }
 
  handleOpen=()=>{
    this.setState({openDialog:true})
  }
  handleAssignedLawyer=()=>{
    this.setState({lawyerDialog:true})
  }
  handleOpenReject=()=>{
    this.setState({rejectDialog:true})
  }
  handleCloseDialog=()=>{
    this.setState({openDialog:false})
  }
  handleClosePop=()=>{
    this.setState({openPop:false})
  }
  handleRejectClose=()=>{
    this.setState({rejectDialog:false})
  }
  handleCloseLawyer=()=>{
    this.setState({lawyerDialog:false})
  }
  handleRejected = () => {
    this.setState({
      selectedOption: "", 
      hasError:false,
      hasDesc:false
    });
    this.props.rejectClose(); 
  }

  
  handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const description = e.target.value;
  
    this.setState({
      formData2: {
        ...this.state.formData2,
        rejection_description: description,
      },
      hasDesc: false,
      hasError: false,
     
    });
  };
  getTaskRejectReasonId = (value: string, optionsReason: any[]) => {
    if (value === "no_legal_case") {
      return null;
     }
   const selectedOption = optionsReason.find((option: { value: any; }) => option.value === value);
   return selectedOption ? selectedOption.id : null;
 };
 
  handleChange2 = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    const taskRejectReasonId = this.getTaskRejectReasonId(value, this.state.optionsReason);
    this.setState(prevState => ({
        ...prevState,
        selectedOption: value,
        rejectOption:value,
        formData2: {
            ...prevState.formData2,
            task_reject_reason_id: taskRejectReasonId,
        },
        hasError: false,
        hasDesc:false
    }));
  };

 
 
  async receive(from: string, message: Message) {
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.data) {
        this.getJobSearchSuccessCallBack(responseJson.data, apiRequestCallId)
      }
    
    
    if (apiRequestCallId === this.getReasonApiCallId) {
      this.setState({ serviceReason: responseJson });
      const reasonsOptions = responseJson?.length > 0 && responseJson.map((e: ServicesReason) => ({
          id: e.id,
          label: e.reason_content, 
          value: e.reason_content,  
      }));
      
      this.setState({ optionsReason: reasonsOptions });
  }
  if(apiRequestCallId === this.postAcceptOfferCallId ||apiRequestCallId === this.postRejectCallId){
    
    this.handleProjectCreationResponse(responseJson)
   
    }
    if(apiRequestCallId === this.assignLawyerCallId){
      const selectedIds = responseJson?.data.map((element: any) => (
        element.id
      ))
      if (responseJson) {
        this.setState({ selectedLawyers: responseJson.data,selectedLawyerId:selectedIds
         
        });
      }
  }
  this.handleApiResponse(apiRequestCallId, responseJson);
}
  
  }
  
  getToken = () => {
    const getAuthToken: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(getAuthToken);
  };

  async componentDidMount() {
    this.getToken();
    this.getReasonList()
  }
 
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (prevProps.taskId !== this.props.taskId) {
      await this.assignLawyer();
    }
    if(prevState.removedLawyerId !== this.state.removedLawyerId) {
      await this.updateLawyers(this.state.removedLawyerId);
    } 
    
  }
  handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.patchLawyerCallId) {
      if (responseJson) {
        this.setState({
          removeDialog: false,
          openPop: true
        });
  
        setTimeout(() => {
          this.setState({ openPop: false });
        }, 700);
      }
    }
  };
  
  
  handleProjectCreationResponse = async(responseJson: { message: any; } ,errors?: { message: string }[]) => {
    if (responseJson && responseJson.message){
      this.setState({
        openPop: true,
        responseShow: { errors: responseJson.message, success: "" } 
      });
    } else {
      this.setState({
        openPop: true,
        responseShow: { errors: "", success: responseJson.message }
      });
    }
    setTimeout(() => {
      this.setState({ openPop: false });
       this.props.handleUpdateCall()
    }, 700);
  };
  
  
  async getRecents() {
    const recentData = await getStorageData('recent');
    this.setState({ recent: recentData });
  }
  handler = (inputValue: string) => {
    if (inputValue.trim()) {
      this.setSearchText(inputValue)
    }
    this.setState({ lastSearch: inputValue.trim(), searchText: inputValue, loading: true });
  };

  setSearchText = (inputValue: string) => {
    this.searchJob(inputValue);
  };

  addDataToRecent() {
    let recent = this.state.recent;
    this.setState({ recent }, () => {
      setStorageData('recent', JSON.stringify(this.state.recent));
    });
  }
  handleRemoveLawyer = (id: number) => {
    this.setState((prevState) => {
      const removedUpdated = prevState.removedLawyerId.filter((element) => element !== id);
      
      return {
        removedLawyerId: removedUpdated,
     
        selectedLawyers: prevState.selectedLawyers.filter(
          (lawyer) => lawyer.attributes.associated_lawyer_details.data.id !== id
        ),
        selectedLawyerId: prevState.selectedLawyerId.filter((lawyerID) => lawyerID !== id)
      };
    });
  };
  
  handleRemoveDialog = (id: number, name: string) => {
    this.setState({
        removeDialog: true,      
        lawyerId: id,             
        lawyerName: name,         
    });

    this.props.closeLawyer();
  };
  
  handleCloseRemove=()=>{
    this.setState({removeDialog:false})
  }
  

  goToJobDetailPage = (jobId: string, companyName: string, companyLocation: string, companyLogo: string) => {
    const jobMessage = new Message(getName(MessageEnum.NavigationMessage));
    jobMessage.addData(getName(MessageEnum.NavigationTargetMessage), "JobDetailsPage");

    jobMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      companyName,
      companyLocation,
      companyLogo,
      jobId
    });
    jobMessage.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(jobMessage);
  };
  handleNav=()=>{
    this.props.handleNavigate()
  }
  
  searchJob = async (inputValue: string) => {
    this.setState({ loading: true });
    let token = this.state.token;
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.searchJobApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchJobApiEndPoint + '?search=' + inputValue
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  determineMainToken1 = (
    metaSignUpToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } },
    metaLoginToken: { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } }
  ): { id?: string; data?: { id?: string }; serialized_data?: { meta?: { token?: string } }; meta?: { token?: string } } | null => {
    if (metaSignUpToken?.data?.id) {
      if (metaLoginToken?.id && metaSignUpToken.data.id !== metaLoginToken.id) {
        return metaLoginToken;
      }
      return metaSignUpToken;
    }

    if (metaLoginToken?.id) {
      return metaLoginToken;
    }

    return null; 
  };


  getUrl = (imageUrl: string) => {
    if (imageUrl.includes("https://") || imageUrl.includes("http://")) {
      return imageUrl;
    } else {
      return config.baseURL + imageUrl;
    }
  };
  getReasonList= async () => {
    const  mainToken = localStorage.getItem('clientTokes');
    const header = {
      "token": mainToken,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   
    this.getReasonApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     "bx_block_request_management/requests/client_reject_offer_reason"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getcompanyApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 
  acceptOffer= async()=>{
    const mainToken = localStorage.getItem('clientTokes');

    const header = {
      "token": mainToken,
      "Content-Type": "application/json",
    };

const requestBody = {
  request: {
    project_template_id: this.props.taskId,
   
  }
};
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postAcceptOfferCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.acceptOffer}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  rejectOffer= async()=>{
    const mainToken = localStorage.getItem('clientTokes');

    const header = {
      "token": mainToken,
      "Content-Type": "application/json",
    };
    const { task_reject_reason_id ,rejection_description} = this.state.formData2;

const requestBody = {
  request: {
    task_reject_reason_id:task_reject_reason_id,
    project_template_id: this.props.taskId,
    rejection_description  : rejection_description
  }
};

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postRejectCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.postRejectRequest}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getTaskLists = async() => {
    const ctoken: string = await getStorageData("clientTokes");

    const header = {
      "token": ctoken,
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.clientListApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getTokenAssign = (userRole: any, maintoken: any, ctoken: any) => {
    return userRole === "lawfirm_admin" ? maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token : ctoken
  }
  assignLawyer = async () => {
    let tokenData = await getStorageData("token", true)
    const userRole = tokenData?.user_role;
    const signupTokens = await getStorageData("userdetails", true);
    const loginTokens = await getStorageData("token", true);
    const maintoken = this.determineMainToken1(signupTokens, loginTokens);
   
    const ctoken: string = await getStorageData("clientTokes");

    const header = {
      "token": this.getTokenAssign(userRole, maintoken,ctoken),
    "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.assignLawyerCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.assignLawyerApiEndPoint}${this.props.taskId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };  
  updateLawyers = async (id:any) => {
    const signupToken: string = await getStorageData("userdetails");
    
    const loginToken: string = await getStorageData("token");
    const metaSignUpTokens = JSON.parse(signupToken);
    const metaLoginTokens = JSON.parse(loginToken);

    const maintoken = this.determineMainToken1(metaSignUpTokens, metaLoginTokens);

    const header: { "Content-Type": string; token: string | undefined } = { "token": maintoken?.serialized_data?.meta?.token || maintoken?.meta?.token,
    "Content-Type": "application/json",};
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage) );
    this.patchLawyerCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.patchApiAllocator}${this.props.taskId}&assigned_lawyer_ids[]=${[...this.state.selectedLawyerId]}`
    );
 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

}

// Customizable Area End
